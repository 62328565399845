import "react-image-crop/src/ReactCrop.scss";
import { stringAvatar } from "../../../../shared/helpers/profile.helper";

import { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from "react-image-crop";

import { FileI } from "@interfaces";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";

import DeleteIcon from "../../../../assets/icons/delete.svg";
import CameraIcon from "../../../../assets/icons/navbar/camera.svg";
import LibraryIcon from "../../../../assets/icons/navbar/library.svg";
import styles from "./Avatar.module.scss";
import { toast } from "react-toastify";
import { getImagesUrl } from "shared/helpers/common.helper";

function UpAvatar({
  avatar,
  name,
  editable = false,
  toggleImage,
  defaultImage,
}: {
  avatar: string | null;
  name?: string;
  editable?: boolean;
  toggleImage?: (val: FileI | null) => void;
  defaultImage: string | null;
}) {
  const [opened, setOpened] = useState<boolean>(false);
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const [crop, setCrop] = useState<Crop>();
  const [imgSrc, setImgSrc] = useState("");
  const [imgSizes, setImgSizes] = useState([0, 0]);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [savedImage, setSavedImage] = useState<FileI | null>(null);

  useEffect(() => {
    savedImage && toggleImage && toggleImage(savedImage);
  }, [savedImage, toggleImage]);

  const canvasRef = useRef<any>(null);
  const imgRef = useRef<any>(null);
  const croppedImageSize = 200;

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      setImgSrc("");
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
        setOpened(false);
        setModalOpened(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    if (
      e.currentTarget.naturalWidth < croppedImageSize ||
      e.currentTarget.naturalHeight < croppedImageSize
    ) {
      toast.error("Uploaded image is too small.");
      setModalOpened(false);

      return;
    }
    setImgSizes([imgRef.current.width, imgRef.current.height]);
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: "%",
            width: 100,
          },
          1,
          width,
          height
        ),
        width,
        height
      )
    );
  }

  function saveImage() {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const imageSizeW = (croppedImageSize * imgSizes[0]) / completedCrop!.width;
    const imageSizeH = (croppedImageSize * imgSizes[1]) / completedCrop!.height;
    context.drawImage(
      imgRef.current,
      completedCrop!.x ? -1 * ((imageSizeW * completedCrop!.x) / imgSizes[0]) : 0,
      completedCrop!.y ? -1 * ((imageSizeH * completedCrop!.y) / imgSizes[1]) : 0,
      imageSizeW,
      imageSizeH
    );

    canvas.toBlob((blob: any) => {
      const reader = new FileReader();

      reader.onload = () => {
        setSavedImage({
          arrayBuffer: reader.result,
          binaryData: canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream"),
        });
      };

      reader.readAsArrayBuffer(blob);
    });

    setModalOpened(false);
  }

  function avatarProps(name: string | undefined) {
    let generated: { sx?: any } = {};
    const isDefaultMessage = !savedImage?.binaryData && !avatar && defaultImage;
    if (name) {
      generated = stringAvatar(name, !!isDefaultMessage);
    }
    const sx = {
      ...generated.sx,
      width: !!isDefaultMessage ? 60 : 98,
      height: !!isDefaultMessage ? 60 : 98,
    };
    return {
      ...generated,
      sx,
    };
  }
  const getImage = useCallback(() => {
    if (savedImage?.binaryData) {
      return savedImage.binaryData;
    }
    if (avatar) {
      return getImagesUrl() + avatar;
    }

    return defaultImage;
  }, [avatar, defaultImage, savedImage]);

  return (
    <div className={styles["up-avatar"]}>
      <Avatar
        {...avatarProps(name?.toUpperCase())}
        alt={name}
        src={getImage() || undefined}
        variant={
          savedImage?.binaryData || avatar || !defaultImage ? "circular" : "square"
        }
        classes={{
          root: styles["up-avatar-root"],
        }}
      />

      {editable && (
        <>
          <Button
            onClick={() => setOpened(true)}
            classes={{ root: styles["up-upload-img"] }}
          >
            Upload new photo
          </Button>

          <SwipeableDrawer
            open={opened}
            onClose={() => setOpened(false)}
            onOpen={() => setOpened(true)}
            anchor="bottom"
            swipeAreaWidth={44}
            disableSwipeToOpen={true}
            classes={{ paper: styles["up-drawer"] }}
          >
            <div className={styles["up-puller"]} />

            <List classes={{ root: styles["up-list"] }}>
              <ListItem classes={{ root: styles["up-list-item"] }} disablePadding>
                <label htmlFor="library-upload">
                  <input
                    type="file"
                    onClick={(e) => ((e.target as HTMLInputElement).value = "")}
                    id="library-upload"
                    accept="image/*"
                    hidden
                    onChange={onSelectFile}
                  />
                  <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                    <img alt="icon" src={LibraryIcon} className={styles["up-list-img"]} />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Choose from library"}
                    classes={{ root: styles["up-list-text"] }}
                  />
                </label>
              </ListItem>

              <ListItem classes={{ root: styles["up-list-item"] }} disablePadding>
                <label htmlFor="camera-upload">
                  <input
                    type="file"
                    onClick={(e) => ((e.target as HTMLInputElement).value = "")}
                    id="camera-upload"
                    accept="image/*"
                    hidden
                    capture
                    onChange={onSelectFile}
                  />
                  <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                    <img alt="icon" src={CameraIcon} className={styles["up-list-img"]} />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Take a new photo"}
                    classes={{ root: styles["up-list-text"] }}
                  />
                </label>
              </ListItem>

              {savedImage && (
                <ListItem classes={{ root: styles["up-list-item"] }} disablePadding>
                  <label
                    onClick={() => {
                      setSavedImage(null);
                      toggleImage && toggleImage(null);
                      setModalOpened(false);
                    }}
                  >
                    <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                      <img
                        alt="icon"
                        src={DeleteIcon}
                        className={styles["up-list-img"]}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Delete current"}
                      classes={{ root: styles["up-list-text"] }}
                    />
                  </label>
                </ListItem>
              )}
            </List>
          </SwipeableDrawer>

          <Dialog
            open={modalOpened}
            onClose={() => {
              setModalOpened(false);
            }}
          >
            <DialogContent className={styles["up-dialog-content"]}>
              <div className={styles["up-dialog-content-item"]}>
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={1}
                  minWidth={croppedImageSize}
                >
                  <img src={imgSrc} alt="resource" ref={imgRef} onLoad={onImageLoad} />
                </ReactCrop>
                <canvas
                  className={styles["up-dialog-canvas"]}
                  ref={canvasRef}
                  width={croppedImageSize}
                  height={croppedImageSize}
                ></canvas>
              </div>
            </DialogContent>
            <DialogActions className={styles["up-dialog-actions"]}>
              <Button
                classes={{
                  root: styles["up-dialog-actions-button"],
                  outlined: styles["outlined"],
                }}
                color={"inherit"}
                onClick={() => setModalOpened(false)}
                variant="outlined"
              >
                {"Cancel"}
              </Button>
              <Button
                classes={{
                  root: styles["up-dialog-actions-button"],
                  contained: styles["contained"],
                }}
                color={"inherit"}
                onClick={saveImage}
                variant="contained"
                disabled={!completedCrop?.width}
              >
                {"Save"}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default UpAvatar;
