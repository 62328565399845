import axios from "axios";
import {
  AppointmentsType,
  DayScheduleActionsNew,
  ResourceLocationStatusEnum,
} from "shared/Enums";
import { formatResponseWarning } from "shared/helpers/common.helper";

import { ResponseDto, VisitDetailsNewI } from "@interfaces";

import {
  apiCreateVisit,
  apiDeleteVisit,
  apiDeleteVisitResource,
  apiEditVisit,
  apiGetExifVisitResources,
  apiGetMediumVisitResources,
  apiGetVisitData,
  apiGetVisitName,
  apiGetVisitsNameIdList,
  apiUploadVisitResources,
} from "./Api/Visits.api.service";
import { getLocationViewData } from "./Locations.service";

const moment = require("moment-timezone");

export const UploadVisitResources = (
  id: string,
  body: any,
  name: string
): Promise<any> => {
  return apiUploadVisitResources(id, body, name)
    .then(() => {
      return axios
        .all([apiGetMediumVisitResources([id]), apiGetExifVisitResources([id])])
        .then((resources: any) => {
          const visitsResources = resources[0]?.data[id]?.medium || [];
          const exif = resources[1].data[id] || [];
          return {
            count: visitsResources?.length,
            items: visitsResources?.map((i: any) => {
              const found = exif.find((exif: any) => exif.image_uuid === i.file.uuid);
              return {
                src: found?.url,
                mediumSrc: i.imagecache,
                id: i.file.uuid,
                timeStatus:
                  found?.statuses.datetime_status || ResourceLocationStatusEnum.unknown,
                geoStatus:
                  found?.statuses.geolocation_status ||
                  ResourceLocationStatusEnum.unknown,
                timeLimit: found?.errors.datetime_error,
                geoLimit: found?.errors.geolocation_error,
                date: i.file.created * 1000,
              };
            }),
          };
        });
    })
    .catch((err) => err);
};

export const getVisitsNameIdList = (
  pageNumber: number,
  pageSize: number,
  timezone: string,
  id: string
) => {
  return apiGetVisitsNameIdList(pageSize, pageNumber * pageSize, timezone, id)
    .then((resp) => {
      const response = formatResponseWarning(resp.data);
      return {
        items:
          response.data?.map((item: any) => {
            const location = resp.data.included?.find(
              (i: ResponseDto<any>) =>
                i.type === "ups_location--ups_location" &&
                i.id === item.relationships.location?.data?.id
            );

            return {
              id: item.id,
              title: `Visit to ${location?.attributes.label}`,
              locationId: location?.id,
            };
          }) || [],
        hasMore: !(response.data?.length < pageSize),
      };
    })
    .catch((err) => err);
};

export const getVisitName = (id: string) => {
  return apiGetVisitName(id)
    .then((resp) => {
      const location = resp.data.included?.find(
        (i: ResponseDto<any>) => i.type === "ups_location--ups_location"
      );

      return {
        id: resp.data.data.id,
        title: `Visit to ${location?.attributes.label}`,
        locationId: location?.id,
      };
    })
    .catch((err) => err);
};

export const deleteVisitResource = (
  id: string,
  visitId: string,
  resourcesids: string[]
): Promise<any> => {
  return apiDeleteVisitResource(id)
    .then(() => {
      editVisit(visitId, resourcesids)
        .then(() => {})
        .catch((err) => err);
    })
    .catch((err) => err);
};

export const editVisit = (visitId: string, ids: string[]): Promise<any> => {
  return apiEditVisit(visitId, {
    data: {
      type: "ups_visit--ups_visit",
      id: visitId,
      relationships: {
        images: {
          data: ids.map((i) => {
            return {
              type: "file--file",
              id: i,
            };
          }),
        },
      },
    },
  }).catch((err) => err);
};

export const CreateVisit = (
  timezone: string,
  locationId: string,
  dayId: string,
  status: string
) => {
  return apiCreateVisit({
    data: {
      type: "ups_visit--ups_visit",
      attributes: {
        date: moment().utc().tz(timezone).format("YYYY-MM-DDTHH:mm:ssZZ"),
        visit_status: status,
      },
      relationships: {
        location: {
          data: {
            type: "ups_location--ups_location",
            id: locationId,
          },
        },
        day: {
          data: {
            type: "ups_day--ups_day",
            id: dayId,
          },
        },
      },
    },
  })
    .then((response) => {
      return response.data.data.id;
    })
    .catch((err) => err);
};

export const DeleteVisit = (id: string) => {
  return apiDeleteVisit(id).catch((err) => err);
};

export const getVisitData = (
  visitId: string,
  params: {
    userIdForTask?: string;
    userIdForNotes?: string;
  }
) => {
  return apiGetVisitData(visitId).then((response) => {
    return getLocationViewData(
      response.data.data.relationships.location.data?.id,
      0,
      params
    ).then((location) => {
      return axios
        .all([apiGetMediumVisitResources([visitId]), apiGetExifVisitResources([visitId])])
        .then((resources: any) => {
          const visitsResources = resources[0]?.data[visitId]?.medium || [];
          const exif = resources[1].data[visitId] || [];
          return {
            title: "",
            id: response.data.data.id,
            status: response.data.data.attributes.visit_status,
            appointment: response.data.data.relationships.appointment.data,
            location: location,
            arrival: response.data.data.attributes.date,
            isVirtual: response.data.data.attributes.is_virtual,
            dateEnd: response.data.data.attributes.date_end,
            resources: {
              count: visitsResources?.length,
              items: visitsResources?.map((i: any) => {
                const found = exif.find((exif: any) => exif.image_uuid === i.file.uuid);
                return {
                  src: found?.url,
                  mediumSrc: i.imagecache,
                  id: i.file.uuid,
                  timeStatus:
                    found?.statuses.datetime_status || ResourceLocationStatusEnum.unknown,
                  geoStatus:
                    found?.statuses.geolocation_status ||
                    ResourceLocationStatusEnum.unknown,
                  timeLimit: found?.errors.datetime_error,
                  geoLimit: found?.errors.geolocation_error,
                  date: i.file.created * 1000,
                };
              }),
            },
          } as VisitDetailsNewI;
        });
    });
  });
};

export const editVisitStatus = (
  id: string,
  body: DayScheduleActionsNew,
  timezone: string,
  isVirtual?: AppointmentsType
): Promise<any> => {
  let action = "";
  let date = {};
  switch (body) {
    case DayScheduleActionsNew.skipVisit:
      action = "skipped";
      break;
    case DayScheduleActionsNew.unskipVisit:
      action = "pending";
      break;
    case DayScheduleActionsNew.completeVisit:
      action = "completed";
      date = {
        date_end: moment().utc().tz(timezone).format("YYYY-MM-DDTHH:mm:ssZZ"),
      };
      break;
  }
  const isVirtualObj = isVirtual
    ? {
        is_virtual: isVirtual === AppointmentsType.call,
      }
    : {};
  return apiEditVisit(id, {
    data: {
      type: "ups_visit--ups_visit",
      id: id,
      attributes: {
        visit_status: action,
        ...isVirtualObj,
        ...date,
      },
    },
  }).catch((err) => err);
};
