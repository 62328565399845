import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./ViewReports.module.scss";
import Header from "components/Shared/Header/Header";
import {
  getAdminUrl,
  isUserHavePermissions,
  openExternalLink,
} from "shared/helpers/common.helper";
import { PermissionsList } from "shared/Enums";
import { useContext } from "react";
import { UserDataContext } from "shared/Contexts";

export default function ViewReports() {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserDataContext);

  return (
    <div className={styles["up-container"]}>
      <Header
        title={"Reports"}
        toggleBack={() => navigate("/")}
        hideReload={true}
        opacity={0}
      />
      <div className={styles["up-link-wrapper"]}>
        {isUserHavePermissions(currentUser!, [
          PermissionsList.viewPerformanceReports,
        ]) && (
          <Link
            onClick={() =>
              openExternalLink(`${getAdminUrl()}/admin/reports/ups-reports/performance`)
            }
            target="_blank"
            underline="hover"
          >
            Performance
          </Link>
        )}
        {isUserHavePermissions(currentUser!, [PermissionsList.viewLocationsReports]) && (
          <Link
            onClick={() =>
              openExternalLink(`${getAdminUrl()}/admin/reports/ups-reports/locations`)
            }
            target="_blank"
            underline="hover"
          >
            Locations
          </Link>
        )}
        {isUserHavePermissions(currentUser!, [PermissionsList.viewTasksReports]) && (
          <Link
            onClick={() =>
              openExternalLink(`${getAdminUrl()}/admin/reports/ups-reports/tasks`)
            }
            target="_blank"
            underline="hover"
          >
            Tasks
          </Link>
        )}
      </div>
      <div className={styles["up-info"]}>Best viewed on desktop</div>
    </div>
  );
}
