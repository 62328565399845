import { AxiosResponse } from "axios";
import { Moment } from "moment-timezone";

import { helperApi, jsonApiFile, jsonAuthApi } from "./Settings.api.service";

const moment = require("moment-timezone");
export const apiEditVisit = (id: string, body: any): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.patch(`/ups_visit/ups_visit/${id}`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiCreateVisit = (body: any): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.post(`/ups_visit/ups_visit/`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiDeleteVisit = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonApiFile.delete(`/ups_visit/ups_visit/${id}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiUploadVisitResources = (
  id: string,
  body: any,
  name: string
): Promise<AxiosResponse<any, any>> => {
  return jsonApiFile.post(`/ups_visit/ups_visit/${id}/images`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
      "Content-Disposition": `file; filename="${name}"`,
    },
  });
};

export const apiGetMediumVisitResources = (
  uuids: string[]
): Promise<AxiosResponse<any, any>> => {
  return helperApi.get(`image_style/visit`, {
    params: {
      uuids,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
export const apiGetExifVisitResources = (
  uuids: string[]
): Promise<AxiosResponse<any, any>> => {
  return helperApi.get(`/image/exif`, {
    params: {
      uuids,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiDeleteVisitResource = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonApiFile.delete(`/file/file/${id}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
export const apiGetVisitsNameIdList = (
  limit: number,
  page: number,
  timezone: string,
  userId: string
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_visit/ups_visit`, {
    params: {
      "page[limit]": limit,
      "page[offset]": page,
      "filter[status][value]": "1",
      "filter[uid.id][value]": userId,
      "filter[search-group][group][conjunction]": "AND",
      "filter[compaire][condition][path]": "date",
      "filter[compaire][condition][value]": moment()
        .utc()
        .add(-1, "day")
        .tz(timezone)
        .set("hour", 23)
        .set("minute", 59)
        .set("second", 59)
        .format("YYYY-MM-DDTHH:mm:ssZ"),
      "filter[compaire][condition][operator]": ">",
      "filter[compaire][condition][memberOf]": "search-group",

      "filter[compaire2][condition][path]": "date",
      "filter[compaire2][condition][value]": moment()
        .utc()
        .add(1, "day")
        .tz(timezone)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .format("YYYY-MM-DDTHH:mm:ssZ"),
      "filter[compaire2][condition][operator]": "<",
      "filter[compaire2][condition][memberOf]": "search-group",

      "filter[visit_status][condition][path]": "visit_status",
      "filter[visit_status][condition][operator]": "=",
      "filter[visit_status][value]": "in_progress",
      "filter[visit_status][condition][memberOf]": "search-group",
      // "filter[isNull][condition][path]": "day",
      // "filter[isNull][condition][operator]": "IS NOT NULL",
      include: "location",
    },
  });
};

export const apiGetVisitData = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_visit/ups_visit/${id}`, {
    params: {},
  });
};

export const apiGetVisitsList = (
  date: Moment,
  id: string
): Promise<AxiosResponse<any, any>> => {
  const firstDay = date.clone().startOf("day");
  const lastDay = date.clone().endOf("day");
  return jsonAuthApi.get(`/ups_visit/ups_visit`, {
    params: {
      include: "location,uid",
      "filter[uid.id]": id,
      "filter[status]": "1",
      "filter[date][operator]": "BETWEEN",
      "filter[date][value][0]": firstDay.format("YYYY-MM-DDTHH:mm:ssZ"),
      "filter[date][value][1]": lastDay.format("YYYY-MM-DDTHH:mm:ssZ"),
      "filter[visit_status]": "completed",
      // "filter[isNull][condition][path]": "day",
      // "filter[isNull][condition][operator]": "IS NOT NULL",
    },
  });
};

export const apiGetVisitsByMonth = (
  date: Moment,
  status: string,
  uid: string
): Promise<AxiosResponse<any, any>> => {
  const firstDay = date.clone().startOf("month");
  const lastDay = firstDay.clone().add(1, "M");
  const statusFilter = status
    ? {
        "filter[visit_status]": status,
      }
    : {};
  return jsonAuthApi.get(`/ups_visit/ups_visit`, {
    params: {
      include: "uid",
      "filter[uid.id]": uid,
      "filter[status]": "1",
      "filter[date][operator]": "BETWEEN",
      "filter[date][value][0]": firstDay.format(),
      "filter[date][value][1]": lastDay.format(),
      // "filter[isNull][condition][path]": "day",
      // "filter[isNull][condition][operator]": "IS NOT NULL",
      ...statusFilter,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetVisitName = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_visit/ups_visit/${id}`, {
    params: {
      include: "location",
    },
  });
};
