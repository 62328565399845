import { AxiosResponse } from "axios";

import { customAuthApi, jsonAuthApi } from "./Settings.api.service";

export const apiGetRouting = (data: any): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.get(`api/v1/routing/calculate`, {
    params: {
      ...data,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetAgentRouting = (data: any): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.get(`api/v1/routing/get`, {
    params: {
      ...data,
    },
  });
};

export const apiGetDay = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_day/ups_day/${id}`, {
    params: {
      include: "steps.location.priority,visits",
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiEditDay = (id: string, body: any): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.patch(`/ups_day/ups_day/${id}`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiEditStep = (id: string, body: any): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.patch(`/ups_timeslot/visit/${id}`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
export const apiGetLastDayByDate = (
  date: string,
  agentId?: string
): Promise<AxiosResponse<any, any>> => {
  const params: {
    include: string;
    "filter[date]": string;
    "filter[agent.id]"?: string;
  } = {
    include: "visits.location",
    "filter[date]": date,
  };

  if (agentId) {
    params["filter[agent.id]"] = agentId;
  }

  return jsonAuthApi.get(`/ups_day/ups_day`, {
    params,
  });
};

export const apiCreateStartLocation = (body: any): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.post(`/ups_agent_location/ups_agent_location/`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetLocationsAdresses = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_agent_location/ups_agent_location/`, {
    params: {
      "filter[uid.id][value]": id,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
export const apiGetLocationStartTime = (
  date: string
): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.get(`/api/v1/startTime/get`, {
    params: {
      date,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
export const apiGetLocationEndTime = (date: string): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.get(`/api/v1/endTime/get`, {
    params: {
      date,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiSetLocationStartTime = (
  date: string,
  time: number
): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.get(`/api/v1/startTime/set`, {
    params: {
      date,
      time_start: time,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
export const apiSetLocationEndTime = (
  date: string,
  time: number
): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.get(`/api/v1/endTime/set`, {
    params: {
      date,
      time_end: time,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
