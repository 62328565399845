import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./NoteCreate.module.scss";
import { NotesForm } from "components/Shared/Notes/NotesForm/NotesForm";
import { NoteFormI } from "@interfaces";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { createNote } from "shared/Services";

function NoteCreate() {
  const navigate = useNavigate();
  const params = useParams();

  const [submitted, setSubmitted] = useState(0);

  const FormSubmit = (value: NoteFormI) => {
    createNote(value).then((date: AxiosError | any) => {
      if (axios.isAxiosError(date)) {
        toast.error(
          date.response?.data?.errors?.length
            ? date.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        toast.success("Successfully completed!");
        navigate(`/notes/${date}`);
      }
    });
  };

  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Create note"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        position={"absolute"}
        background="transparent"
        onCancelToggle={() => navigate(-1)}
      />
      <NotesForm
        submitted={submitted}
        formFinished={FormSubmit}
        locationId={params.locationId}
        taskId={params.taskId}
        visitId={params.visitId}
      />
    </div>
  );
}

export default NoteCreate;
