import axios from "axios";
import { toast } from "react-toastify";
import moment, { Moment } from "moment-timezone";
import styles from "./DayScheduleCalendar.module.scss";
import DayScheduleBreak from "../DayScheduleBreak/DayScheduleBreak";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import UpTabs from "components/Shared/Tabs/Tabs";
import VisitCard from "components/Shared/VisitCard/VisitCard";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ManagerContext, RouterContext, UserDataContext } from "shared/Contexts";
import {
  DayScheduleActionsNew,
  DayScheduleStepsStatusesNew,
  DayScheduleStepsTypeNew,
  DayScheduleTypeNew,
  TabsStatuses,
} from "shared/Enums";
import { WeekShortDays } from "shared/helpers/profile.helper";
import {
  CreateVisit,
  editStepStatus,
  editVisitStatus,
  DeleteVisit,
  GetLastDayByDate,
  getRouting,
  getAgentRouting,
  editDayStatus,
} from "shared/Services";

import { DaySchedulePageNewI, VisitBreaksNewI, VisitCardsNewI } from "@interfaces";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TodayIcon from "@mui/icons-material/Today";
import { Button } from "@mui/material";
import { getCurrentTestTime } from "shared/helpers/time.helper";
import { CalendarDatePicker } from "../DatePicker/DatePicker";
import { Footer } from "components/Shared/Footer/Footer";

function DayScheduleCalendar() {
  const navigate = useNavigate();

  const [routerValue, setRouterValue] = useContext(RouterContext);
  const { currentUser } = useContext(UserDataContext);
  const [{ agent }] = useContext(ManagerContext);

  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState<DaySchedulePageNewI>();

  const [dayOffType, setDayOffType] = useState<null | "vacation" | "day off">(null);

  const isFeatureDate = !routerValue.selectedDate.isBefore(
    moment().utc().tz(currentUser!.timezone.value),
    "day"
  );

  const changeTab = (type: TabsStatuses) => {
    setRouterValue((prev) => ({ ...prev, selectedTab: type }));
  };

  const changeDay = (val: number) => {
    setIsLoading(true);
    const newDate = routerValue.selectedDate.add(val, "days");
    setRouterValue((prev) => ({ ...prev, selectedDate: newDate.clone() }));
  };

  function isCard(object: VisitCardsNewI | VisitBreaksNewI): object is VisitCardsNewI {
    return object.cardType === DayScheduleStepsTypeNew.service;
  }

  const ActionToggle = (
    action: DayScheduleActionsNew,
    id?: string,
    locationId?: string
  ) => {
    setIsLoading(true);
    switch (action) {
      case DayScheduleActionsNew.skipVisit:
        // eslint-disable-next-line no-case-declarations
        const active =
          (content!.steps.active?.filter(
            (i) => isCard(i) && i.id === id
          ) as VisitCardsNewI[]) || [];

        // eslint-disable-next-line no-case-declarations
        const foundActiveVisitId = [...active].find((i) => i.visitId)?.visitId;
        if (foundActiveVisitId) {
          editVisitStatus(foundActiveVisitId, action, currentUser!.timezone.value).then(
            (data) => {
              if (axios.isAxiosError(data)) {
                toast.error(
                  data.response?.data?.errors?.length
                    ? data.response?.data?.errors[0]?.detail
                    : ""
                );
              }
              editStepStatus(id!, action).then((data) => {
                if (axios.isAxiosError(data)) {
                  toast.error(
                    data.response?.data?.errors?.length
                      ? data.response?.data?.errors[0]?.detail
                      : ""
                  );
                } else {
                  Calculate();
                }
              });
            }
          );
        } else {
          CreateVisit(
            currentUser!.timezone.value,
            locationId!,
            content!.day!.id,
            "skipped"
          ).then((data) => {
            if (axios.isAxiosError(data)) {
              toast.error(
                data.response?.data?.errors?.length
                  ? data.response?.data?.errors[0]?.detail
                  : ""
              );
            }
            editStepStatus(id!, action).then((data) => {
              if (axios.isAxiosError(data)) {
                toast.error(
                  data.response?.data?.errors?.length
                    ? data.response?.data?.errors[0]?.detail
                    : ""
                );
              } else {
                Calculate();
              }
            });
          });
        }
        break;
      case DayScheduleActionsNew.unskipVisit:
        // eslint-disable-next-line no-case-declarations
        const skipped =
          (content!.steps.skipped?.filter(
            (i) => isCard(i) && i.id === id
          ) as VisitCardsNewI[]) || [];

        // eslint-disable-next-line no-case-declarations
        const foundVisitId = [...skipped].find((i) => i.visitId)?.visitId;
        if (foundVisitId) {
          DeleteVisit(foundVisitId).then((data) => {
            if (axios.isAxiosError(data)) {
              toast.error(
                data.response?.data?.errors?.length
                  ? data.response?.data?.errors[0]?.detail
                  : ""
              );
            }
            editStepStatus(id!, action).then((data) => {
              if (axios.isAxiosError(data)) {
                toast.error(
                  data.response?.data?.errors?.length
                    ? data.response?.data?.errors[0]?.detail
                    : ""
                );
              } else {
                Calculate();
              }
            });
          });
        } else {
          editStepStatus(id!, action).then((data) => {
            if (axios.isAxiosError(data)) {
              toast.error(
                data.response?.data?.errors?.length
                  ? data.response?.data?.errors[0]?.detail
                  : ""
              );
            } else {
              Calculate();
            }
          });
        }
        break;
      case DayScheduleActionsNew.createVisit:
        if (content!.steps.active.find((i) => isCard(i) && i.visitId)) {
          toast.info(
            "You already have an unfinished visit. Please complete it before starting a new one."
          );
          setIsLoading(false);
        } else {
          CreateVisit(
            currentUser!.timezone.value,
            locationId!,
            content!.day!.id,
            DayScheduleStepsStatusesNew.inProgress
          ).then((data) => {
            if (axios.isAxiosError(data)) {
              toast.error(
                data.response?.data?.errors?.length
                  ? data.response?.data?.errors[0]?.detail
                  : ""
              );
            } else {
              navigate("/visits/" + data);
            }
          });
        }
        break;
    }
  };

  const EditPastDay = (action: DayScheduleActionsNew, id?: string) => {
    setIsLoading(true);
    switch (action) {
      case DayScheduleActionsNew.skipVisit:
        id &&
          editVisitStatus(id, action, currentUser!.timezone.value).then((data) => {
            if (axios.isAxiosError(data)) {
              toast.error(
                data.response?.data?.errors?.length
                  ? data.response?.data?.errors[0]?.detail
                  : ""
              );
            }

            setIsLoading(false);
          });
        break;
    }
  };
  const Calculate = () => {
    if (isFeatureDate) {
      // if it's a working day and not a vacation
      if (
        currentUser!.workingDays?.includes(routerValue.selectedDate.isoWeekday()) &&
        !currentUser?.vacations?.find((i: Moment) =>
          i.isSame(routerValue.selectedDate, "day")
        )
      ) {
        getRouting(routerValue.selectedDate, routerValue.myLocation!).then((resp) => {
          if (axios.isAxiosError(resp)) {
            setIsLoading(false);
            toast.error(
              resp.response?.data?.errors?.length
                ? resp.response?.data?.errors[0]?.detail
                : resp.response?.data?.message ||
                    `see DayScheduleCalendar.tsx line 236. resp: ${JSON.stringify(resp)}`
            );
            return;
          } else if (resp.error) {
            setIsLoading(false);
            toast.error(typeof resp.error === "string" ? resp.error : resp.error[0]);
            setContent(undefined);
            return;
          }
          changeTab(TabsStatuses.active);
          setContent(resp);
        });
      } else {
        // if it's a vacation or not a working day
        setIsLoading(false);
        setContent(undefined);
        setDayOffType(
          !currentUser!.workingDays?.includes(routerValue.selectedDate.isoWeekday())
            ? "day off"
            : "vacation"
        );
      }
    } else {
      GetLastDayByDate(routerValue.selectedDate).then((resp) => {
        if (axios.isAxiosError(resp)) {
          setIsLoading(false);
          toast.error(resp.response?.data?.errors[0]?.detail);
          return;
        }
        if (routerValue.selectedTab === TabsStatuses.active) {
          changeTab(TabsStatuses.completed);
        }
        setContent(resp);
      });
    }
  };

  const CalculateForManager = () => {
    if (isFeatureDate) {
      getAgentRouting(routerValue.selectedDate, agent?.uuid as string).then((resp) => {
        if (axios.isAxiosError(resp)) {
          setIsLoading(false);
          toast.error(
            resp.response?.data?.errors?.length
              ? resp.response?.data?.errors[0]?.detail
              : resp.response?.data?.message ||
                  `see DayScheduleCalendar.tsx line 268. resp: ${JSON.stringify(resp)}`
          );
          return;
        } else if (resp.error) {
          setIsLoading(false);
          toast.error(typeof resp.error === "string" ? resp.error : resp.error[0]);
          setContent(undefined);
          return;
        }

        if (routerValue.selectedTab !== TabsStatuses.active) {
          changeTab(TabsStatuses.active);
        }
        setContent(resp);
      });
    } else {
      GetLastDayByDate(routerValue.selectedDate, agent?.uuid).then((resp) => {
        if (axios.isAxiosError(resp)) {
          setIsLoading(false);
          toast.error(
            resp.response?.data?.errors?.length
              ? resp.response?.data?.errors[0]?.detail
              : ""
          );
          return;
        }
        setContent(resp);
        if (routerValue.selectedTab === TabsStatuses.active) {
          changeTab(TabsStatuses.completed);
        }
      });
    }
  };

  const isToday = () => {
    const today = moment().tz(currentUser!.timezone.value).startOf("day"); // Get the start of today
    return routerValue.selectedDate.isSame(today, "day");
  };

  useEffect(() => {
    setIsLoading(true);
    setDayOffType(null);
    agent ? CalculateForManager() : Calculate();
  }, [routerValue.selectedDate, agent]);

  useEffect(() => {
    content && setIsLoading(false);
  }, [content]);

  const onDayStart = async () => {
    try {
      await editDayStatus(content!.day.id, {
        day_status: DayScheduleTypeNew.started,
        actual_time_start: getCurrentTestTime(currentUser!),
      });

      setContent({
        ...content!,
        day: {
          ...content!.day,
          status: DayScheduleTypeNew.started,
        },
      });
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const onDayEnd = async () => {
    try {
      await editDayStatus(content!.day.id, {
        day_status: DayScheduleTypeNew.ended,
        actual_time_start: getCurrentTestTime(currentUser!),
      });
      setContent({
        ...content!,
        day: {
          ...content!.day,
          status: DayScheduleTypeNew.ended,
        },
      });
    } catch (e: any) {
      toast.error(e.message);
    }
  };
  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-header"]}>
        <div className={styles["up-header-left"]}>
          <Button
            classes={{ root: styles["up-button"] }}
            variant="outlined"
            color={"inherit"}
            disabled={isLoading}
            onClick={() => changeDay(-1)}
            aria-label="back"
          >
            <NavigateBeforeIcon fontSize={"large"} />
          </Button>
          <CalendarDatePicker
            date={routerValue.selectedDate}
            dataLoading={isLoading}
            handleChange={(newDate) => {
              setIsLoading(true);
              setRouterValue((prev) => ({ ...prev, selectedDate: newDate }));
            }}
          />
        </div>
        <h1 className={styles["up-title"]}>
          <span>{routerValue.selectedDate.format("MMM D, YYYY")} - </span>
          <span
            className={
              routerValue.selectedDate.day() === 0 || routerValue.selectedDate.day() === 6
                ? styles["weekend"]
                : ""
            }
          >
            {WeekShortDays[routerValue.selectedDate.day()]}
          </span>
          {/* {currentUser?.routerSettings.testMode.isTest && <span>{moment.utc(moment.duration(currentUser?.routerSettings.testMode.time, "seconds").asMilliseconds())
              .format('HH:mm')}
            </span>} */}
          {!isToday() && (
            <span
              onClick={() => {
                setIsLoading(true);
                setRouterValue((prev) => ({
                  ...prev,
                  selectedDate: moment().tz(currentUser!.timezone.value),
                }));
              }}
              className={styles["up-title--today-icon"]}
            >
              <TodayIcon />
            </span>
          )}
        </h1>
        <Button
          classes={{ root: styles["up-button"] }}
          variant="outlined"
          color={"inherit"}
          disabled={isLoading}
          onClick={() => changeDay(1)}
          aria-label="next"
        >
          <NavigateNextIcon fontSize={"large"} />
        </Button>
      </div>
      <LoadingSpinner isLoading={isLoading}>
        {dayOffType ? (
          <div className="up-no-items">
            {dayOffType === "day off"
              ? "This is your day-off."
              : "This is your vacation day."}
          </div>
        ) : (
          // ) : isDayEnded ? (
          //   <>Day is ended</>
          <>
            {content?.steps.passed?.length ? (
              <div className={styles["up-passed"]}>
                <div className={styles["up-passed-title"]}>
                  Complete the visit below to end your day.
                </div>
                {content.steps.passed.map((item, index) => (
                  <div key={index} className={styles["up-card"]}>
                    <VisitCard
                      item={item}
                      day={content.day}
                      onActionToggle={EditPastDay}
                    />
                  </div>
                ))}
              </div>
            ) : null}

            {content && content.tabs && content.steps[routerValue.selectedTab] && (
              <UpTabs
                tabs={content.tabs}
                content={content.steps[routerValue.selectedTab]}
                changeTab={changeTab}
                selectedTab={routerValue.selectedTab}
              >
                <>
                  {content.steps[routerValue.selectedTab]?.length ? (
                    content.steps[routerValue.selectedTab]?.map((item, index) => {
                      if (isCard(item)) {
                        return (
                          <div key={index} className={styles["up-card"]}>
                            <VisitCard
                              item={item}
                              day={content.day}
                              onActionToggle={ActionToggle}
                            ></VisitCard>
                          </div>
                        );
                      } else {
                        return (
                          <div key={index} className={styles["up-card"]}>
                            <DayScheduleBreak
                              onDayStart={onDayStart}
                              onDayEnd={onDayEnd}
                              day={content.day}
                              item={item}
                              showActions={!agent}
                            />
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div className="up-no-items">No visits found</div>
                  )}
                </>
              </UpTabs>
            )}
          </>
        )}
      </LoadingSpinner>
      <Footer />
      {isFeatureDate && (
        <>
          <Button
            onClick={() =>
              setRouterValue((prev) => ({
                ...prev,
                opened: true,
                routeData: content,
              }))
            }
            disabled={isLoading || !content?.steps.active?.length}
            classes={{ root: styles["up-route"] }}
          >
            Route
          </Button>
          <div className={styles["up-route-spacer"]}></div>
        </>
      )}
    </div>
  );
}

export default DayScheduleCalendar;
