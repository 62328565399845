import styles from "../AgentCard/AgentCard.module.scss";
import { AgentI } from "@interfaces";
import { SyntheticEvent, useContext, useState } from "react";
import { Avatar } from "@mui/material";
import { stringAvatar } from "shared/helpers/profile.helper";
import { AgentDialog } from "../AgentDialog/AgentDialog";
import { ManagerContext } from "shared/Contexts";
import { useNavigate } from "react-router-dom";

export default function AgentCard({ agent }: { agent: AgentI }) {
  const navigate = useNavigate();
  const [, setManagerContext] = useContext(ManagerContext);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { email, fullName, avatar } = agent;

  const handleClose = (e: SyntheticEvent) => {
    e.stopPropagation();
    setIsDialogOpen(false);
  };

  return (
    <div className={styles["up-agents-card"]} onClick={() => setIsDialogOpen(true)}>
      <Avatar
        {...stringAvatar(fullName.toUpperCase())}
        classes={{ root: styles["up-agents-card--img-wrapper"] }}
        alt={fullName}
        src={avatar || "/dummy_image"}
      />

      <div className={styles["up-agents-card--body"]}>
        <div>{fullName}</div>
        <div className={styles["up-agents-card--body--email"]}>{email}</div>
      </div>

      <AgentDialog
        open={isDialogOpen}
        name={fullName}
        onClose={(e) => handleClose(e)}
        onSubmit={() => {
          setManagerContext({
            agent,
          });
          navigate("/");
        }}
      />
    </div>
  );
}
