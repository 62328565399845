import axios, { AxiosError } from "axios";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import NotesCards from "components/Shared/Notes/NotesCards/NotesCards";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CountriesStatesContext, UserDataContext } from "shared/Contexts";
import { PermissionsList, TaskPriority, TaskStatuses } from "shared/Enums";
import {
  checkPermissionsForEdit,
  formatAddressToString,
} from "shared/helpers/common.helper";
import { checkDate12AMto00 } from "shared/helpers/time.helper";
import { completeTask } from "shared/Services";

import { Button } from "@mui/material";

import TickIcon from "../../../../assets/icons/tick.svg";
import styles from "./TaskView.module.scss";
import { toast } from "react-toastify";
import moment from "moment";
import { useTaskInfo } from "./hooks/useTaskInfo";

function TaskView() {
  const { countriesStates } = useContext(CountriesStatesContext);
  const { currentUser } = useContext(UserDataContext);

  const navigate = useNavigate();
  const params = useParams();

  const { taskInfo, loading, getContent, setTaskInfo } = useTaskInfo(
    params.taskId,
    currentUser!
  );

  const CompleteTask = () => {
    completeTask(params.taskId!, currentUser!.timezone.value).then(
      (data: AxiosError | any) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          toast.success("succefully completed!");
          setTaskInfo({
            ...taskInfo!,
            status: {
              name: TaskStatuses.completed,
              date: data.data.data.attributes.completion_date,
            },
          });
        }
      }
    );
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <Header
        title={"Task"}
        toggleBack={() => navigate(-1)}
        toggleReload={() => getContent()}
        opacity={0}
      />
      <LoadingSpinner isLoading={loading} height={300}>
        {taskInfo ? (
          <div className={styles["up-container"]}>
            <div className={styles["up-card"]}>
              <div className={styles["up-card-title"]}>
                {taskInfo.title}
                {checkPermissionsForEdit(
                  currentUser!,
                  PermissionsList.editAnyTask,
                  PermissionsList.editOwnTask,
                  taskInfo.authorId
                ) && (
                  <Button
                    classes={{ root: styles["up-card-title-edit"] }}
                    color={"inherit"}
                    onClick={() => navigate("edit")}
                  >
                    Edit
                  </Button>
                )}
              </div>

              <div className={styles["up-card-title-location"]}>
                {taskInfo.location.title}
              </div>

              <div className={styles["up-card-footer"]}>
                <div className={styles.address}>
                  {formatAddressToString(taskInfo.location.address, countriesStates)}
                </div>
                {!(taskInfo.status?.name === TaskStatuses.completed) ? (
                  <Button
                    classes={{ root: styles["up-card-footer-button"] }}
                    color={"inherit"}
                    onClick={CompleteTask}
                  >
                    Complete
                  </Button>
                ) : (
                  <div className={styles["status"]}>
                    <img src={TickIcon} alt="tick" />
                    Completed <br />
                    {taskInfo.status.date &&
                      checkDate12AMto00(
                        taskInfo.status.date,
                        currentUser!.timezone.value
                      )}
                  </div>
                )}
              </div>
            </div>
            <div className={styles["up-content"]}>
              <div className={styles["up-description"]}>
                <label className={styles["up-content-label"]}>Description</label>
                <div className={styles["up-content-value"]}>{taskInfo.description}</div>
              </div>
              {taskInfo.deadline && (
                <div className={styles["up-deadline"]}>
                  <label className={styles["up-content-label"]}>
                    Deadline (end of day)
                  </label>
                  <div
                    className={`${styles["up-content-value"]} ${
                      moment(taskInfo.deadline).isBefore(moment()) ? styles.missed : ""
                    }`}
                  >
                    {moment(taskInfo.deadline).format("ll")}
                  </div>
                </div>
              )}
              <div className={styles["up-priority"]}>
                <label className={styles["up-content-label"]}>
                  Priority (High priority tasks affect how the system generated agent
                  routes)
                </label>
                <div
                  className={`${styles["up-content-value"]} ${
                    taskInfo.priority === "high" ? styles.high : ""
                  }`}
                >
                  {TaskPriority[taskInfo.priority as "regular" | "high"]}
                </div>
              </div>
              {taskInfo.products.length ? (
                <>
                  <label className={styles["up-content-label"]}>Products</label>
                  <div className={styles["up-content-value"]}>
                    {taskInfo.products.map((i) => i.title).join(", ")}
                  </div>
                </>
              ) : null}
            </div>

            <div className={styles["up-notes"]}>
              <NotesCards
                showTitle={true}
                content={taskInfo.notes}
                ids={{ taskId: params.taskId! }}
              ></NotesCards>
            </div>
          </div>
        ) : (
          <></>
        )}
      </LoadingSpinner>
    </>
  );
}

export default TaskView;
