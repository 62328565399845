import styles from "./AgentsListView.module.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AgentsList from "components/Shared/Agents/AgentsList/AgentsList";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";

export default function AgentsListView() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const reload = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 50);
  };

  return (
    <div className={styles["up-container"]}>
      <LoadingSpinner isLoading={isLoading}>
        <Header
          title={"Switch to User"}
          toggleBack={() => navigate("/")}
          toggleReload={reload}
        />

        <AgentsList />
      </LoadingSpinner>
    </div>
  );
}
