import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";

import PageNotFound from "../../PageNotFound/PageNotFound";
import TaskCreate from "./TaskCreate/TaskCreate";
import TaskEdit from "./TaskEdit/TaskEdit";
import TasksView from "./TasksView/TasksView";
import TaskView from "./TaskView/TaskView";
import { ErrorBoundary } from "error";
import { Footer } from "components/Shared/Footer/Footer";

function TasksRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <>
      <Routes>
        {isUserHavePermissions(currentUser!, [
          PermissionsList.viewAnyTask,
          PermissionsList.viewOwnTask,
          PermissionsList.viewMyServiceTask,
        ]) && (
          <>
            <Route
              path="/"
              element={
                <ErrorBoundary>
                  <TasksView />
                </ErrorBoundary>
              }
            />
            <Route
              path="/location/:locationId"
              element={
                <ErrorBoundary>
                  <TasksView />
                </ErrorBoundary>
              }
            />
            <Route
              path="/:taskId"
              element={
                <ErrorBoundary>
                  <TaskView />
                </ErrorBoundary>
              }
            />
          </>
        )}

        {isUserHavePermissions(currentUser!, [PermissionsList.createTask]) && (
          <>
            <Route
              path="create"
              element={
                <ErrorBoundary>
                  <TaskCreate />
                </ErrorBoundary>
              }
            />
            <Route
              path="create/:locationId"
              element={
                <ErrorBoundary>
                  <TaskCreate />
                </ErrorBoundary>
              }
            />
          </>
        )}

        {isUserHavePermissions(currentUser!, [
          PermissionsList.editAnyTask,
          PermissionsList.editOwnTask,
        ]) && (
          <Route
            path="/:taskId/edit"
            element={
              <ErrorBoundary>
                <TaskEdit />
              </ErrorBoundary>
            }
          />
        )}

        <Route path={`*`} element={<PageNotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default TasksRouter;
