import axios from "axios";
import { ResourceLocationStatusEnum } from "shared/Enums";
import { formatResponseWarning } from "shared/helpers/common.helper";

import {
  LocationBlockFormI,
  LocationFormI,
  LocationNameI,
  LocationResourcesI,
  LocationsListI,
  LocationViewI,
  ResponseDto,
} from "@interfaces";

import {
  apiCreateLocation,
  apiDeleteLocation,
  apiEditLocationData,
  apiGetLocationData,
  apiGetLocationName,
  apiGetLocationResources,
  apiGetLocationsList,
  apiUploadLocationLogo,
  apiGetTimezones,
} from "./Api/Locations.api.service";
import { apiGetNotesListByLocation } from "./Api/Notes.api.service";
import { apiGetTasksListByLocation } from "./Api/Tasks.api.service";
import {
  convertWorkingHoursToJsonFormat,
  formatWorkingHours,
} from "../helpers/time.helper";

export const getLocationsList = (
  pageNumber: number,
  searchText: string,
  params?: {
    countriesStates?: { states: string[]; countries: string[] };
    companyId?: string;
    filterByAgent?: string;
    filterByUser?: string;
  }
) => {
  return apiGetLocationsList(pageNumber, searchText, params).then((resp) => {
    const response = formatResponseWarning(resp.data);
    return {
      hasMore: !(response.data?.length < 20),
      content: Object.values(
        response.data?.reduce((acc: any, item: any) => {
          const firstLetter = item.attributes.label.charAt(0).toLowerCase();
          if (!acc[firstLetter]) {
            acc[firstLetter] = { letter: firstLetter, locations: [] };
          }
          const contactsIds = item.relationships.contacts.data?.map(
            (i: { id: any }) => i.id
          );
          const mappedItem = {
            id: item.id,
            title: item.attributes.label,
            address: item.attributes.address,
            logo: response.included?.find(
              (included: any) => included.id === item.relationships.image.data?.id
            )?.attributes.uri.url,
            company: response.included?.find(
              (included: any) => included.id === item.relationships.company.data?.id
            )?.attributes.label,
            contacts: response.included
              ?.filter((included: any) => contactsIds.includes(included.id))
              .map((i: any) => i.attributes.label),
          };
          acc[firstLetter].locations.push(mappedItem);
          return acc;
        }, {})
      ) as LocationsListI[],
    };
  });
};

export const getLocationName = (id: string) => {
  return apiGetLocationName(id).then((resp) => {
    const response = formatResponseWarning(resp.data);
    return {
      id: response.data.id,
      title: response.data.attributes.label,
      address: response.data.attributes.address,
      phoneNumbers: response.data.attributes.telephones,
    } as LocationNameI;
  });
};

export const getLocationData = (id: string) => {
  return apiGetLocationData(id).then((resp) => {
    const response = formatResponseWarning(resp.data);

    const locationAvatar: ResponseDto<any> = response.included?.find(
      (i: ResponseDto<any>) => i.type === "file--file"
    );

    const company = response.included?.find(
      (i: ResponseDto<any>) => i.type === "ups_company--ups_company"
    );
    const agent = response.included?.find(
      (i: ResponseDto<any>) => i.type === "user--user"
    );
    const priority = response.included?.find(
      (i: ResponseDto<any>) => i.type === "ups_priority--ups_priority"
    );
    return {
      id: response.data.id,
      name: response.data.attributes.label,
      address: response.data.attributes.address,
      phoneNumbers: response.data.attributes.telephones,
      emails: response.data.attributes.emails,
      geolocation: response.data.attributes.geolocation,
      avatar: locationAvatar
        ? {
            url: locationAvatar.attributes.uri.url,
            id: locationAvatar.id,
          }
        : null,
      blocked: response.data.attributes.blocked,
      blockedUntil: response.data.attributes.blocked_until,
      company: {
        name: company?.attributes.label,
        id: company?.id,
      },
      agent: {
        id: agent?.id,
        title: agent?.attributes.display_name,
      },
      priority: {
        title: priority?.attributes.label,
        id: priority?.id,
      },
      authorId: response.data.relationships.uid.data.id,
      hasTimeSchedule: response.data.attributes.field_has_time_schedule,
      timezone: response.data.attributes.field_timezone,
      workingHours: formatWorkingHours(response.data.attributes.field_working_hours),
    } as LocationViewI;
  });
};

export const getLocationViewData = (
  id: string,
  userTimezoneOffset = 0,
  params: {
    userIdForTask?: string;
    userIdForNotes?: string;
  }
) => {
  return axios
    .all([
      apiGetLocationData(id),
      apiGetTasksListByLocation(id, params.userIdForTask),
      apiGetNotesListByLocation(id, params.userIdForNotes),
    ])
    .then((resp) => {
      const response = formatResponseWarning(resp[0].data);
      const locationAvatar: ResponseDto<any> = response.included?.find(
        (i: ResponseDto<any>) => i.type === "file--file"
      );
      const locationContacts =
        response.included
          ?.filter((i: ResponseDto<any>) => i.type === "ups_contact--ups_contact")
          ?.map((i: any) => {
            return {
              name: i.attributes.label,
              phoneNumbers: i.attributes.telephones,
              id: i.id,
              emails: i.attributes.emails,
            };
          }) || [];

      const notesList =
        resp[2]?.data.data?.map(
          (i: {
            [x: string]: any;
            id: string;
            attributes: { created: string; note: { value: string } };
          }) => {
            return {
              id: i.id,
              date: i.attributes.created,
              content: i.attributes.note.value,
              author: resp[2].data.included?.find(
                (author: { id: any }) => author.id === i.relationships.uid.data.id
              )?.attributes.display_name,
            };
          }
        ) || [];
      const tasksList =
        resp[1]?.data.data?.map((i: any) => {
          return {
            id: i.id,
            title: i.attributes.label,
            description: i.attributes.description?.value || "",
            completionDate: i.attributes.completion_date,
            status: i.attributes.status,
            deadline: i.attributes.deadline,
            priority: i.attributes.priority,
          };
        }) || [];

      const company = response.included?.find(
        (i: ResponseDto<any>) => i.type === "ups_company--ups_company"
      );

      const agent = response.included?.find(
        (i: ResponseDto<any>) => i.type === "user--user"
      );
      const priority = response.included?.find(
        (i: ResponseDto<any>) => i.type === "ups_priority--ups_priority"
      );
      return {
        id: response.data.id,
        name: response.data.attributes.label,
        address: response.data.attributes.address,
        phoneNumbers: response.data.attributes.telephones,
        emails: response.data.attributes.emails,
        geolocation: response.data.attributes.geolocation,
        avatar: locationAvatar
          ? {
              url: locationAvatar.attributes.uri.url,
              id: locationAvatar.id,
            }
          : null,
        contacts: locationContacts,
        blocked: response.data.attributes.blocked,
        blockedUntil: response.data.attributes.blocked_until,
        company: {
          name: company?.attributes.label,
          id: company?.id,
        },
        tasks: {
          items: tasksList,
          count: resp[1]?.data.meta.count || 0,
        },
        notes: {
          items: notesList,
          count: resp[2]?.data.meta.count || 0,
        },
        authorId: response.data.relationships.uid.data.id,
        priority: {
          title: priority?.attributes.label,
          id: priority?.id,
        },
        lastVisit: response.data.attributes.last_visit,
        hasTimeSchedule: response.data.attributes.field_has_time_schedule,
        timezone: response.data.attributes.field_timezone,
        workingHours: response.data.attributes.field_working_hours
          ? formatWorkingHours(
              response.data.attributes.field_working_hours,
              response.data.attributes.field_timezone,
              userTimezoneOffset
            )
          : [],
        agent: {
          id: agent?.id,
          title: agent?.attributes.display_name,
        },
      } as LocationViewI;
    });
};

export const editLocationData = (id: string, body: LocationFormI) => {
  if (body.avatar?.arrayBuffer) {
    return apiUploadLocationLogo(id, body.avatar.arrayBuffer)
      .then(() => {
        editLocationDataWithAvatar(id, body);
      })
      .catch((err) => err);
  } else if (!body.avatar) {
    return editLocationDataWithAvatar(id, body, true);
  }
  return editLocationDataWithAvatar(id, body);
};

const editLocationDataWithAvatar = (
  id: string,
  body: LocationFormI,
  deleteAvatar?: boolean
) => {
  const image = deleteAvatar
    ? {
        image: {
          data: null,
        },
      }
    : {};

  return apiEditLocationData(id, {
    data: {
      type: "ups_location--ups_location",
      id: id,
      attributes: {
        address: body.address,
        label: body.title,
        telephones: body.phoneNumbers,
        emails: body.emails,
        geolocation: body.geolocation,
        field_has_time_schedule: body.hasTimeSchedule,
        field_timezone: body.timezone,
        field_working_hours: convertWorkingHoursToJsonFormat(body.workingHours),
      },
      relationships: {
        company: {
          data: {
            type: "ups_company--ups_company",
            id: body.selectedCompany.id,
          },
        },
        priority: {
          data: {
            type: "ups_priority--ups_priority",
            id: body.priority,
          },
        },
        agent: {
          data: {
            type: "user--user",
            id: body.agent,
          },
        },
        ...image,
      },
    },
  }).catch((err) => err);
};
export const blockLocation = (id: string, body: LocationBlockFormI) => {
  return apiEditLocationData(id, {
    data: {
      type: "ups_location--ups_location",
      id: id,
      attributes: {
        blocked: body.isBlocked,
        blocked_until: body.isBlocked && body.isDateSelected ? body.endDate : null,
      },
    },
  }).catch((err) => err);
};
export const createLocation = (body: LocationFormI) => {
  return apiCreateLocation({
    data: {
      type: "ups_location--ups_location",
      attributes: {
        address: body.address,
        label: body.title,
        telephones: body.phoneNumbers,
        emails: body.emails,
        geolocation: body.geolocation,
      },
      relationships: {
        company: {
          data: {
            type: "ups_company--ups_company",
            id: body.selectedCompany.id,
          },
        },
        agents: {
          data: {
            type: "user--user",
            id: body.agent,
          },
        },
        priority: {
          data: {
            type: "ups_priority--ups_priority",
            id: body.priority,
          },
        },
      },
    },
  })
    .then((response) => {
      if (body.avatar?.arrayBuffer) {
        return apiUploadLocationLogo(response.data.data.id, body.avatar.arrayBuffer)
          .then(() => {
            return response.data.data.id;
          })
          .catch((err) => err);
      }

      return response.data.data.id;
    })
    .catch((err) => err);
};

export const deleteLocation = (id: string) => {
  return apiDeleteLocation(id).catch((err) => err);
};

export const getLocationResources = (
  id: string,
  pageSize: number,
  pageNumber: number
) => {
  return apiGetLocationResources([id], pageSize, pageNumber).then((resp) => {
    const resources = resp.data as {
      [key: number | string]: LocationResourcesI;
    };
    const count = resources.count;
    const errors: any = resources.errors;
    delete resources.count;
    delete resources.errors;
    return {
      count: count,
      items: Object.values(resources)?.map((i) => {
        return {
          src: i.url,
          mediumSrc: i.styles.medium,
          id: i.data.image_uuid,
          timeStatus: i?.statuses.datetime_status || ResourceLocationStatusEnum.unknown,
          geoStatus: i?.statuses.geolocation_status || ResourceLocationStatusEnum.unknown,
          timeLimit: errors.datetime_error,
          geoLimit: errors.geolocation_error,
          date: i.created * 1000,
        };
      }),
    } as any;
  });
};

export const getTimezones = () => {
  return apiGetTimezones()
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => err);
};
