import { LatLngI } from "@interfaces";

export const openMapApp = (geolocation: LatLngI, callback: (value: string) => void) => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isAndroid = userAgent.includes("android");
  const isiOS = /(iphone|ipad|ipod|ios)/i.test(userAgent);

  if (isAndroid) {
    window.open(`geo:0,0?q=${geolocation.lat},${geolocation.lng}`, "_blank");
  } else if (isiOS) {
    window.open(`maps://q=${geolocation.lat},${geolocation.lng}`, "_blank");
  } else {
    callback("Unsupported device");
  }
};
