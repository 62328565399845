import { ResourceI, ResourcesListI } from "@interfaces";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getLocationResources } from "shared/Services/Locations.service";

export function useResources(locationId?: string) {
  const [resourcesInfo, setResourcesInfo] = useState<ResourcesListI>({
    count: 0,
    items: [],
  });
  const [resourcesList, setResourcesList] = useState<ResourceI[]>([]);

  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [resourcesError, setResourcesError] = useState<boolean>(false);
  const pageSize = 20;

  useEffect(() => {
    setResourcesError(false);
    (async () => {
      if (locationId) {
        try {
          const resourcesData = await getLocationResources(locationId!, pageSize, 0);
          setResourcesInfo(resourcesData);
          setResourcesList(resourcesData.items);
        } catch (error: any) {
          setResourcesError(error);
        }
      }
    })();
  }, [locationId]);

  useEffect(() => {
    setResourcesError(false);
    (async () => {
      if (pageNumber > 0) {
        try {
          const data = await getLocationResources(
            locationId!,
            pageSize,
            pageNumber * pageSize
          );
          setHasMore(resourcesList.length + data.items.length < data.count);
          setResourcesList([...resourcesList, ...data.items]);
        } catch (error: any) {
          toast.error(
            error.response?.data?.errors?.length
              ? error.response?.data?.errors[0]?.detail
              : error.message
          );
          setResourcesError(true);
        }
      }
    })();
  }, [pageNumber]);

  return {
    resourcesInfo,
    resourcesList,
    hasMore,
    pageNumber,
    setPageNumber,
    resourcesError,
  };
}
