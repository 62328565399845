import styles from "./Route.module.scss";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { SwipeableDrawer } from "@mui/material";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import VisitCard from "components/Shared/VisitCard/VisitCard";
import DayScheduleBreak from "../DaySchedule/DayScheduleBreak/DayScheduleBreak";
import { Circle as CircleMarker, Plane as PlaneMarker } from "components/Shared/Marker";
import FlagIcon from "../../../assets/icons/mapMarkers/marker-flag.svg";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ManagerContext, RouterContext, UserDataContext } from "shared/Contexts";
import {
  TabsStatuses,
  DayScheduleStepsTypeNew,
  DayScheduleTypeNew,
  TrafficModelE,
  UnitSystemE,
  PermissionsList,
} from "shared/Enums";

import { TaskCardI, VisitBreaksNewI, VisitCardsNewI } from "@interfaces";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  MarkerF,
} from "@react-google-maps/api";
import { useMyLocation } from "./hooks/useMyLocation";
import { useDirections } from "./hooks/useDirections";
import { getTasksList } from "shared/Services";
import axios from "axios";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import TaskCard from "components/Shared/Tasks/TaskCard/TaskCard";

function RouteView() {
  const [routerValue, setRouterValue] = useContext(RouterContext);
  const [{ agent }] = useContext(ManagerContext);
  const { currentUser } = useContext(UserDataContext);
  const [map, setMap] = useState<google.maps.Map>();
  const [startPoint, setStartPoint] = useState<any>(null);
  const [startLocationAngle, setStartLocationAngle] = useState<number>(0);
  const [response, setResponse] = useState<any>(null);
  const [opened, setOpened] = useState<boolean>(false);
  const [card, setCard] = useState<VisitCardsNewI | VisitBreaksNewI>();
  const [rerender, setRerender] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerLoading, setIsDrawerLoading] = useState(true);
  const [customDashedRoute, setCustomDashedRoute] = useState<any>(null);
  const [tasksList, setTasksList] = useState<TaskCardI[]>([]);

  const location = useLocation();

  useMyLocation(routerValue, setRouterValue, agent, currentUser);

  const {
    waypoints,
    missedAppointments,
    skipped,
    completed,
    startLocation,
    endLocation,
  } = useDirections(routerValue, setIsLoading, setResponse);

  useEffect(() => {
    setOpened(false);
  }, [location, routerValue.gMapOpened]);

  useEffect(() => {
    if (routerValue.opened) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "inherit";
  }, [routerValue.opened]);

  useEffect(() => {
    waypoints.length && setIsLoading(false);
    setRerender(true);
  }, [waypoints]);

  useEffect(() => {
    if (response) {
      const firstStep = response.routes[0]?.legs[0]?.steps[0];
      const firstPoint = firstStep?.path[0] as google.maps.LatLng;
      const secondPoint = firstStep?.path[1] as google.maps.LatLng;
      if (secondPoint && firstPoint) {
        // Calculate the angle between the start and end locations of the first step
        const angle = google.maps.geometry.spherical.computeHeading(
          firstPoint,
          secondPoint
        );
        setStartLocationAngle(angle);
      }
      // Reset start and end locations from routes (road locations)
      setStartPoint({
        location: {
          lat: firstPoint.lat(),
          lng: firstPoint.lng(),
        },
        cardType: DayScheduleStepsTypeNew.start,
        status: TabsStatuses.active,
      });

      setCustomDashedRoute(renderCustomDashedRoute());
    }
  }, [response]);

  const renderCustomDashedRoute = () => {
    if (!response) {
      return null;
    }

    const legs = response.routes[0].legs;
    const polylines = [];
    const directions = {
      ...response,
      routes: [
        {
          ...response.routes[0],
          legs: legs.slice(0, legs.length - 1), // Exclude the last leg
        },
      ],
    };

    // Create regular polylines for all legs except the last one
    for (let i = 0; i < legs.length - 1; i++) {
      const legPath = legs[i].steps.flatMap((step: google.maps.DirectionsStep) =>
        google.maps.geometry.encoding.decodePath(step.polyline?.points as string)
      );

      polylines.push(
        <DirectionsRenderer
          key={i}
          options={{
            polylineOptions: {
              path: legPath,
              strokeWeight: 3,
              strokeColor: "#541F1B",
            },
            hideRouteList: true,
            suppressMarkers: true,
            suppressPolylines: false,
            directions,
            map: map,
          }}
        />
      );
    }

    return polylines;
  };

  const directionsCallback = (
    result: google.maps.DirectionsResult | null,
    status: google.maps.DirectionsStatus
  ) => {
    setRerender(false);
    if (!response) {
      if (status === "OK") {
        setResponse(result);
      } else {
        toast.error("Directions request returned no results.");
      }
    }
  };

  const showSidenav = (
    id: string,
    status: TabsStatuses,
    cardType: DayScheduleStepsTypeNew
  ) => {
    setIsDrawerLoading(true);
    setOpened(true);
    setTasksList([]);
    const card = routerValue.routeData?.steps[status].find(
      (i) =>
        (cardType === DayScheduleStepsTypeNew.service && i.id === id) ||
        (cardType !== DayScheduleStepsTypeNew.service && i.cardType === cardType)
    ) as VisitCardsNewI;
    getTasksList(TabsStatuses.active, 0, card?.location.id, {
      id:
        !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceTask]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewOwnTask])
          ? currentUser!.uid
          : undefined,
      serviceId:
        !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceTask])
          ? currentUser!.uid
          : undefined,
    }).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(
          data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        setTasksList(data.content);
        setIsDrawerLoading(false);
      }
    });
    setCard(card);
  };

  const GetTrafficModel = (model: TrafficModelE): google.maps.TrafficModel => {
    switch (model) {
      case TrafficModelE.bestguess:
        return google.maps.TrafficModel.BEST_GUESS;
      case TrafficModelE.optimistic:
        return google.maps.TrafficModel.OPTIMISTIC;
      case TrafficModelE.pessimistic:
        return google.maps.TrafficModel.PESSIMISTIC;
    }
  };

  function isCard(object?: VisitCardsNewI | VisitBreaksNewI): object is VisitCardsNewI {
    return object?.cardType === DayScheduleStepsTypeNew.service;
  }

  function getContentForSecondaryLabel(item: any): string {
    return item.isLocationOverdue ? "Overdue" : item.appointment ? "Appointment" : "";
  }

  const headerTitle = (
    <div className={agent ? styles["up-header--agent-mode"] : ""}>
      {agent ? (
        <>
          <div>Route for</div>
          <div>{agent.fullName}</div>
        </>
      ) : (
        moment().tz(currentUser!.timezone.value).format("MMM DD, YYYY - ddd")
      )}
    </div>
  );

  return routerValue.loaded ? (
    <>
      <div className={styles["up-modal"]}>
        <Header
          title={headerTitle}
          hideReload={true}
          toggleBack={() => {
            setRouterValue({ ...routerValue, opened: false });
          }}
        />
        <LoadingSpinner isLoading={isLoading} height={300}>
          {startLocation &&
          endLocation &&
          waypoints &&
          routerValue.routeData?.steps.active?.length ? (
            <GoogleMap
              onLoad={(m) => setMap(m)}
              options={{
                mapId: "11a7b148a03577a0",
                fullscreenControl: false,
                mapTypeControl: false,
                zoomControl: false,
                streetViewControl: false,
              }}
              clickableIcons={false}
              mapContainerStyle={{
                width: "100vw",
                height: "100vh",
              }}
              zoom={18}
            >
              <>
                <PlaneMarker
                  angle={startLocationAngle}
                  position={{
                    lat: startPoint?.location.lat || startLocation.location.lat,
                    lng: startPoint?.location.lng || startLocation.location.lng,
                  }}
                  onClick={() => {
                    showSidenav(
                      startLocation.id,
                      startLocation.status,
                      startLocation.cardType
                    );
                  }}
                />

                <MarkerF
                  position={{
                    lat: endLocation.location.lat,
                    lng: endLocation.location.lng,
                  }}
                  icon={{
                    url: FlagIcon,
                    anchor: new google.maps.Point(4, 30),
                  }}
                  onClick={() =>
                    showSidenav(endLocation.id, endLocation.status, endLocation.cardType)
                  }
                />

                {waypoints.map((p: any, index: number) => {
                  let color;
                  if (p.appointment) {
                    color = "#954638";
                  } else if (p.isLocationOverdue) {
                    color = "#FF0901";
                  } else {
                    color = "#FF8050";
                  }

                  return (
                    <div key={index}>
                      <CircleMarker
                        position={p.location}
                        circle={{ fill: color }}
                        text={{ content: index + 1 }}
                        primaryLabel={{
                          content: p.locationName,
                          color,
                        }}
                        secondaryLabel={{
                          content: getContentForSecondaryLabel(p),
                          color,
                        }}
                        onClick={() => showSidenav(p.id, p.status, p.cardType)}
                      />
                    </div>
                  );
                })}

                {missedAppointments.map((p: any, index: number) => (
                  <div key={index}>
                    <CircleMarker
                      position={p.location}
                      circle={{ fill: "#686868" }}
                      primaryLabel={{
                        content: p.locationName,
                        color: "#333",
                      }}
                      onClick={() => showSidenav(p.id, p.status, p.cardType)}
                    />
                  </div>
                ))}

                {skipped.map((p: any, index: number) => (
                  <div key={index}>
                    <CircleMarker
                      position={p.location}
                      circle={{ fill: "#686868" }}
                      primaryLabel={{
                        content: p.locationName,
                        color: "#333",
                      }}
                      onClick={() => showSidenav(p.id, p.status, p.cardType)}
                    />
                  </div>
                ))}

                {completed.map((p: any, index: number) => {
                  const color = p.appointment ? "#954638" : "#FF8050";
                  return (
                    <div key={index}>
                      <CircleMarker
                        position={p.location}
                        circle={{ fill: color }}
                        text={{ content: "completed" }}
                        primaryLabel={{
                          content: p.locationName,
                          color,
                        }}
                        secondaryLabel={p.appointment && { content: "Appointment" }}
                        onClick={() => showSidenav(p.id, p.status, p.cardType)}
                      />
                    </div>
                  );
                })}

                {rerender && (
                  <DirectionsService
                    options={{
                      destination: endLocation.location,
                      origin: startLocation.location,
                      travelMode: google.maps.TravelMode.DRIVING,
                      optimizeWaypoints: false,
                      waypoints: waypoints.map((i: { location: any; stopover: any }) => ({
                        location: i.location,
                        stopover: i.stopover,
                      })),
                      avoidFerries:
                        routerValue.routeData?.day.status === DayScheduleTypeNew.started
                          ? currentUser?.routerSettings?.avoidFerries ||
                            agent?.profile.avoidFerries
                          : false,
                      avoidTolls:
                        routerValue.routeData?.day.status === DayScheduleTypeNew.started
                          ? currentUser?.routerSettings?.avoidTolls ||
                            agent?.profile.avoidTolls
                          : false,
                      avoidHighways:
                        routerValue.routeData?.day.status === DayScheduleTypeNew.started
                          ? currentUser?.routerSettings?.avoidHighways ||
                            agent?.profile.avoidHighways
                          : false,
                      unitSystem:
                        routerValue.routeData?.day.status === DayScheduleTypeNew.started
                          ? currentUser?.routerSettings?.unitSystem ===
                              UnitSystemE.metric ||
                            agent?.profile.unitSystem === UnitSystemE.metric
                            ? google.maps.UnitSystem.METRIC
                            : google.maps.UnitSystem.IMPERIAL
                          : google.maps.UnitSystem.IMPERIAL,
                      drivingOptions:
                        routerValue.routeData?.day.status === DayScheduleTypeNew.started
                          ? {
                              departureTime: new Date(),
                              trafficModel: GetTrafficModel(
                                agent
                                  ? agent?.profile.trafficModel
                                  : (currentUser?.routerSettings
                                      ?.trafficModel as TrafficModelE)
                              ),
                            }
                          : undefined,
                    }}
                    callback={directionsCallback}
                  />
                )}
              </>

              {response && customDashedRoute}
            </GoogleMap>
          ) : (
            <div className={styles["up-modal-error"]}>
              There are no routing steps to show
            </div>
          )}
        </LoadingSpinner>
      </div>

      <SwipeableDrawer
        open={opened}
        onClose={() => setOpened(false)}
        onOpen={() => setOpened(true)}
        anchor="bottom"
        swipeAreaWidth={44}
        disableSwipeToOpen={true}
        classes={{ paper: styles["up-drawer"] }}
      >
        <div className={styles["up-puller"]} />
        <div className={styles["up-drawer-content"]}>
          <LoadingSpinner isLoading={isDrawerLoading || isLoading} height={300}>
            <div className={styles["up-card"]}>
              {isCard(card) ? (
                <div className={styles["up-card"]}>
                  <VisitCard item={card} onActionToggle={() => {}}></VisitCard>
                  {!!tasksList.length && (
                    <>
                      <div className={styles["up-card-tasks-title"]}>
                        Tasks to do by creation date
                      </div>
                      <div className={styles["up-card-tasks"]}>
                        {tasksList.map((i) => (
                          <TaskCard key={i.id} card={i} />
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <>
                  {card && (
                    <div className={styles["up-card"]}>
                      <DayScheduleBreak item={card}></DayScheduleBreak>
                    </div>
                  )}
                </>
              )}
            </div>
          </LoadingSpinner>
        </div>
      </SwipeableDrawer>
    </>
  ) : (
    <div className={styles["up-modal"]}>Getting the location data</div>
  );
}

export default RouteView;
