import axios from "axios";
import { useFormik } from "formik";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { Login } from "shared/Services";
import * as Yup from "yup";

import { Button, Link, TextField } from "@mui/material";

import styles from "./Login.module.scss";
import { toast } from "react-toastify";

function LoginPage() {
  const navigate = useNavigate();
  const { login } = useContext(UserDataContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required").email("Invalid email"),
    password: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    } as {
      email: string;
      password: string;
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Login({ name: values.email.trim(), pass: values.password }).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data.message);
        } else {
          login(data.data.current_user);
        }
      });
    },
  });

  return (
    <div className={styles["up-container"]}>
      <h2 className={styles["up-title"]}>Login</h2>

      <form onSubmit={formik.handleSubmit}>
        <TextField
          classes={{ root: styles["up-form-email"] }}
          placeholder="email"
          variant="filled"
          hiddenLabel={true}
          fullWidth={true}
          InputProps={{
            classes: {
              root: styles["up-form-email-input"],
              focused: styles["focused"],
            },
          }}
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          classes={{ root: styles["up-form-password"] }}
          placeholder="Password"
          variant="filled"
          hiddenLabel={true}
          fullWidth={true}
          InputProps={{
            classes: {
              root: styles["up-form-password-input"],
              focused: styles["focused"],
            },
          }}
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          inputProps={{
            type: "password",
          }}
        />
        <div className={styles["up-form-link-wrapper"]}>
          <Link
            underline="none"
            onClick={() => navigate(`forgot-password`)}
            classes={{ root: styles["up-form-link"] }}
          >
            Forgot Password
          </Link>
        </div>
        <Button
          type="submit"
          color={"inherit"}
          classes={{ root: styles["up-form-button"] }}
          variant="contained"
        >
          Login
        </Button>
      </form>
    </div>
  );
}

export default LoginPage;
