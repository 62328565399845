import { createContext, Dispatch, SetStateAction } from "react";

import { DaySchedulePageNewI, LatLngI } from "@interfaces";
import { TabsStatuses } from "shared/Enums";
import { Moment } from "moment";
import moment from "moment";

export interface RouterContextI {
  opened: boolean;
  loaded: boolean;
  myLocation?: LatLngI;
  routeData?: DaySchedulePageNewI;
  gMapOpened: boolean;
  gMapGeolocation?: LatLngI;
  gMapLocationName?: string;
  isLocationLoaded?: boolean;
  isLocationHasError?: boolean;
  selectedTab: TabsStatuses;
  selectedDate: Moment;
  locationUpdatedTimeStamp?: number;
}
export const RouterContext = createContext<
  [RouterContextI, Dispatch<SetStateAction<RouterContextI>>]
>([
  {
    opened: false,
    loaded: false,
    gMapOpened: false,
    selectedTab: TabsStatuses.active,
    selectedDate: moment(),
  },
  () => {},
]);
