import { ResourceLocationStatusEnum } from "shared/Enums";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PublicIcon from "@mui/icons-material/Public";
import styles from "./GeoStatusIcons.module.scss";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { useState } from "react";

type GeoStatusIconsProps = {
  geoStatus: ResourceLocationStatusEnum;
  timeStatus: ResourceLocationStatusEnum;
  geoLimit: number;
  timeLimit: number;
};

export const GeoStatusIcons = ({
  geoStatus,
  timeStatus,
  geoLimit,
  timeLimit,
}: GeoStatusIconsProps) => {
  const [geoOpened, setGeoOpened] = useState(false);
  const [timeOpened, setTimeOpened] = useState(false);

  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-item"]}>
        <ClickAwayListener onClickAway={() => setGeoOpened(false)}>
          <Tooltip
            placement="top-start"
            arrow={true}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setGeoOpened(false)}
            open={geoOpened}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <ul className={styles["up-tooltip"]}>
                {(() => {
                  switch (geoStatus) {
                    case ResourceLocationStatusEnum.ok:
                      return (
                        <li>
                          Image has been taken within {geoLimit} meters of the visit
                          location
                        </li>
                      );
                    case ResourceLocationStatusEnum.bad:
                      return (
                        <li>
                          Image has been taken more than {geoLimit} meters from the visit
                          location.
                        </li>
                      );
                    case ResourceLocationStatusEnum.unknown:
                      return <li>Not enough information</li>;
                  }
                })()}
              </ul>
            }
          >
            <PublicIcon
              onClick={() => setGeoOpened((prev) => !prev)}
              className={`${styles["up-item-icon"]} ${
                geoStatus === ResourceLocationStatusEnum.unknown
                  ? styles["grey"]
                  : geoStatus === ResourceLocationStatusEnum.ok
                    ? styles["green"]
                    : styles["red"]
              }`}
            />
          </Tooltip>
        </ClickAwayListener>
      </div>
      <div className={styles["up-item"]}>
        <ClickAwayListener onClickAway={() => setTimeOpened(false)}>
          <Tooltip
            placement="top-start"
            arrow={true}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setTimeOpened(false)}
            open={timeOpened}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <ul className={styles["up-tooltip"]}>
                {(() => {
                  switch (timeStatus) {
                    case ResourceLocationStatusEnum.ok:
                      return (
                        <li>
                          Image has been taken within {timeLimit} minutes after visit
                          started
                        </li>
                      );
                    case ResourceLocationStatusEnum.bad:
                      return (
                        <li>
                          Image was not taken within {timeLimit} minutes after visit
                          started
                        </li>
                      );
                    case ResourceLocationStatusEnum.unknown:
                      return <li>Not enough information</li>;
                  }
                })()}
              </ul>
            }
          >
            <AccessTimeIcon
              onClick={() => setTimeOpened((prev) => !prev)}
              className={`${styles["up-item-icon"]} ${
                timeStatus === ResourceLocationStatusEnum.unknown
                  ? styles["grey"]
                  : timeStatus === ResourceLocationStatusEnum.ok
                    ? styles["green"]
                    : styles["red"]
              }`}
            />
          </Tooltip>
        </ClickAwayListener>
      </div>
    </div>
  );
};
