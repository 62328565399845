import { ProductsI } from "@interfaces";

import {
  apiGetCountriesAndStates,
  apiGetMissedAppointments,
  apiGetMissedVisits,
  apiGetProductsList,
  apiGetSettingsList,
  apiGetUserById,
  apiMuteNotifications,
} from "./Api/Common.api.service";
import { MuteNotificationsUntil } from "shared/Enums";
import moment from "moment";

export const getCountriesAndStates = () => {
  return apiGetCountriesAndStates().catch((err) => err);
};

export const getProductsList = (page: number) => {
  return apiGetProductsList(page)
    .then((data) => {
      return {
        hasMore: !(data.data.data?.length < 5),
        content:
          data.data.data?.map((i: { id: any; attributes: { label: string } }) => {
            return {
              id: i.id,
              title: i.attributes.label,
            };
          }) || ([] as ProductsI[]),
      };
    })
    .catch((err) => err);
};

export const getSettingsList = () => {
  return apiGetSettingsList()
    .then((i) => {
      return {
        ...i.data,
        priority: Object.values(i.data.priority),
      };
    })
    .catch((err) => err);
};
export const getMissedAppointment = (date: string) => {
  return apiGetMissedAppointments(date).catch((err) => err);
};

export const muteNotifications = (type: MuteNotificationsUntil) => {
  let date = "";
  switch (type) {
    case MuteNotificationsUntil.oneHour:
      date = moment().utc().add(1, "hours").format("YYYY-MM-DDTHH:mm:ss");
      break;
    case MuteNotificationsUntil.fourHours:
      date = moment().utc().add(4, "hours").format("YYYY-MM-DDTHH:mm:ss");
      break;
    case MuteNotificationsUntil.tomorrow: {
      date = moment().utc().endOf("day").format("YYYY-MM-DDTHH:mm:ss");
      break;
    }
    case MuteNotificationsUntil.unMute: {
      date = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
      break;
    }
  }

  return apiMuteNotifications(date);
};

export const getMissedVisits = (date: string, userId: string): Promise<any> => {
  return apiGetMissedVisits(date, userId)
    .then((data) => {
      return data.data.data.map((i: any) => {
        return {
          id: i.id,
          date: i.attributes.date,
          visit_status: i.attributes.visit_status,
        };
      });
    })
    .catch((err) => err);
};
export const getUserById = (userId: string): Promise<any> => {
  return apiGetUserById(userId)
    .then((data) => {
      const user = data.data.data;
      return {
        uuid: user?.id,
        fullName: user?.attributes.field_full_name,
        email: user?.attributes.mail,
      };
    })
    .catch((err) => err);
};
