import { Button, IconButton, Popover } from "@mui/material";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { useContext, useState } from "react";

import styles from "./UnmuteNotifications.module.scss";
import { muteNotifications } from "shared/Services/Common.service";
import { toast } from "react-toastify";
import { UserDataContext } from "shared/Contexts";
import { MuteNotificationsUntil } from "shared/Enums";

export const UnmuteNotifications = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { setCurrentUser } = useContext(UserDataContext);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const unMuteNotifications = async () => {
    try {
      const data = await muteNotifications(MuteNotificationsUntil.unMute);
      if (data.data.result) {
        setCurrentUser((prev) => ({
          ...prev!,
          mute_notifications_until: data.data.value,
          notificationsMuted: false,
        }));
      }
      handlePopoverClose();
    } catch (e) {
      toast.error("Error while muting notifications");
    }
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <IconButton color="inherit" aria-label="notifications" onClick={handlePopoverOpen}>
        <NotificationsOffIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        className={styles["up-popover"]}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
      >
        <Button
          className={styles["up-button"]}
          onClick={() => {
            unMuteNotifications();
          }}
        >
          Unmute notifications
        </Button>
      </Popover>
    </>
  );
};
