import { CircularProgress } from "@mui/material";

import styles from "./LoadingSpinner.module.scss";

function LoadingSpinner({
  isLoading,
  height,
  children,
}: {
  isLoading: boolean;
  height?: number;
  children?: JSX.Element | JSX.Element[];
}) {
  return (
    <>
      {isLoading ? (
        <div
          className={styles["loader-container"]}
          style={{ height: height ? `${height / 16}rem` : "auto" }}
        >
          <CircularProgress />
        </div>
      ) : (
        children
      )}
    </>
  );
}

export default LoadingSpinner;
