import axios from "axios";
import AddressField from "components/Shared/Forms/AddressField/AddressField";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import LocationsList from "components/Shared/Locations/LocationsList/LocationsList";
import UpAvatar from "components/Shared/Profile/Avatar/Avatar";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { getCompaniesList } from "shared/Services";
import {
  addressValidation,
  addressValidationWithoutRequired,
  linkValidation,
  phoneValidation,
} from "shared/Validation/Validation";
import * as Yup from "yup";

import { ContactFormI, ContactViewI, FileI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  FormHelperText,
  IconButton,
  Modal,
  Switch,
  TextField,
} from "@mui/material";

import DeleteIcon from "../../../../assets/icons/delete.svg";
import styles from "./ContactsForm.module.scss";
import { toast } from "react-toastify";

interface ContactsFormPropsI {
  submitted: number;
  formFinished: (value: ContactFormI) => void;
  formValue?: ContactViewI;
}

function ContactsForm({ submitted, formFinished, formValue }: ContactsFormPropsI) {
  const [isAsCompanyAddress, setIsAsCompanyAddress] = useState<boolean>(
    formValue?.sameAsCompanyAddress || false
  );
  const [avatar, setAvatar] = useState<FileI | null>(null);

  const [companiesOpened, setCompaniesOpened] = useState(false);
  const [companiesList, setCompaniesList] = useState<{ id: string; title: string }[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<{
    id: string;
    title: string;
  }>(
    formValue
      ? { id: formValue.company.id, title: formValue.company.name }
      : ({} as {
          id: string;
          title: string;
        })
  );
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const { currentUser } = useContext(UserDataContext);

  const [locationsOpened, setLocationsOpened] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<
    | {
        id: string;
        title: string;
      }
    | undefined
  >(
    formValue?.location
      ? { id: formValue.location.id, title: formValue.location.name }
      : undefined
  );

  useEffect(() => {
    submitted && formik.handleSubmit();
  }, [submitted]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    phoneNumbers: Yup.array().of(phoneValidation).required("This field is required"),
    address: isAsCompanyAddress ? addressValidationWithoutRequired : addressValidation,
    emails: Yup.array()
      .of(Yup.string().required("This field is required").email("Invalid email"))
      .required("This field is required"),
    links: Yup.array().of(linkValidation),
    company: Yup.string().required("This field is required"),
    location: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: formValue?.name,
      phoneNumbers: formValue?.phoneNumbers || [""],
      emails: formValue?.emails || [""],
      address: formValue?.address || {
        address_line: "",
        city: "",
        country_code: "",
        postal_code: "",
        state: "",
      },
      links: formValue?.allLinks || [""],
      company: formValue?.company.id || "",
      location: formValue?.location?.id || "",
    } as ContactFormI,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formFinished({
        ...values,
        selectedCompany,
        selectedLocation,
        avatar,
        isAsCompanyAddress,
      });
    },
  });

  useEffect(() => {
    getCompaniesList(
      pageNumber * 20,
      !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyCompany]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewOwnCompany])
        ? currentUser!.uid
        : undefined
    ).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(
          data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        setCompaniesList([...companiesList, ...data.items]);
        setHasMore(data.hasMore);
      }
    });
  }, [pageNumber]);

  useEffect(() => {
    setAvatar({
      binaryData: formValue?.avatar?.url || null,
      arrayBuffer: null,
    });
  }, []);

  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-card"]}>
        <UpAvatar
          name={formValue?.name}
          avatar={avatar?.binaryData || null}
          editable={true}
          toggleImage={setAvatar}
          defaultImage={null}
        />
      </div>
      <div className={styles["up-form"]}>
        <FormikProvider value={formik}>
          <TextField
            size="medium"
            classes={{ root: styles["up-form-company"] }}
            label="Company"
            variant="outlined"
            hiddenLabel={true}
            fullWidth={true}
            multiline
            onClick={() => setCompaniesOpened(true)}
            InputProps={{
              classes: { root: styles["up-form-company-input"] },
            }}
            id="company"
            name="company"
            value={selectedCompany.title}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
          />

          <Modal open={companiesOpened} onClose={() => {}}>
            <div className={styles["up-modal"]}>
              <FormsHeader
                title="Pick Company"
                position="relative"
                onCancelToggle={() => setCompaniesOpened(false)}
                onRightBtnToggle={() => setCompaniesOpened(false)}
              />
              <InfiniteScroll
                dataLength={companiesList.length}
                next={() => setPageNumber(pageNumber + 1)}
                hasMore={hasMore}
                height={"calc(100vh - 3.125rem)"}
                loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
              >
                {companiesList.length ? (
                  companiesList.map((i) => (
                    <div
                      className={`${styles["up-modal-company-item"]} ${
                        i.id === selectedCompany?.id ? styles["active"] : ""
                      }`}
                      key={i.id}
                      onClick={() => {
                        formik.setFieldValue("location", "");
                        setSelectedLocation(undefined);
                        formik.setFieldValue("company", i.id);
                        setSelectedCompany(i);
                        setCompaniesOpened(false);
                      }}
                    >
                      {i.title}
                    </div>
                  ))
                ) : (
                  <div className="up-no-items">No company records found</div>
                )}
              </InfiniteScroll>
            </div>
          </Modal>

          <TextField
            size="medium"
            classes={{ root: styles["up-form-location"] }}
            label="Location"
            variant="outlined"
            hiddenLabel={true}
            fullWidth={true}
            multiline
            onClick={() => setLocationsOpened(true)}
            InputProps={{
              classes: { root: styles["up-form-location-input"] },
            }}
            id="location"
            name="location"
            value={selectedLocation?.title || ""}
            error={formik.touched.location && Boolean(formik.errors.location)}
            helperText={formik.touched.location && formik.errors.location}
          />
          <Modal open={locationsOpened} onClose={() => {}}>
            <div className={styles["up-modal-location"]}>
              <FormsHeader
                title="Pick Location"
                position="relative"
                onCancelToggle={() => setLocationsOpened(false)}
                onRightBtnToggle={() => setLocationsOpened(false)}
              />

              <div className={styles["up-modal-location-content"]}>
                <LocationsList
                  hasSearch={true}
                  companyId={selectedCompany.id}
                  active={selectedLocation?.id || undefined}
                  locationSelected={(location) => {
                    formik.setFieldValue("location", location.id);
                    setSelectedLocation(location);
                    setLocationsOpened(false);
                  }}
                />
              </div>
            </div>
          </Modal>

          <TextField
            classes={{ root: styles["up-form-name"] }}
            label="name"
            variant="outlined"
            hiddenLabel={true}
            fullWidth={true}
            multiline
            InputProps={{
              classes: { root: styles["up-form-name-input"] },
            }}
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <FieldArray name="phoneNumbers">
            {(formHelpers: any) => (
              <div className={styles["up-form-phoneNumbers"]}>
                {formik.values.phoneNumbers?.map((_, index) => (
                  <TextField
                    key={index}
                    classes={{ root: styles["up-form-phoneNumbers-root"] }}
                    label={"Phone number"}
                    variant="outlined"
                    hiddenLabel={true}
                    fullWidth={true}
                    multiline
                    InputProps={{
                      classes: { root: styles["up-form-phoneNumbers-input"] },
                      endAdornment:
                        formik.values.phoneNumbers?.length > 1 ? (
                          <IconButton
                            onClick={() => formHelpers.remove(index)}
                            className={styles["up-form-phoneNumbers-delete"]}
                          >
                            <img src={DeleteIcon} alt="Delete" />
                          </IconButton>
                        ) : null,
                    }}
                    id={`phoneNumbers.${index}`}
                    name={`phoneNumbers.${index}`}
                    value={formik.values.phoneNumbers[index]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumbers &&
                      Boolean(
                        formik.errors.phoneNumbers
                          ? formik.errors.phoneNumbers[index]
                          : false
                      )
                    }
                  />
                ))}
                <Button
                  classes={{ root: styles["up-form-phoneNumbers-add"] }}
                  variant="outlined"
                  startIcon={<AddIcon color="inherit" />}
                  onClick={() => formHelpers.push("")}
                >
                  Add Telephone
                </Button>
                <FormHelperText>
                  {formik.touched.phoneNumbers &&
                    formik.errors.phoneNumbers &&
                    (typeof formik.errors.phoneNumbers === "string"
                      ? formik.errors.phoneNumbers
                      : formik.errors.phoneNumbers.filter((i) => i.length)[0])}
                </FormHelperText>
              </div>
            )}
          </FieldArray>

          <FieldArray name="emails">
            {(formHelpers: any) => (
              <div className={styles["up-form-emails"]}>
                {formik.values.emails?.map((_, index) => (
                  <TextField
                    key={index}
                    classes={{ root: styles["up-form-emails-root"] }}
                    label={"Email"}
                    variant="outlined"
                    hiddenLabel={true}
                    fullWidth={true}
                    multiline
                    InputProps={{
                      classes: { root: styles["up-form-emails-input"] },
                      endAdornment:
                        formik.values.emails?.length > 1 ? (
                          <IconButton
                            onClick={() => formHelpers.remove(index)}
                            className={styles["up-form-emails-delete"]}
                          >
                            <img src={DeleteIcon} alt="Delete" />
                          </IconButton>
                        ) : null,
                    }}
                    id={`emails.${index}`}
                    name={`emails.${index}`}
                    value={formik.values.emails[index]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.emails &&
                      Boolean(formik.errors.emails ? formik.errors.emails[index] : false)
                    }
                  />
                ))}
                <Button
                  classes={{ root: styles["up-form-emails-add"] }}
                  variant="outlined"
                  startIcon={<AddIcon color="inherit" />}
                  onClick={() => formHelpers.push("")}
                >
                  Add Email
                </Button>
                <FormHelperText>
                  {formik.touched.emails &&
                    formik.errors.emails &&
                    (typeof formik.errors.emails === "string"
                      ? formik.errors.emails
                      : formik.errors.emails.filter((i) => i.length)[0])}
                </FormHelperText>
              </div>
            )}
          </FieldArray>

          <div className={styles["up-form-address"]}>
            <div className={styles["up-form-address-switch"]}>
              <span className={styles["up-form-address-switch-label"]}>
                Same as company address
              </span>
              <Switch
                color="default"
                checked={isAsCompanyAddress}
                onChange={(e) => {
                  setIsAsCompanyAddress(e.target.checked);
                }}
                classes={{
                  root: styles["up-switch"],
                  thumb: styles["up-switch-thumb"],
                  track: styles["up-switch-track"],
                  switchBase: styles["up-switch-switch-base"],
                  checked: styles["up-switch-checked"],
                }}
              />
            </div>
            {!isAsCompanyAddress && (
              <AddressField
                addressValue={formValue?.address}
                toggleValue={(e) => formik.setFieldValue("address", e)}
                errors={formik.errors.address}
                touched={formik.touched.address}
              ></AddressField>
            )}
          </div>

          <FieldArray name="links">
            {(formHelpers: any) => (
              <div className={styles["up-form-links"]}>
                {formik.values.links?.map((_, index) => (
                  <TextField
                    key={index}
                    classes={{ root: styles["up-form-links-root"] }}
                    label={"Link"}
                    variant="outlined"
                    hiddenLabel={true}
                    fullWidth={true}
                    multiline
                    InputProps={{
                      classes: { root: styles["up-form-links-input"] },
                      endAdornment: (
                        <IconButton
                          onClick={() => formHelpers.remove(index)}
                          className={styles["up-form-links-delete"]}
                        >
                          <img src={DeleteIcon} alt="Delete" />
                        </IconButton>
                      ),
                    }}
                    id={`links.${index}`}
                    name={`links.${index}`}
                    value={formik.values.links[index]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.links &&
                      Boolean(formik.errors.links ? formik.errors.links[index] : false)
                    }
                    helperText={
                      formik.touched.links &&
                      formik.errors.links &&
                      formik.errors.links[index]
                    }
                  />
                ))}
                <Button
                  classes={{ root: styles["up-form-links-add"] }}
                  variant="outlined"
                  startIcon={<AddIcon color="inherit" />}
                  onClick={() => formHelpers.push("")}
                >
                  Add Link
                </Button>
              </div>
            )}
          </FieldArray>
        </FormikProvider>
      </div>
    </div>
  );
}

export default ContactsForm;
