import axios from "axios";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { deleteVisitResource, UploadVisitResources } from "shared/Services";

import { ResourceI, ResourcesListI } from "@interfaces";
import { PhotoCamera } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  ClickAwayListener,
  Dialog,
  IconButton,
  Link,
  SwipeableDrawer,
  Tooltip,
} from "@mui/material";

import DeleteIcon from "../../../../assets/icons/delete.svg";
import styles from "./Resources.module.scss";
import { toast } from "react-toastify";
import { GeoStatusIcons } from "components/Shared/GeoStatusIcons/GeoStatusIcons";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PublicIcon from "@mui/icons-material/Public";
import { getImagesUrl } from "shared/helpers/common.helper";
import moment from "moment";
import { UserDataContext } from "shared/Contexts";

function ViewResources({
  content,
  visitId,
  canAdd = false,
  scrollData,
  hasError,
  updateCount,
}: {
  content: ResourcesListI;
  visitId?: string;
  canAdd: boolean;
  scrollData?: {
    items: ResourceI[];
    setPageNumber: (action: number) => void;
    pageNumber: number;
    hasMore: boolean;
  };
  hasError?: boolean;
  updateCount: (e: number) => void;
}) {
  const [openedImageSrc, setOpenedImageSrc] = useState<null | ResourceI>(null);
  const [geoOpened, setGeoOpened] = useState(false);
  const { currentUser } = useContext(UserDataContext);

  const [items, setItems] = useState<ResourcesListI>({
    count: 0,
    items: [],
  });

  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    setItems(content);
  }, [content]);

  const deleteResource = (fileId: string) => {
    deleteVisitResource(
      fileId,
      visitId!,
      items.items.filter((i) => i.id !== fileId).map((i) => i.id)
    ).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(
          data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        updateCount(items.count - 1);
        setItems({
          count: items.count - 1,
          items: items.items.filter((i) => i.id !== fileId),
        });
        setOpenedImageSrc(null);
        toast.success("Successfully deleted!");
      }
    });
  };
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      const file = e.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          const result = event.target?.result;
          if (result) {
            UploadVisitResources(visitId!, reader.result, file.name).then((data) => {
              if (axios.isAxiosError(data)) {
                toast.error(
                  data.response?.data?.errors?.length
                    ? data.response?.data?.errors[0]?.detail
                    : ""
                );
              } else {
                setItems(data);
                updateCount(items.count + 1);
              }
            });
          }
        };
        reader.onerror = (event: ProgressEvent<FileReader>) => {
          console.error("Error reading image:", event.target?.error);
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-header"]}>
        <div style={{ marginRight: "auto" }} />
        {scrollData && content.items.length > 0 && (
          <Link
            onClick={() => setOpened(true)}
            underline="none"
            classes={{ root: styles["up-header-link"] }}
          >
            All Resources
          </Link>
        )}
        <ClickAwayListener onClickAway={() => setGeoOpened(false)}>
          <Tooltip
            placement="top-start"
            arrow={true}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setGeoOpened(false)}
            open={geoOpened}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <ul className={styles["up-tooltip"]}>
                <li className={styles["up-tooltip-item"]}>
                  <PublicIcon
                    className={`${styles["up-tooltip-icon"]} ${styles["grey"]}`}
                  />
                  <AccessTimeIcon
                    className={`${styles["up-tooltip-icon"]} ${styles["grey"]}`}
                  />
                  Not enough information
                </li>
                <li className={styles["up-tooltip-item"]}>
                  <PublicIcon
                    className={`${styles["up-tooltip-icon"]} ${styles["green"]}`}
                  />
                  Image has been taken in close vicinity of visit location
                </li>
                <li className={styles["up-tooltip-item"]}>
                  <PublicIcon
                    className={`${styles["up-tooltip-icon"]} ${styles["red"]}`}
                  />
                  Image has been taken too far from visit location
                </li>
                <li className={styles["up-tooltip-item"]}>
                  <AccessTimeIcon
                    className={`${styles["up-tooltip-icon"]} ${styles["green"]}`}
                  />
                  Image has been taken within acceptable period after visit started
                </li>
                <li className={styles["up-tooltip-item"]}>
                  <AccessTimeIcon
                    className={`${styles["up-tooltip-icon"]} ${styles["red"]}`}
                  />
                  Image was not taken within acceptable period after visit started
                </li>
              </ul>
            }
          >
            <IconButton
              onClick={() => setGeoOpened((prev) => !prev)}
              color="inherit"
              classes={{ root: styles["up-header-help"] }}
            >
              <HelpOutlineIcon className={`${styles["up-header-help-svg"]}`} />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>

        {canAdd && visitId && (
          <>
            <IconButton
              color="inherit"
              aria-label="upload picture"
              component="label"
              classes={{ root: styles["up-header-camera"] }}
            >
              <label htmlFor="camera-upload">
                <input
                  type="file"
                  onClick={(e) => ((e.target as HTMLInputElement).value = "")}
                  id="camera-upload"
                  accept="image/*"
                  hidden
                  capture
                  onChange={onSelectFile}
                />
                <PhotoCamera color={"inherit"} fontSize={"small"} />
              </label>
            </IconButton>

            <IconButton color="inherit" classes={{ root: styles["up-header-add"] }}>
              <label htmlFor="library-upload">
                <input
                  type="file"
                  onClick={(e) => ((e.target as HTMLInputElement).value = "")}
                  id="library-upload"
                  accept="image/*"
                  hidden
                  onChange={onSelectFile}
                />
                <AddIcon color={"inherit"} fontSize={"small"} />
              </label>
            </IconButton>
          </>
        )}
      </div>

      {hasError ? (
        <p className={styles["up-grid-no-items"]}>
          There was an error loading resources. Please try again later.
        </p>
      ) : (
        <>
          {items.items?.length ? (
            <div className={styles["up-grid"]}>
              {items.items.map((i) => (
                <div key={i.id} className={styles["up-grid-item"]}>
                  <div className={styles["up-grid-item-title"]}>
                    {moment(i.date)
                      .tz(currentUser!.timezone.value)
                      .format("MMM D, YYYY, hh:mm A")}
                  </div>
                  <img
                    src={getImagesUrl() + i.mediumSrc}
                    onClick={() => setOpenedImageSrc(i)}
                    alt={"resource"}
                    loading="lazy"
                  />
                  <GeoStatusIcons
                    geoStatus={i.geoStatus}
                    timeStatus={i.timeStatus}
                    geoLimit={i.geoLimit}
                    timeLimit={i.timeLimit}
                  />
                </div>
              ))}
            </div>
          ) : (
            <p className={styles["up-grid-no-items"]}>No resources yet.</p>
          )}
        </>
      )}
      <Dialog
        open={openedImageSrc !== null}
        onClose={() => {
          setOpenedImageSrc(null);
        }}
      >
        {openedImageSrc && (
          <div className={styles["up-modal"]}>
            {canAdd && visitId && (
              <IconButton
                onClick={() => deleteResource(openedImageSrc.id)}
                className={styles["up-modal-delete"]}
              >
                <img src={DeleteIcon} alt="Delete" />
              </IconButton>
            )}
            <img
              className={styles["up-modal-img"]}
              src={getImagesUrl() + openedImageSrc.src || ""}
              alt="resource"
            />
          </div>
        )}
      </Dialog>

      {scrollData && (
        <SwipeableDrawer
          open={opened}
          onClose={() => setOpened(false)}
          onOpen={() => setOpened(true)}
          anchor="bottom"
          swipeAreaWidth={44}
          disableSwipeToOpen={true}
          classes={{ paper: styles["up-drawer"] }}
        >
          <div className={styles["up-puller"]} />
          <div className={styles["up-swipeable"]}>
            <div className={styles["up-swipeable-grid"]} id="resources-scrollable">
              <InfiniteScroll
                style={{
                  overflow: "hidden",
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                }}
                dataLength={scrollData.items.length}
                next={() => {
                  scrollData.setPageNumber(scrollData.pageNumber + 1);
                }}
                hasMore={scrollData.hasMore}
                scrollableTarget={"resources-scrollable"}
                loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
              >
                {scrollData.items.map((i) => (
                  <div key={i.id} className={styles["up-swipeable-item"]}>
                    <div className={styles["up-grid-item-title"]}>
                      {moment(i.date)
                        .tz(currentUser!.timezone.value)
                        .format("MMM D, YYYY, hh:mm A")}
                    </div>
                    <img
                      src={getImagesUrl() + i.mediumSrc}
                      onClick={() => setOpenedImageSrc(i)}
                      alt={"resource"}
                      loading="lazy"
                    />

                    <GeoStatusIcons
                      geoStatus={i.geoStatus}
                      timeStatus={i.timeStatus}
                      geoLimit={i.geoLimit}
                      timeLimit={i.timeLimit}
                    />
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </SwipeableDrawer>
      )}
    </div>
  );
}

export default ViewResources;
