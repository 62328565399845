import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import UpTabs from "components/Shared/Tabs/Tabs";
import { useContext, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";
import { CountriesStatesContext, UserDataContext } from "shared/Contexts";
import { PermissionsList, TabsStatuses } from "shared/Enums";
import {
  formatAddressToString,
  isUserHavePermissions,
} from "shared/helpers/common.helper";

import AddIcon from "@mui/icons-material/Add";
import { Fab, Link } from "@mui/material";

import styles from "./TasksView.module.scss";
import { useTasksInfo } from "./hooks/useTasksInfo";
import { checkDate12AMto00 } from "shared/helpers/time.helper";
import moment from "moment";

function TasksView() {
  const { countriesStates } = useContext(CountriesStatesContext);
  const { currentUser } = useContext(UserDataContext);

  const navigate = useNavigate();
  const params = useParams();

  const {
    getContent,
    isLoading,
    tasksContent,
    tabsContent,
    locationInfo,
    hasMore,
    pageNumber,
    setPageNumber,
    tabStatus,
    setTabStatus,
  } = useTasksInfo(currentUser!, params.locationId);

  const changeTab = (type: TabsStatuses) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("tab", type);
    navigate(`${window.location.pathname}?${queryParams.toString()}`);
    setTabStatus(type);
    setPageNumber(0);
  };

  useEffect(() => {
    getContent();
  }, [params.locationId]);

  return (
    <div className={styles["up-container"]}>
      <Header
        title={"Tasks"}
        toggleBack={() => navigate(-1)}
        toggleReload={() => getContent()}
      />
      <h2 className={styles["up-title"]}>Tasks</h2>

      {locationInfo && (
        <>
          <div className={styles["up-location-name"]}>{locationInfo.title}</div>
          <div className={styles["up-location-address"]}>
            {formatAddressToString(locationInfo.address, countriesStates)}
          </div>
        </>
      )}
      <LoadingSpinner isLoading={isLoading}>
        <>
          {tasksContent && tabsContent && (
            <UpTabs
              content={tasksContent}
              tabs={tabsContent}
              changeTab={changeTab}
              selectedTab={tabStatus}
            >
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={tasksContent.length}
                next={() => setPageNumber(pageNumber + 1)}
                hasMore={hasMore}
                loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
              >
                {tasksContent.length ? (
                  tasksContent.map((item, index) => (
                    <div
                      key={index}
                      className={styles["up-card"]}
                      onClick={() => navigate(`/tasks/${item.id}`)}
                    >
                      {(item.location || item.deadline || item.completionDate) && (
                        <div className={styles["up-card-header"]}>
                          {item.location && !locationInfo && (
                            <Link
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                navigate(`/tasks/location/${item.location.id}`);
                              }}
                              className={styles["up-card-location"]}
                            >
                              {item.location.title}
                            </Link>
                          )}
                          {tabStatus === TabsStatuses.active ? (
                            <span className={styles["up-card-date"]}>
                              {item.deadline && (
                                <>Deadline: {moment(item.deadline).format("ll")}</>
                              )}
                            </span>
                          ) : (
                            <span className={styles["up-card-date"]}>
                              {item.completionDate && (
                                <>
                                  Completed:{" "}
                                  {checkDate12AMto00(
                                    item.completionDate,
                                    currentUser!.timezone.value
                                  )}
                                </>
                              )}
                            </span>
                          )}
                        </div>
                      )}
                      {item.title && (
                        <span className={styles["up-card-title"]}>{item.title}</span>
                      )}
                      {item.description && (
                        <span className={styles["up-card-description"]}>
                          {item.description}
                        </span>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="up-no-items">No task records found</div>
                )}
              </InfiniteScroll>
            </UpTabs>
          )}
        </>
      </LoadingSpinner>
      {isUserHavePermissions(currentUser!, [PermissionsList.createTask]) && (
        <Fab
          aria-label={"add task"}
          color={"inherit"}
          classes={{ root: styles["up-fab-root"] }}
          onClick={() =>
            params.locationId
              ? navigate(`/tasks/create/${params.locationId}`)
              : navigate(`/tasks/create`)
          }
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
}

export default TasksView;
