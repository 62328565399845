import { useEffect, useState } from "react";
import { TabsStatuses } from "shared/Enums";

import { TabsPropsI } from "@interfaces";
import { Tab, Tabs } from "@mui/material";

import ActiveIcon from "../../../assets/icons/visit-states/visit-state-active.svg";
import CompletedIcon from "../../../assets/icons/visit-states/visit-state-completed.svg";
import SkippedIcon from "../../../assets/icons/visit-states/visit-state-skipped.svg";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import styles from "./Tabs.module.scss";

function UpTabs({ tabs, content = [], changeTab, children, selectedTab }: TabsPropsI) {
  const [tabVal, setTabVal] = useState<TabsStatuses>(
    TabsStatuses[selectedTab || (Object.keys(tabs)[0] as TabsStatuses)]
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [content]);

  return (
    <>
      <Tabs
        classes={{
          root: styles["up-tabs"],
          flexContainer:
            tabs.active && tabs.skipped
              ? styles["up-tabs-three-columns"]
              : styles["up-tabs-one-two-columns"],
          indicator: styles["up-tabs-indicator"],
        }}
        onChange={(_, value: any) => {
          setTabVal(value);
          setIsLoading(true);
          changeTab(value);
        }}
        value={tabVal}
      >
        {tabs.active && (
          <Tab
            classes={{
              root: styles["up-tab"],
              selected: styles["up-tab-active"],
            }}
            value={TabsStatuses.active}
            icon={
              <div className={styles["up-tab-content"]}>
                <div className={`${styles["up-tab-content-icon"]} ${styles.active}`}>
                  <img alt="icon" src={ActiveIcon} />
                </div>
                <div className={styles["up-tab-content-count"]}>{tabs.active.count}</div>
                <div className={styles["up-tab-content-title"]}>{tabs.active.title}</div>
              </div>
            }
          ></Tab>
        )}
        <Tab
          classes={{
            root: styles["up-tab"],
            selected: styles["up-tab-active"],
          }}
          value={TabsStatuses.completed}
          icon={
            <div className={styles["up-tab-content"]}>
              <div className={`${styles["up-tab-content-icon"]} ${styles.completed}`}>
                <img alt="icon" src={CompletedIcon} />
              </div>
              <div className={styles["up-tab-content-count"]}>{tabs.completed.count}</div>
              <div className={styles["up-tab-content-title"]}>{tabs.completed.title}</div>
            </div>
          }
        ></Tab>
        {tabs.skipped && (
          <Tab
            classes={{
              root: styles["up-tab"],
              selected: styles["up-tab-active"],
            }}
            value={TabsStatuses.skipped}
            icon={
              <div className={styles["up-tab-content"]}>
                <div className={`${styles["up-tab-content-icon"]} ${styles.skipped}`}>
                  <img alt="icon" src={SkippedIcon} />
                </div>
                <div className={styles["up-tab-content-count"]}>{tabs.skipped.count}</div>
                <div className={styles["up-tab-content-title"]}>{tabs.skipped.title}</div>
              </div>
            }
          ></Tab>
        )}
      </Tabs>

      <LoadingSpinner isLoading={isLoading}>{children}</LoadingSpinner>
    </>
  );
}

export default UpTabs;
