import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { checkDate12AMto00 } from "shared/helpers/time.helper";

import { NotesListI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import { Chip, IconButton, Link } from "@mui/material";

import EditIcon from "../../../../assets/icons/edit.svg";
import styles from "./NotesCards.module.scss";

function NotesCards({
  content,
  ids,
  showTitle = false,
}: {
  content: NotesListI;
  ids: {
    locationId?: string;
    taskId?: string;
    visitId?: string;
  };
  showTitle?: boolean;
}) {
  const { currentUser } = useContext(UserDataContext);
  const navigate = useNavigate();

  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-header"]}>
        {showTitle ? (
          <>
            <h1 className={styles["up-header-title"]}>Notes</h1>
            <Chip
              label={content.count}
              classes={{
                root: styles["up-header-chip"],
                label: styles["up-header-chip-label"],
              }}
            />
          </>
        ) : (
          <div style={{ marginRight: "auto" }} />
        )}

        {content.items.length > 0 && (
          <Link
            onClick={() =>
              ids?.locationId
                ? navigate(`/notes/location/${ids.locationId}`)
                : navigate(`/notes/`)
            }
            underline="none"
            classes={{ root: styles["up-header-link"] }}
          >
            All Notes
          </Link>
        )}

        {isUserHavePermissions(currentUser!, [PermissionsList.createNote]) && (
          <IconButton
            color="inherit"
            classes={{ root: styles["up-header-add"] }}
            onClick={() =>
              ids.visitId
                ? navigate(`/notes/create/visits/${ids.visitId}`)
                : ids.taskId
                  ? navigate(`/notes/create/tasks/${ids.taskId}`)
                  : ids.locationId
                    ? navigate(`/notes/create/locations/${ids.locationId}`)
                    : navigate("/notes/create")
            }
          >
            <AddIcon />
          </IconButton>
        )}
      </div>
      {content.items.length ? (
        <div className={styles["up-grid"]}>
          {content.items.map((i) => (
            <div className={styles["up-grid-item"]} key={i.id}>
              <div className={styles["date"]}>
                {checkDate12AMto00(i.date, currentUser!.timezone.value)}
              </div>
              <div className={styles["description"]}>
                <p className={`${styles["content"]} up-truncate-6`}>{i.content}</p>
                <div className={styles["author"]}>
                  <span>{i.author}</span>
                  <IconButton
                    classes={{ root: styles["edit"] }}
                    onClick={() => navigate(`/notes/${i.id}`)}
                  >
                    <img src={EditIcon} alt="edit" />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className={styles["up-grid-no-items"]}>
          No notes yet. Be the first one to add a note.
        </p>
      )}
    </div>
  );
}

export default NotesCards;
