import axios, { AxiosResponse } from "axios";
import { Moment } from "moment";
import {
  AppointmentsType,
  DayScheduleActionsNew,
  DayScheduleStepsStatusesNew,
  DayScheduleStepsTypeNew,
} from "shared/Enums";
import {
  check12AMto00,
  formatNumberToString,
  formatTimeStringToNumber,
} from "shared/helpers/time.helper";

import {
  DaySchedulePageNewDTO,
  LatLngI,
  ResponseDto,
  StartLocationFormI,
  TabsI,
  VisitBreaksNewI,
  VisitCardsNewI,
} from "@interfaces";

import { apiEditProfileAgent, apiGetAgentProfile } from "./Api/Profile.api.service";
import {
  apiCreateStartLocation,
  apiEditDay,
  apiEditStep,
  apiGetAgentRouting,
  apiGetDay,
  apiGetLastDayByDate,
  apiGetLocationEndTime,
  apiGetLocationsAdresses,
  apiGetLocationStartTime,
  apiGetRouting,
  apiSetLocationEndTime,
  apiSetLocationStartTime,
} from "./Api/Router.api.service";

const moment = require("moment-timezone");

function isCardError(
  object: { error: any } | DaySchedulePageNewDTO
): object is { error: any } {
  return "error" in object;
}

function parseRouting(
  date: Moment,
  calculateData: AxiosResponse<DaySchedulePageNewDTO | { error: any }>
): Promise<any> | { error: any } {
  if (isCardError(calculateData.data)) {
    return {
      error:
        typeof calculateData.data.error === "string"
          ? calculateData.data.error
          : calculateData.data.error.length
            ? calculateData.data.error[0]
            : calculateData.data.error.message,
    };
  } else {
    return apiGetDay(calculateData.data.day.uuid).then((day) => {
      const locations = day.data.included?.filter(
        (i: ResponseDto<any>) => i.type === "ups_location--ups_location"
      );
      const priorities2 = day.data.included?.filter(
        (i: ResponseDto<any>) => i.type === "ups_priority--ups_priority"
      );
      // const locationsIds =
      //   day.data.included
      //     ?.filter((i: ResponseDto<any>) => i.type === "ups_location--ups_location")
      //     ?.map((i: { id: any }) => i.id) || [];
      const calcData = calculateData.data as DaySchedulePageNewDTO;
      const stepsFromRoutingApiByUuid = new Map();
      calcData.steps?.forEach((routeStep) => {
        stepsFromRoutingApiByUuid.set(routeStep.uuid, routeStep);
      });

      if (locations.length) {
        const stepsFromDay = day.data.included?.filter(
          (i: ResponseDto<any>) =>
            i.type === "ups_timeslot--appointment" ||
            i.type === "ups_timeslot--visit" ||
            i.type === "ups_timeslot--start_time" ||
            i.type === "ups_timeslot--time_reservation" ||
            i.type === "ups_timeslot--end_time"
        );

        const visitsList = day.data.included?.filter(
          (i: ResponseDto<any>) => i.type === "ups_visit--ups_visit"
        );
        const steps =
          stepsFromDay?.map((step: any) => {
            switch (step.attributes.step_type as DayScheduleStepsTypeNew) {
              case DayScheduleStepsTypeNew.start:
                return {
                  cardType: step.attributes.step_type,
                  time: day.data.data.attributes.time_start
                    ? formatNumberToString(day.data.data.attributes.time_start)
                    : check12AMto00(step.attributes.date),
                  address: day.data.data.attributes.address_start,
                  sequence: step.attributes.sequence,
                  geolocation: day.data.data.attributes.geolocation_start,
                } as VisitBreaksNewI;
              case DayScheduleStepsTypeNew.end:
                return {
                  cardType: step.attributes.step_type,
                  time: day.data.data.attributes.time_end
                    ? formatNumberToString(day.data.data.attributes.time_end)
                    : check12AMto00(step.attributes.date),
                  address: day.data.data.attributes.address_end,
                  sequence: step.attributes.sequence,
                  geolocation: day.data.data.attributes.geolocation_end,
                } as VisitBreaksNewI;
              case DayScheduleStepsTypeNew.break:
                return {
                  cardType: DayScheduleStepsTypeNew.break,
                  time: step.attributes.date,
                  timeTo: undefined,
                  sequence: step.attributes.sequence,
                } as VisitBreaksNewI;

              default: {
                const location = day.data.included?.find(
                  (i: ResponseDto<any>) =>
                    i.type === "ups_location--ups_location" &&
                    i.id === step.relationships.location?.data?.id
                );
                const priority = priorities2.find(
                  (i: { id: any }) => i.id === location?.relationships.priority?.data?.id
                );
                return {
                  cardType: DayScheduleStepsTypeNew.service,
                  status: step.attributes.step_status,
                  id: step.id,
                  sequence: step.attributes.sequence,
                  arrival: stepsFromRoutingApiByUuid.get(step.id).arrival,
                  isVirtual: step.attributes.is_virtual,
                  appointment: step.relationships.appointment.data,
                  visitId: visitsList?.find(
                    (i: { relationships: any }) =>
                      i?.relationships.location?.data?.id === location!.id
                  )?.id,
                  isLocationOverdue: stepsFromRoutingApiByUuid.get(step.id)
                    .is_location_overdue,
                  location: {
                    id: location?.id,
                    address: location?.attributes.address,
                    title: location?.attributes.label,
                    phoneNumbers: location?.attributes.telephones || [],
                    priority: priority?.attributes.priority,
                  },
                  geolocation: step.attributes.geolocation,
                } as VisitCardsNewI;
              }
            }
          }) || [];

        return {
          day: {
            id: calcData.day.uuid,
            status: calcData.day.day_status,
            date: date,
          },
          tabs: {
            active: {
              title: "Active",
              count:
                steps?.filter(
                  (i: VisitCardsNewI | VisitBreaksNewI) =>
                    i.cardType === DayScheduleStepsTypeNew.service &&
                    (i.status === DayScheduleStepsStatusesNew.inProgress ||
                      i.status === DayScheduleStepsStatusesNew.missed ||
                      i.status === DayScheduleStepsStatusesNew.pending)
                ).length || 0,
            },
            completed: {
              title: "Completed",
              count:
                steps?.filter(
                  (i: VisitCardsNewI | VisitBreaksNewI) =>
                    i.cardType === DayScheduleStepsTypeNew.service &&
                    i.status === DayScheduleStepsStatusesNew.completed
                ).length || 0,
            },
            skipped: {
              title: "Skipped",
              count:
                steps?.filter(
                  (i: VisitCardsNewI | VisitBreaksNewI) =>
                    i.cardType === DayScheduleStepsTypeNew.service &&
                    i.status === DayScheduleStepsStatusesNew.skipped
                ).length || 0,
            },
          } as TabsI,
          steps: {
            active: (
              steps.filter(
                (i: VisitCardsNewI | VisitBreaksNewI) =>
                  i.cardType !== DayScheduleStepsTypeNew.service ||
                  i.status === DayScheduleStepsStatusesNew.missed ||
                  i.status === DayScheduleStepsStatusesNew.pending ||
                  i.status === DayScheduleStepsStatusesNew.inProgress
              ) || []
            ).sort(
              (
                a: VisitCardsNewI | VisitBreaksNewI,
                b: VisitCardsNewI | VisitBreaksNewI
              ) => (a.sequence > b.sequence ? 1 : -1)
            ),
            completed: (
              steps.filter(
                (i: VisitCardsNewI | VisitBreaksNewI) =>
                  i.cardType === DayScheduleStepsTypeNew.service &&
                  i.status === DayScheduleStepsStatusesNew.completed
              ) || []
            ).sort(
              (
                a: VisitCardsNewI | VisitBreaksNewI,
                b: VisitCardsNewI | VisitBreaksNewI
              ) => (a.sequence > b.sequence ? 1 : -1)
            ),
            skipped: (
              steps.filter(
                (i: VisitCardsNewI | VisitBreaksNewI) =>
                  i.cardType === DayScheduleStepsTypeNew.service &&
                  i.status === DayScheduleStepsStatusesNew.skipped
              ) || []
            ).sort(
              (
                a: VisitCardsNewI | VisitBreaksNewI,
                b: VisitCardsNewI | VisitBreaksNewI
              ) => (a.sequence > b.sequence ? 1 : -1)
            ),
          },
        };
      } else {
        return {
          day: {
            id: calcData.day.uuid,
            status: calcData.day.day_status,
            date: date,
          },
          tabs: {
            active: {
              title: "Active",
              count: 0,
            },
            completed: {
              title: "Completed",
              count: 0,
            },
            skipped: {
              title: "Skipped",
              count: 0,
            },
          } as TabsI,
          steps: {
            active: [],
            completed: [],
            skipped: [],
          },
        };
      }
    });
  }
}

export const getRouting = (date: Moment, location: LatLngI): Promise<any> => {
  return apiGetRouting({
    date: moment(date).format("YYYY-MM-DD"),
    current_lat: location.lat,
    current_lng: location.lng,
  })
    .then((calculateData: AxiosResponse<DaySchedulePageNewDTO | { error: any }>) => {
      return parseRouting(date, calculateData);
    })
    .catch((err) => err);
};

export const getAgentRouting = (date: Moment, agentUuid: string): Promise<any> => {
  return apiGetAgentRouting({
    date: moment(date).format("YYYY-MM-DD"),
    uuid: agentUuid,
  })
    .then((calculateData: AxiosResponse<DaySchedulePageNewDTO | { error: any }>) => {
      return parseRouting(date, calculateData);
    })
    .catch((err) => err);
};

export const editDayStatus = (
  id: string,
  attributes: {
    day_status?: string;
    actual_time_start?: string;
    actual_time_end?: string;
  }
): Promise<any> => {
  return apiEditDay(id, {
    data: {
      type: "ups_day--ups_day",
      id: id,
      attributes,
    },
  });
};

export const editStepStatus = (
  id: string,
  body: DayScheduleActionsNew,
  isVirtual?: AppointmentsType
): Promise<any> => {
  let action = "";
  switch (body) {
    case DayScheduleActionsNew.skipVisit:
      action = "skipped";
      break;
    case DayScheduleActionsNew.unskipVisit:
      action = "pending";
      break;
    case DayScheduleActionsNew.completeVisit:
      action = "completed";
      break;
  }
  const isVirtualObj = isVirtual
    ? {
        is_virtual: isVirtual === AppointmentsType.call,
      }
    : {};
  return apiEditStep(id, {
    data: {
      type: "ups_timeslot--visit",
      id: id,
      attributes: {
        step_status: action,
        ...isVirtualObj,
      },
    },
  }).catch((err) => err);
};

export const GetLastDayByDate = (date: Moment, agentUuid?: string): Promise<any> => {
  return apiGetLastDayByDate(date.format("YYYY-MM-DD"), agentUuid)
    .then((day) => {
      const stepsFromDay = day.data.included?.filter(
        (i: ResponseDto<any>) => i.type === "ups_visit--ups_visit"
      );
      const steps =
        stepsFromDay?.map((step: any) => {
          const location = day.data.included?.find(
            (i: ResponseDto<any>) =>
              i.type === "ups_location--ups_location" &&
              i.id === step.relationships.location?.data?.id
          );
          return {
            cardType: DayScheduleStepsTypeNew.service,
            status: step.attributes.visit_status,
            id: step.id,
            sequence: step.attributes.sequence,
            arrival: step.attributes.date,
            isVirtual: step.attributes.is_virtual,
            location: {
              id: location?.id,
              address: location?.attributes.address,
              title: location?.attributes.label,
              phoneNumbers: location?.attributes.telephones || [],
            },
            geolocation: step.attributes.geolocation,
          } as VisitCardsNewI;
        }) || [];
      if (day.data.data.length) {
        return {
          day: {
            id: day.data.data[0].id,
            status: "ended",
            date: date,
          },
          tabs: {
            completed: {
              title: "Completed",
              count:
                steps?.filter(
                  (i: VisitCardsNewI) =>
                    i.status === DayScheduleStepsStatusesNew.completed
                ).length || 0,
            },
            skipped: {
              title: "Skipped",
              count:
                steps?.filter(
                  (i: VisitCardsNewI) => i.status === DayScheduleStepsStatusesNew.skipped
                ).length || 0,
            },
          } as TabsI,
          steps: {
            completed: (
              steps.filter(
                (i: VisitCardsNewI) => i.status === DayScheduleStepsStatusesNew.completed
              ) || []
            ).sort((a: VisitCardsNewI, b: VisitCardsNewI) =>
              a.sequence > b.sequence ? 1 : -1
            ),
            skipped: (
              steps.filter(
                (i: VisitCardsNewI) => i.status === DayScheduleStepsStatusesNew.skipped
              ) || []
            ).sort((a: VisitCardsNewI, b: VisitCardsNewI) =>
              a.sequence > b.sequence ? 1 : -1
            ),
            passed:
              steps.filter(
                (i: VisitCardsNewI) =>
                  i.status === DayScheduleStepsStatusesNew.inProgress ||
                  i.status === DayScheduleStepsStatusesNew.pending
              ) || [],
          },
        };
      }
      return {
        day: {
          id: "",
          status: "ended",
          date: date,
        },
        tabs: {
          completed: {
            title: "Completed",
            count: 0,
          },
          skipped: {
            title: "Skipped",
            count: 0,
          },
        } as TabsI,
        steps: {
          completed: [],
          skipped: [],
        },
      };
    })
    .catch((err) => err);
};

export const createStartLocation = (
  id: string,
  body: StartLocationFormI,
  type: "start" | "end",
  time: string
) => {
  return apiCreateStartLocation({
    data: {
      type: "ups_agent_location--ups_agent_location",
      attributes: {
        address: body.address,
        geolocation: body.geolocation,
      },
    },
  })
    .then((response) => {
      return attachGeolocationToAgentProfile(id, response.data.data.id, type, time);
    })
    .catch((err) => err);
};

export const getLocationsAdresses = (id: string) => {
  return apiGetLocationsAdresses(id)
    .then((response) => {
      return response.data.data.map((item: any) => {
        return {
          id: item.id,
          address: item.attributes.address,
          geolocation: item.attributes.geolocation,
        };
      });
    })
    .catch((err) => err);
};
export const getLocationTime = (type: "start" | "end") => {
  if (type === "start") {
    return apiGetLocationStartTime(moment().format("YYYY-MM-DD"))
      .then((i) => i.data.time_start)
      .catch((err) => err);
  } else {
    return apiGetLocationEndTime(moment().format("YYYY-MM-DD"))
      .then((i) => i.data.time_end)
      .catch((err) => err);
  }
};

export const attachGeolocationToAgentProfile = (
  userId: string,
  geoId: string,
  type: "start" | "end",
  time: string
): Promise<any> => {
  return apiGetAgentProfile(userId)
    .then((response) => {
      const agentId = response.data.included?.find(
        (i: ResponseDto<any>) => i.type === "profile--agent"
      )?.id;
      const data =
        type === "start"
          ? {
              field_agent_location: {
                data: {
                  id: geoId,
                  type: "ups_agent_location--ups_agent_location",
                },
              },
            }
          : {
              field_agent_location_end: {
                data: {
                  id: geoId,
                  type: "ups_agent_location--ups_agent_location",
                },
              },
            };
      const timeEndpoint =
        type === "start"
          ? apiSetLocationStartTime(
              moment().format("YYYY-MM-DD"),
              formatTimeStringToNumber(time)
            )
          : apiSetLocationEndTime(
              moment().format("YYYY-MM-DD"),
              formatTimeStringToNumber(time)
            );
      return axios
        .all([
          apiEditProfileAgent(agentId, {
            data: {
              type: "profile--agent",
              id: agentId,
              relationships: {
                ...data,
              },
            },
          }),

          timeEndpoint,
        ])
        .catch((err) => err);
    })
    .catch((err) => err);
};
