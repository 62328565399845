import axios, { AxiosError } from "axios";
import CompaniesForm from "components/Shared/Companies/CompaniesForm/CompaniesForm";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { checkPermissionsForEdit } from "shared/helpers/common.helper";
import { deleteCompany, editCompanyData } from "shared/Services";

import { CompanyFormI } from "@interfaces";

import styles from "./CompanyEdit.module.scss";
import { toast } from "react-toastify";
import { useCompanyData } from "../hooks/useCompanyData";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { Footer } from "components/Shared/Footer/Footer";

function CompanyEdit() {
  const { currentUser } = useContext(UserDataContext);
  const params = useParams();

  const [submitted, setSubmitted] = useState(0);
  const navigate = useNavigate();

  const { companyInfo, isLoading } = useCompanyData(params.companyId);

  const FormSubmit = (value: CompanyFormI) => {
    editCompanyData(params.companyId!, value).then((data: AxiosError | any) => {
      if (axios.isAxiosError(data)) {
        toast.error(
          data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        toast.success("Successfully completed!");
        navigate(`/companies/${params.companyId}`);
      }
    });
  };

  const ToggleDelete = () => {
    deleteCompany(params.companyId!).then((data: AxiosError | any) => {
      if (axios.isAxiosError(data)) {
        toast.error(
          data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        toast.success("succefully deleted!");
        navigate("/");
      }
    });
  };

  return (
    <>
      <div className={styles["up-container"]}>
        <FormsHeader
          viewDeleteButton={checkPermissionsForEdit(
            currentUser!,
            PermissionsList.deleteAnyCompany,
            PermissionsList.deleteOwnCompany,
            companyInfo?.authorId || ""
          )}
          deleteToggle={() => ToggleDelete()}
          title="Edit Company"
          onRightBtnToggle={() => setSubmitted(submitted + 1)}
          onCancelToggle={() => navigate(-1)}
        />
        <LoadingSpinner isLoading={isLoading} height={300}>
          {companyInfo && (
            <CompaniesForm
              submitted={submitted}
              formFinished={FormSubmit}
              formValue={companyInfo}
            ></CompaniesForm>
          )}
        </LoadingSpinner>
      </div>
      <Footer />
    </>
  );
}

export default CompanyEdit;
