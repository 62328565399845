import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { TaskPriority } from "shared/Enums";

import { TaskCardI } from "@interfaces";
import TickIcon from "../../../../assets/icons/tick.svg";
import styles from "./TaskCard.module.scss";
import { checkDate12AMto00 } from "shared/helpers/time.helper";
import moment from "moment";

function TaskCard({ card }: { card: TaskCardI }) {
  const { currentUser } = useContext(UserDataContext);
  const navigate = useNavigate();
  return (
    <div
      className={styles["up-grid-item"]}
      key={card.id}
      onClick={() => navigate(`/tasks/${card.id}`)}
    >
      <div
        className={`${styles["up-grid-item--header"]} ${
          moment(card.deadline).isBefore(moment()) ? styles.missed : ""
        }`}
      >
        {card.priority && (
          <>
            <span className={card.priority === "high" ? styles.high : ""}>
              {TaskPriority[card.priority as "regular" | "high"]}
            </span>
          </>
        )}
        {card.deadline && <>,Deadline - {moment(card.deadline).format("ll")} </>}
      </div>
      <div className={styles["description"]}>
        <p className={styles["title"]}>{card.title}</p>
        <p className={`${styles["content"]} up-truncate-6`}>{card.description}</p>

        {card.completionDate && (
          <div className={styles["status"]}>
            <img src={TickIcon} alt="tick" />
            Completed <br />
            {checkDate12AMto00(card.completionDate, currentUser!.timezone.value)}
          </div>
        )}
      </div>
    </div>
  );
}

export default TaskCard;
