import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";

import PageNotFound from "../../PageNotFound/PageNotFound";
import ContactCreate from "./ContactCreate/ContactCreate";
import ContactEdit from "./ContactsEdit/ContactEdit";
import ContactView from "./ContactView/ContactView";
import { ErrorBoundary } from "error";
import { Footer } from "components/Shared/Footer/Footer";

function ContactsRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <>
      <Routes>
        {isUserHavePermissions(currentUser!, [
          PermissionsList.viewAnyContact,
          PermissionsList.viewOwnContact,
        ]) && (
          <Route
            path="/:contactId"
            element={
              <ErrorBoundary>
                <ContactView />
              </ErrorBoundary>
            }
          />
        )}

        {isUserHavePermissions(currentUser!, [PermissionsList.createContact]) && (
          <Route
            path="/create"
            element={
              <ErrorBoundary>
                <ContactCreate />
              </ErrorBoundary>
            }
          />
        )}

        {isUserHavePermissions(currentUser!, [
          PermissionsList.editAnyContact,
          PermissionsList.editOwnContact,
        ]) && (
          <Route
            path="/:contactId/edit"
            element={
              <ErrorBoundary>
                <ContactEdit />
              </ErrorBoundary>
            }
          />
        )}

        <Route path={`*`} element={<PageNotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default ContactsRouter;
