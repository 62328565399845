import { AxiosResponse } from "axios";

import { jsonApiFile, jsonAuthApi } from "./Settings.api.service";

export const apiGetContactData = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_contact/ups_contact/${id}`, {
    params: {
      include: "photo,location,company",
    },
  });
};

export const apiEditContactData = (
  id: string,
  body: any
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.patch(`/ups_contact/ups_contact/${id}`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiUploadContactLogo = (
  id: string,
  body: any
): Promise<AxiosResponse<any, any>> => {
  return jsonApiFile.post(`/ups_contact/ups_contact/${id}/photo`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiCreateContact = (body: any): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.post(`/ups_contact/ups_contact/`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiDeleteContact = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.delete(`/ups_contact/ups_contact/${id}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
