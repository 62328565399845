import axios from "axios";
import moment from "moment-timezone";
import RouteView from "components/Pages/Route/Route";
import GMapView from "components/Shared/gMap/gMap";
import LoadingSplash from "components/Shared/LoadingSplash/LoadingSplash";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CountriesStatesContext,
  HeaderContext,
  RouterContext,
  UserDataContext,
  ManagerContext,
} from "shared/Contexts";
import { CountriesStatesContextI } from "shared/Contexts/CountriesStatesContext";
import { HeaderContextI } from "shared/Contexts/HeaderContext";
import { RouterContextI } from "shared/Contexts/RouterContext";
import { ManagerContextI } from "shared/Contexts/ManagerContext";
import { getCountriesAndStates, getMissedAppointment } from "shared/Services";

import { Container, createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { useJsApiLoader } from "@react-google-maps/api";

import AddSidenav from "./Sidenav/AddSidenav";
import Sidenav from "./Sidenav/Sidenav";
import { toast } from "react-toastify";

import styles from "./Layout.module.scss";
import { TabsStatuses } from "shared/Enums";
import { MuteNotifications } from "./MuteNotifications/MuteNotifications";
import { getMissedVisits } from "shared/Services/Common.service";

const libraries = process.env.REACT_APP_GOOGLE_LIBRARY?.split(",") || [];

function Layout({ children }: { children: JSX.Element | JSX.Element[] }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser, setCurrentUser } = useContext(UserDataContext);
  const [managerContext, setManagerContext] = useState<ManagerContextI>({
    agent: null,
  });
  const [headerContext, setHeaderContext] = useState<HeaderContextI>({
    opened: false,
    addOpened: false,
  });

  const [routerValue, setRouterValue] = useState<RouterContextI>({
    opened: false,
    loaded: false,
    gMapOpened: false,
    selectedTab: TabsStatuses.active,
    selectedDate: moment().tz(currentUser!.timezone.value),
  });
  const [countriesStates, setCountriesStates] = useState<CountriesStatesContextI>({});
  const [isLoading, setIsLoading] = useState(true);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAUEpOSpLuffk8S1IBVfn8k77FYXBYjpSg",
    mapIds: ["11a7b148a03577a0"],
    libraries: libraries as ["places"],
  });

  useEffect(() => {
    setRouterValue((prev) => ({ ...prev, loaded: isLoaded }));
  }, [isLoaded]);

  useEffect(() => {
    setRouterValue((prev) => ({ ...prev, opened: false, gMapOpened: false }));

    const date = moment().utc().tz(currentUser!.timezone.value);

    // If the user has muted notifications, then the notification will not be shown.
    if (
      currentUser!.mute_notifications_until &&
      date.isBefore(
        moment.utc(currentUser!.mute_notifications_until).tz(currentUser!.timezone.value)
      )
    ) {
      setCurrentUser((prev) => ({ ...prev!, notificationsMuted: true }));
      return;
    }

    setCurrentUser((prev) => ({ ...prev!, notificationsMuted: false }));
    // Gets missed appointments before current day and generates notification message.
    axios
      .all([
        getMissedAppointment(date.format("YYYY-MM-DD")),
        getMissedVisits(
          date.format("YYYY-MM-DD"),
          managerContext.agent ? managerContext.agent.uuid : currentUser!.uid
        ),
      ])
      .then((resp) => {
        if (axios.isAxiosError(resp)) {
          toast.error(resp?.response?.data?.message);
        } else {
          const appointmentsNode: ReactNode[] = [];
          const appointments = Object.entries(resp[0].data)?.sort((a: any, b: any) =>
            moment(a[1].date).isBefore(moment(b[1].date)) ? -1 : 1
          );
          for (const [id, value] of appointments) {
            const appointment = value as {
              id: number;
              description: string;
              date: string;
            };

            appointmentsNode.push(
              <li
                key={id}
                className={styles["up-link"]}
                onClick={() => navigate(`/visits/${id}/edit`)}
              >
                {appointment.description} on {moment(appointment.date).format("ll")}
              </li>
            );
          }

          const visits: ReactNode[] = [];
          resp[1]?.forEach((visit: any, index: number) => {
            visits.push(
              <li
                key={index}
                className={styles["up-link"]}
                onClick={() => {
                  setRouterValue((prev) => ({
                    ...prev,
                    selectedDate: moment(visit.date).tz(currentUser!.timezone.value),
                    selectedTab: TabsStatuses.completed,
                  }));
                  navigate(`/`);
                }}
              >
                on {moment(visit.date).tz(currentUser!.timezone.value).format("ll")}
              </li>
            );
          });
          if (
            (appointments.length > 0 || visits.length > 0) &&
            !toast.isActive("missed_appointments")
          ) {
            toast.info(
              <div>
                {appointments.length > 0 && (
                  <>
                    <div>You have missed appointments:</div>
                    <ul className={styles["up-appointment-group"]}>
                      {appointmentsNode.map((a) => a)}
                    </ul>
                  </>
                )}

                {visits.length > 0 && (
                  <>
                    <div>You have incomplete visits:</div>
                    <ul className={styles["up-appointment-group"]}>
                      {visits.map((a) => a)}
                    </ul>
                  </>
                )}

                <MuteNotifications />
              </div>,
              {
                toastId: "missed_appointments",
                autoClose: false,
              }
            );
          }
        }
      });
  }, [location, routerValue.selectedDate]);

  useEffect(() => {
    getCountriesAndStates().then((data) => {
      if (axios.isAxiosError(data)) {
        return;
      }
      setCountriesStates(data.data);
      setIsLoading(false);
    });
  }, []);

  const theme = createTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          InputLabelProps: { shrink: true },
        },
      },
      MuiInputLabel: {
        defaultProps: { shrink: true },
      },
      MuiOutlinedInput: {
        defaultProps: {
          notched: true,
        },
      },
    },
    typography: {
      fontFamily: [
        "Lato",
        "Oxygen",
        "Ubuntu",
        "Cantarel",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        "sans-serif",
      ].join(","),
    },
    palette: {
      primary: {
        main: "#039BE5",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <HeaderContext.Provider value={[headerContext, setHeaderContext]}>
        <CountriesStatesContext.Provider value={{ countriesStates }}>
          <RouterContext.Provider value={[routerValue, setRouterValue]}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ManagerContext.Provider value={[managerContext, setManagerContext]}>
                <LoadingSplash isLoading={isLoading}>
                  <Container maxWidth="md" classes={{ root: styles["up-container"] }}>
                    <Sidenav
                      opened={headerContext.opened}
                      onToggle={(condition) =>
                        setHeaderContext({
                          opened: condition,
                          addOpened: false,
                        })
                      }
                    />

                    <AddSidenav
                      opened={headerContext.addOpened}
                      onToggle={(condition) =>
                        setHeaderContext({
                          opened: false,
                          addOpened: condition,
                        })
                      }
                    />
                    {children}
                    {(currentUser!.agentProfiles || managerContext.agent) && isLoaded && (
                      <div className={!routerValue.opened ? "router-hidden" : ""}>
                        <RouteView />
                      </div>
                    )}
                    {isLoaded && (
                      <div className={!routerValue.gMapOpened ? "router-hidden" : ""}>
                        <GMapView />
                      </div>
                    )}
                  </Container>
                </LoadingSplash>
              </ManagerContext.Provider>
            </LocalizationProvider>
          </RouterContext.Provider>
        </CountriesStatesContext.Provider>
      </HeaderContext.Provider>
    </ThemeProvider>
  );
}

export default Layout;
