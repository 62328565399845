import axios from "axios";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { FormikErrors, FormikTouched, useFormik } from "formik";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getProductsList } from "shared/Services";
import * as Yup from "yup";

import { ProductsI } from "@interfaces";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import styles from "./ProductsField.module.scss";
import { toast } from "react-toastify";

function ProductsField({
  selectedProducts,
  toggleValue,
  errors,
  touched,
}: {
  selectedProducts?: ProductsI[];
  toggleValue: (e: string[]) => void;
  errors?: FormikErrors<string[] | string>;
  touched?: FormikTouched<boolean>;
}) {
  const [productsList, setProductsLists] = useState<ProductsI[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);

  const handleChange = (e: string) => {
    const array = formik.values.products;
    const index = array.indexOf(e);
    index !== -1 ? array.splice(index, 1) : array.push(e);

    formik.setFieldValue("products", array);
    toggleValue(array);
  };

  useEffect(() => {
    getProductsList(pageNumber * 5).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(
          data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        setHasMore(data.hasMore);
        setProductsLists([
          ...productsList,
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...data.content?.filter(
            (item1: ProductsI) =>
              !selectedProducts?.some((item2) => item1.id === item2.id)
          ),
        ]);
      }
    });
  }, [pageNumber]);

  const validationSchema = Yup.object().shape({
    products: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      products: selectedProducts?.map((i: { id: any }) => i.id) || [],
    },
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  return (
    <>
      <FormControl className={styles["up-form"]}>
        <InputLabel>Products</InputLabel>
        <Select
          classes={{ select: styles["up-form-input"] }}
          variant="outlined"
          fullWidth={true}
          id="products"
          name="products"
          value={formik.values.products}
          multiple
          displayEmpty
          error={touched && Boolean(errors)}
          label={"Products"}
        >
          {selectedProducts?.length
            ? selectedProducts.map((i) => (
                <MenuItem style={{ display: "none" }} value={i.id} key={i.id}>
                  {i.title}
                </MenuItem>
              ))
            : null}
          {productsList.map((i) => (
            <MenuItem style={{ display: "none" }} value={i.id} key={i.id}>
              {i.title}
            </MenuItem>
          ))}
          <div className={styles["up-menus"]}>
            <InfiniteScroll
              dataLength={productsList.length + (selectedProducts?.length || 0)}
              next={() => setPageNumber(pageNumber + 1)}
              hasMore={hasMore}
              height={200}
              loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
            >
              {selectedProducts?.map((i) => (
                <Button
                  value={i.id}
                  key={i.id}
                  className={`${styles["up-menu-item"]} ${
                    formik.values.products.includes(i.id) ? styles["active"] : ""
                  }`}
                  onClick={() => {
                    handleChange(i.id);
                  }}
                >
                  {i.title}
                </Button>
              ))}
              {productsList.map((i) => (
                <Button
                  value={i.id}
                  key={i.id}
                  className={`${styles["up-menu-item"]} ${
                    formik.values.products.includes(i.id) ? styles["active"] : ""
                  }`}
                  onClick={() => {
                    handleChange(i.id);
                  }}
                >
                  {i.title}
                </Button>
              ))}
            </InfiniteScroll>
          </div>
        </Select>
      </FormControl>
    </>
  );
}

export default ProductsField;
