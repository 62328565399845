import { VisitDetailsNewI } from "@interfaces";
import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList, AppointmentsType } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { getVisitData } from "shared/Services";

export const useVisitData = (visitId: string | undefined, formik: any) => {
  const { currentUser } = useContext(UserDataContext);

  const [visitInfo, setVisitInfo] = useState<VisitDetailsNewI>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<AxiosError<any>>();

  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    if (visitId) {
      try {
        const data = await getVisitData(visitId, {
          userIdForTask:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
            !isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceTask]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewOwnTask])
              ? currentUser!.uid
              : undefined,
          userIdForNotes:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
            !isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceNote]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewOwnNote])
              ? currentUser!.uid
              : undefined,
        });

        formik.setFieldValue(
          "isVirtual",
          data.isVirtual ? AppointmentsType.call : AppointmentsType.visit
        );
        setVisitInfo(data);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(
        error.response?.data?.errors?.length
          ? error.response?.data?.errors[0]?.detail
          : error.message
      );
      throw error;
    }
  }, [error]);

  return { visitInfo, setVisitInfo, isLoading, getContent };
};
