import AddressField from "components/Shared/Forms/AddressField/AddressField";
import GeolocationField from "components/Shared/Forms/GeolocationField/GeolocationField";
import UpTimePicker from "components/Shared/ScrollablePicker/ScrollablePicker";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import {
  formatMilisecondToTimeFormat,
  formatTimeFormatToMilisecond,
} from "shared/helpers/time.helper";
import { WorkingHoursMocked } from "shared/MockedData/Profile.mocked";
import { TimeOptionGroups } from "shared/MockedData/TimePicker.mocked";
import {
  addressValidation,
  linkValidation,
  phoneValidation,
} from "shared/Validation/Validation";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import {
  FileI,
  ProfileFormI,
  ProfileI,
  TimeFormatI,
  WorkingHoursItemI,
} from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
  TextField,
} from "@mui/material";

import DeleteIcon from "../../../../assets/icons/delete.svg";
import UpAvatar from "../Avatar/Avatar";
import WorkingHours from "../WorkingHours/WorkingHours";
import styles from "./ProfileForm.module.scss";

function ProfileForm({
  profileInfo,
  formFinished,
  submitted,
  isAgent,
}: {
  profileInfo: ProfileI;
  formFinished: (value: ProfileFormI) => void;
  submitted: number;
  isAgent: boolean;
}) {
  const [avatar, setAvatar] = useState<FileI | null>(null);
  const [workingHours, setWorkingHours] = useState<WorkingHoursItemI[]>([]);
  const [workingHoursDefault, setWorkingHoursDefault] = useState<WorkingHoursItemI[]>([]);
  const { currentUser } = useContext(UserDataContext);

  useEffect(() => {
    submitted && formik.handleSubmit();
  }, [submitted]);

  useEffect(() => {
    if (isAgent && !profileInfo.agentProfile) {
      setWorkingHoursDefault(WorkingHoursMocked);
      return;
    }
    setWorkingHoursDefault(
      profileInfo.agentProfile?.breaks
        ? [
            ...profileInfo.agentProfile.workingHours,
            {
              ...profileInfo.agentProfile?.breaks,
              name: "Break",
            },
          ]
        : []
    );
  }, []);

  const [timeTestValue, setTimeTestValue] = useState<TimeFormatI | null>(
    currentUser!.routerSettings?.testMode.time
      ? formatMilisecondToTimeFormat(currentUser!.routerSettings.testMode.time)
      : null
  );

  const testfields = isUserHavePermissions(currentUser!, [
    PermissionsList.administerTesting,
  ])
    ? ({
        testGeolocation: Yup.object().shape({
          lat: Yup.string(),
          lng: Yup.string(),
        }),
        isTest: Yup.boolean().required("This field is required"),
      } as ObjectShape)
    : ({} as ObjectShape);

  const validationSchema = isAgent
    ? Yup.object().shape({
        name: Yup.string().required("This field is required"),
        phones: Yup.array().of(phoneValidation).required("This field is required"),
        email: Yup.string().required("This field is required").email("Invalid email"),
        links: Yup.array().of(linkValidation),
        address: addressValidation,
        geolocation: Yup.object().shape({
          lat: Yup.string().required("This field is required"),
          lng: Yup.string().required("This field is required"),
        }),
        ...testfields,
      })
    : Yup.object().shape({
        name: Yup.string().required("This field is required"),
        phones: Yup.array()
          .of(Yup.string().required("This field is required"))
          .required("This field is required"),
        email: Yup.string().required("This field is required").email("Invalid email"),
      });

  const testfieldsValue = isUserHavePermissions(currentUser!, [
    PermissionsList.administerTesting,
  ])
    ? {
        testGeolocation: {
          lat: currentUser!.routerSettings?.testMode.points?.lat || "",
          lng: currentUser!.routerSettings?.testMode.points?.lng || "",
        },
        isTest: currentUser!.routerSettings?.testMode.isTest || false,
      }
    : {};

  const formik = useFormik({
    initialValues: isAgent
      ? ({
          name: profileInfo.name || "",
          phones: profileInfo.phoneNumber || [""],
          email: profileInfo.email ? profileInfo.email[0] : "",
          address: profileInfo.agentProfile?.address || {
            address_line: "",
            city: "",
            country_code: "",
            postal_code: "",
            state: "",
          },
          geolocation: {
            lat: profileInfo.agentProfile?.geolocation.lat || "",
            lng: profileInfo.agentProfile?.geolocation.lng || "",
          },
          links: profileInfo.agentProfile?.allLinks || [""],
          ...testfieldsValue,
        } as ProfileFormI)
      : ({
          name: profileInfo.name || "",
          phones: profileInfo.phoneNumber || [""],
          email: profileInfo.email ? profileInfo.email[0] : "",
        } as ProfileFormI),

    validationSchema: validationSchema,
    onSubmit: (values) => {
      formFinished({
        ...values,
        avatar: avatar,
        agentProfileId: profileInfo.agentProfile?.id,
        workingHours,
        isUserAgent: currentUser!.roles.includes("agent"),
        testTime: timeTestValue ? formatTimeFormatToMilisecond(timeTestValue) : undefined,
      });
    },
  });

  useEffect(() => {
    setAvatar({
      binaryData: profileInfo?.avatar?.url || null,
      arrayBuffer: null,
    });
  }, []);

  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-card"]}>
        <div className={styles["up-card-avatar"]}>
          <UpAvatar
            name={profileInfo?.name}
            avatar={avatar?.binaryData || null}
            editable={true}
            toggleImage={setAvatar}
            defaultImage={null}
          />
        </div>
      </div>
      <div className={styles["up-form"]}>
        <FormikProvider value={formik}>
          <TextField
            classes={{ root: styles["up-form-name"] }}
            label="name"
            variant="outlined"
            hiddenLabel={true}
            fullWidth={true}
            multiline
            InputProps={{
              classes: { root: styles["up-form-name-input"] },
            }}
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <FieldArray name="phones">
            {(formHelpers: any) => (
              <div className={styles["up-form-phones"]}>
                {formik.values.phones?.map((_, index) => (
                  <TextField
                    key={index}
                    classes={{ root: styles["up-form-phones-root"] }}
                    label={"Phone number"}
                    variant="outlined"
                    hiddenLabel={true}
                    fullWidth={true}
                    multiline
                    InputProps={{
                      classes: { root: styles["up-form-phones-input"] },
                      endAdornment:
                        formik.values.phones?.length > 1 ? (
                          <IconButton
                            onClick={() => formHelpers.remove(index)}
                            className={styles["up-form-phones-delete"]}
                          >
                            <img src={DeleteIcon} alt="Delete" />
                          </IconButton>
                        ) : (
                          <></>
                        ),
                    }}
                    id={`phones.${index}`}
                    name={`phones.${index}`}
                    value={formik.values.phones[index]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phones &&
                      Boolean(formik.errors.phones ? formik.errors.phones[index] : false)
                    }
                  />
                ))}
                <Button
                  classes={{ root: styles["up-form-phones-add"] }}
                  variant="outlined"
                  startIcon={<AddIcon color="inherit" />}
                  onClick={() => formHelpers.push("")}
                >
                  Add Telephone
                </Button>
                <FormHelperText>
                  {formik.touched.phones &&
                    formik.errors.phones &&
                    (typeof formik.errors.phones === "string"
                      ? formik.errors.phones
                      : formik.errors.phones.filter((i) => i.length)[0])}
                </FormHelperText>
              </div>
            )}
          </FieldArray>
          <TextField
            classes={{ root: styles["up-form-email"] }}
            label="email"
            variant="outlined"
            hiddenLabel={true}
            fullWidth={true}
            disabled={true}
            multiline
            InputProps={{
              classes: { root: styles["up-form-email-input"] },
            }}
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          {isAgent ? (
            <>
              <div className={styles["up-form-address"]}>
                <AddressField
                  addressValue={profileInfo.agentProfile?.address}
                  toggleValue={(e) => formik.setFieldValue("address", e)}
                  errors={formik.errors.address}
                  touched={formik.touched.address}
                ></AddressField>
              </div>
              <div className={styles["up-form-geolocation"]}>
                <GeolocationField
                  searchValue={formik.values.address}
                  position={profileInfo.agentProfile?.geolocation}
                  toggleValue={(e) => formik.setFieldValue("geolocation", e)}
                  errors={formik.errors.geolocation}
                  touched={formik.touched.geolocation}
                ></GeolocationField>
              </div>

              <FieldArray name="links">
                {(formHelpers: any) => (
                  <div className={styles["up-form-links"]}>
                    {formik.values.links?.map((_, index) => (
                      <TextField
                        key={index}
                        classes={{ root: styles["up-form-links-root"] }}
                        label={"Link"}
                        variant="outlined"
                        hiddenLabel={true}
                        fullWidth={true}
                        multiline
                        InputProps={{
                          classes: { root: styles["up-form-links-input"] },
                          endAdornment: (
                            <IconButton
                              onClick={() => formHelpers.remove(index)}
                              className={styles["up-form-links-delete"]}
                            >
                              <img src={DeleteIcon} alt="Delete" />
                            </IconButton>
                          ),
                        }}
                        id={`links.${index}`}
                        name={`links.${index}`}
                        value={formik.values.links[index]}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.links &&
                          Boolean(
                            formik.errors.links ? formik.errors.links[index] : false
                          )
                        }
                        helperText={
                          formik.touched.links &&
                          formik.errors.links &&
                          formik.errors.links[index]
                        }
                      />
                    ))}
                    <Button
                      classes={{ root: styles["up-form-links-add"] }}
                      variant="outlined"
                      startIcon={<AddIcon color="inherit" />}
                      onClick={() => formHelpers.push("")}
                    >
                      Add Link
                    </Button>
                  </div>
                )}
              </FieldArray>
              <div className={styles["up-form-working-hours"]}>
                <label className={styles["up-form-working-hours-title"]}>
                  Working hours in <strong>{currentUser?.timezone.value || "UTC"}</strong>{" "}
                  timezone
                </label>

                <div className={styles["up-form-working-hours-block"]}>
                  {workingHoursDefault.length ? (
                    <WorkingHours
                      hoursChanged={setWorkingHours}
                      hours={workingHoursDefault}
                    />
                  ) : null}
                </div>
              </div>

              {isUserHavePermissions(currentUser!, [
                PermissionsList.administerTesting,
              ]) && (
                <>
                  <div className={styles["up-form-test-mode"]}>
                    <FormControlLabel
                      classes={{
                        root: styles["up-container-checkbox"],
                        label: styles["up-container-checkbox-label"],
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          checked={formik.values.isTest}
                          onChange={(e) =>
                            formik.setFieldValue("isTest", e.target.checked)
                          }
                        />
                      }
                      label="Test mode"
                    />
                  </div>
                  <div className={styles["up-form-geolocation"]}>
                    <GeolocationField
                      position={currentUser!.routerSettings?.testMode.points}
                      toggleValue={(e) => formik.setFieldValue("testGeolocation", e)}
                      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                      errors={formik.errors.testGeolocation}
                      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                      touched={formik.touched.testGeolocation}
                    ></GeolocationField>
                  </div>

                  <div className={styles["up-form-test-time"]}>
                    <label>Test time</label>

                    <div className={styles["up-form-test-time-block"]}>
                      <UpTimePicker
                        optionGroups={TimeOptionGroups}
                        valueGroups={
                          timeTestValue as unknown as {
                            [key: string]: string;
                          }
                        }
                        onChange={(name: string, value: string) =>
                          timeTestValue &&
                          setTimeTestValue({
                            ...timeTestValue,
                            [name]: value,
                          })
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : null}
        </FormikProvider>
      </div>
    </div>
  );
}
export default ProfileForm;
