const moment = require("moment-timezone");

import { SpecialDaysDto, WorkingHoursItemI } from "@interfaces";
import { Moment } from "moment";

export const WorkingHoursFormat = (date: WorkingHoursItemI): string => {
  return `${WeekDays[date.day]}: ${date.from}-${date.to}`;
};

export const WeekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const WeekShortDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const MessengersKeys = ["https://wa.me/", "https://m.me/", "https://t.me/"];

export const formatTimeStringToDate = (date: string, timezone: string): string => {
  return moment.tz(date, "hh:mm A", timezone).format("YYYY-MM-DDTHH:mm:ssZZ");
};

/**
 * Generates the color based on the name of the agent.
 * @param str
 */
function stringToColor(str: string) {
  let hash = 0;
  let i;

  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

/**
 * Generates initials and background color.
 * @param name
 */
export function stringAvatar(name: string, transparent?: boolean) {
  const splitted = name.split(" ");
  const filtered = splitted.filter((part) => part.trim() !== "");
  return {
    sx: {
      bgcolor: !transparent ? stringToColor(name) : "transparent",
    },
    children:
      filtered.length > 1 ? `${filtered[0][0]}${filtered[1][0]}` : `${filtered[0][0]}`,
  };
}

export function formatVacations(vacations: SpecialDaysDto[]): Moment[] {
  return vacations
    .filter(
      (i: SpecialDaysDto) =>
        i.working_hours_start_time === null && i.working_hours_end_time === null
    )
    .flatMap((i: SpecialDaysDto) => {
      const startDate = moment(i.start_date);
      if (!i.end_date) return [startDate];
      const endDate = moment(i.end_date);
      const daysArray = [];

      // Loop through each day between startDate and endDate (inclusive)
      for (let d = startDate; d <= endDate; d = d.clone().add(1, "d")) {
        daysArray.push(moment(d)); // Add a moment instance for each day
      }
      return daysArray;
    });
}
