import axios, { AxiosError } from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import LocationsForm from "components/Shared/Locations/LocationsForm/LocationsForm";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { checkPermissionsForEdit } from "shared/helpers/common.helper";
import { deleteLocation, editLocationData } from "shared/Services";

import { LocationFormI } from "@interfaces";

import styles from "./LocationEdit.module.scss";
import { toast } from "react-toastify";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { useLocationData } from "./hooks/useLocationData";
import { Footer } from "components/Shared/Footer/Footer";

function LocationEdit() {
  const { currentUser } = useContext(UserDataContext);

  const [submitted, setSubmitted] = useState(0);
  const params = useParams();
  const navigate = useNavigate();

  const { locationInfo, isLoading } = useLocationData(params.locationId);

  const FormSubmit = (value: LocationFormI) => {
    editLocationData(params.locationId!, value).then((data: AxiosError | any) => {
      if (axios.isAxiosError(data)) {
        toast.error(
          data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        toast.success("Successfully completed!");
        navigate(`/locations/${params.locationId}`);
      }
    });
  };

  const ToggleDelete = () => {
    params.locationId &&
      deleteLocation(params.locationId).then((data: AxiosError | any) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          toast.success("Successfully deleted!");
          navigate(`/locations/`);
        }
      });
  };
  return (
    <>
      <div className={styles["up-container"]}>
        <FormsHeader
          title="Edit Location"
          onRightBtnToggle={() => setSubmitted(submitted + 1)}
          position={"absolute"}
          background="transparent"
          viewDeleteButton={checkPermissionsForEdit(
            currentUser!,
            PermissionsList.deleteAnyLocation,
            PermissionsList.deleteOwnLocation,
            locationInfo?.authorId || ""
          )}
          deleteToggle={() => ToggleDelete()}
          onCancelToggle={() => navigate(-1)}
        />

        <LoadingSpinner isLoading={isLoading} height={300}>
          <>
            {locationInfo && (
              <LocationsForm
                submitted={submitted}
                formValue={locationInfo}
                formFinished={FormSubmit}
              ></LocationsForm>
            )}
          </>
        </LoadingSpinner>
      </div>
      <Footer />
    </>
  );
}

export default LocationEdit;
