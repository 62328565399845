import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { checkDate12AMto00 } from "shared/helpers/time.helper";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { Fab, InputAdornment, TextField } from "@mui/material";

import styles from "./AllNotesView.module.scss";
import { useAllNotesData } from "./hooks/useAllNotesData";
import { useContext } from "react";
import { UserDataContext } from "shared/Contexts";

function AllNotesView() {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserDataContext);

  const {
    content,
    setSearchText,
    searchText,
    hasMore,
    isLoading,
    reload,
    setPageNumber,
    searchContent,
  } = useAllNotesData();

  return (
    <div className={styles["up-container"]}>
      <Header
        title={"All Notes"}
        toggleBack={() => navigate("/")}
        toggleReload={reload}
      />
      <TextField
        hiddenLabel={true}
        fullWidth={true}
        onChange={(e) => setSearchText(e.target.value)}
        InputProps={{
          classes: {
            root: styles["up-search-root"],
            input: styles["up-search-input"],
          },
          endAdornment: (
            <InputAdornment position={"end"} classes={{ root: styles["up-search-icon"] }}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant={"filled"}
        placeholder={"Search"}
      />

      <LoadingSpinner isLoading={isLoading} height={300}>
        <div className={styles["up-content"]}>
          {!searchText ? (
            <InfiniteScroll
              style={{ overflow: "hidden" }}
              dataLength={content.length}
              next={() => setPageNumber((prev) => prev + 1)}
              hasMore={hasMore}
              loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
            >
              {content.length ? (
                content.map((i) => (
                  <div
                    className={styles["up-content-card"]}
                    key={i.locationId}
                    onClick={() => navigate(`location/${i.locationId}`)}
                  >
                    <div className={styles["up-content-card-header"]}>
                      <h2 className={styles["up-content-card-header-title"]}>
                        {i.locationName}
                      </h2>
                      {i.count && (
                        <span className={styles["up-content-card-header-count"]}>
                          {i.count}
                        </span>
                      )}
                    </div>

                    <p className={`up-truncate-6`}>{i.lastNote}</p>
                  </div>
                ))
              ) : (
                <div className="up-no-items">No note records found</div>
              )}
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              style={{ overflow: "hidden" }}
              dataLength={searchContent.length}
              next={() => setPageNumber((prev) => prev + 1)}
              hasMore={hasMore}
              loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
            >
              {searchContent.length ? (
                searchContent.map((i) => (
                  <div
                    className={styles["up-content-card"]}
                    key={i.id}
                    onClick={() => navigate(`/notes/${i.id}`)}
                  >
                    <div className={styles["up-content-card-header"]}>
                      <div className={styles["up-content-card-header-left"]}>
                        {i.task && (
                          <span className={styles["up-content-card-header-task"]}>
                            {i.task}
                          </span>
                        )}
                        {i.location && (
                          <span className={styles["up-content-card-header-location"]}>
                            {i.location}
                          </span>
                        )}
                      </div>
                      <span className={styles["up-content-card-header-date"]}>
                        {checkDate12AMto00(i.date, currentUser!.timezone.value)}
                      </span>
                    </div>
                    <p className={`${styles["content"]} up-truncate-6`}>{i.content}</p>
                    <div className={styles["up-content-card-footer"]}>
                      <span className={styles["up-content-card-footer-author"]}>
                        {i.author}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="up-no-items">No note records found</div>
              )}
            </InfiniteScroll>
          )}
        </div>
      </LoadingSpinner>
      {isUserHavePermissions(currentUser!, [PermissionsList.createNote]) && (
        <Fab
          aria-label={"add task"}
          color={"inherit"}
          classes={{ root: styles["up-fab-root"] }}
          onClick={() => navigate("/notes/create")}
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
}

export default AllNotesView;
