import { AxiosResponse } from "axios";

import { jsonApiFile, jsonAuthApi } from "./Settings.api.service";

export const apiCompaniesList = (
  pageNumber: number,
  id?: string | null
): Promise<AxiosResponse<any, any>> => {
  const idFilter = id
    ? {
        "filter[uid.id][value]": id,
      }
    : {};

  return jsonAuthApi.get(`/ups_company/ups_company`, {
    params: {
      sort: "label",
      "page[offset]": pageNumber,
      "page[limit]": "20",
      "filter[status][value]": "1",
      ...idFilter,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetCompanyData = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_company/ups_company/${id}`, {
    params: {
      include: "logo,contacts",
    },
  });
};

export const apiGetCompanyNameId = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_company/ups_company/${id}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiEditCompanyData = (
  id: string,
  body: any
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.patch(`/ups_company/ups_company/${id}`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiDeleteCompany = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.delete(`/ups_company/ups_company/${id}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiCreateCompany = (body: any): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.post(`/ups_company/ups_company/`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiUploadCompanyLogo = (
  id: string,
  body: any
): Promise<AxiosResponse<any, any>> => {
  return jsonApiFile.post(`/ups_company/ups_company/${id}/logo`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
