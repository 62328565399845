import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";

import PageNotFound from "../../PageNotFound/PageNotFound";
import LocationCreate from "./LocationCreate/LocationCreate";
import LocationEdit from "./LocationEdit/LocationEdit";
import LocationsListView from "./LocationsListView/LocationsListView";
import LocationView from "./LocationView/LocationView";
import { ErrorBoundary } from "error";

function LocationsRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <Routes>
      {isUserHavePermissions(currentUser!, [PermissionsList.createLocation]) && (
        <>
          <Route
            path="/create"
            element={
              <ErrorBoundary>
                <LocationCreate />
              </ErrorBoundary>
            }
          />
          <Route
            path="/create/:companyId"
            element={
              <ErrorBoundary>
                <LocationCreate />
              </ErrorBoundary>
            }
          />
        </>
      )}
      {isUserHavePermissions(currentUser!, [
        PermissionsList.viewAnyLocation,
        PermissionsList.viewOwnLocation,
        PermissionsList.viewMyServiceLocation,
      ]) && (
        <>
          <Route
            path="/"
            element={
              <ErrorBoundary>
                <LocationsListView />
              </ErrorBoundary>
            }
          />
          <Route
            path="/:locationId"
            element={
              <ErrorBoundary>
                <LocationView />
              </ErrorBoundary>
            }
          />
        </>
      )}
      {isUserHavePermissions(currentUser!, [
        PermissionsList.editAnyLocation,
        PermissionsList.editOwnLocation,
      ]) && (
        <>
          <Route
            path="/:locationId/edit"
            element={
              <ErrorBoundary>
                <LocationEdit />
              </ErrorBoundary>
            }
          />
        </>
      )}
      <Route path={`*`} element={<PageNotFound />} />
    </Routes>
  );
}

export default LocationsRouter;
