import { createContext, Dispatch, SetStateAction } from "react";

export interface HeaderContextI {
  opened: boolean;
  addOpened: boolean;
}
export const HeaderContext = createContext<
  [HeaderContextI, Dispatch<SetStateAction<HeaderContextI>>]
>([
  {
    opened: false,
    addOpened: false,
  },
  () => {},
]);
