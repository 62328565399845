import { AppBar, Button, IconButton } from "@mui/material";

import DeleteIcon from "../../../../assets/icons/delete.svg";
import styles from "./FormsHeader.module.scss";
import { useState, useEffect } from "react";

interface FormHeaderPropsI {
  title: string;
  position?: "fixed" | "relative" | "absolute";
  onCancelToggle: () => void;
  deleteToggle?: () => void;
  viewDeleteButton?: boolean;
  stepper?: {
    step: number;
    spepsCount: number;
    onStepperNext: () => void;
    onStepperBack: () => void;
  };
  onRightBtnToggle?: () => void;
  hideRightButton?: boolean;
  disableNext?: boolean;
  rightBtnText?: string;
  background?: string;
}

function FormsHeader({
  title,
  position,
  onCancelToggle,
  deleteToggle,
  viewDeleteButton,
  onRightBtnToggle,
  hideRightButton,
  stepper,
  rightBtnText,
  background,
  disableNext,
}: FormHeaderPropsI) {
  const stepMax = stepper ? stepper.spepsCount - 1 : 0;
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      position={isFixed ? "fixed" : position || "fixed"}
      classes={{ root: styles["up-header"] }}
      style={{
        background: isFixed ? "#fff" : background || "transparent",
        borderBottomColor: isFixed ? "#e3edff" : "none",
      }}
    >
      <Button
        onClick={() => {
          onCancelToggle();
        }}
        classes={{ root: styles["up-header-button"] }}
      >
        {"Cancel"}
      </Button>
      <div
        className={styles["up-header-title"]}
        style={{ padding: stepper ? "6.25rem" : "4.375rem" }}
      >
        {title}
      </div>
      {viewDeleteButton && (
        <IconButton onClick={deleteToggle} className={styles["up-header-delete"]}>
          <img src={DeleteIcon} alt="Delete" />
        </IconButton>
      )}
      {stepper && (
        <div className={styles["up-header-stepper"]}>
          <Button
            disabled={stepper.step === 0}
            onClick={() => stepper && stepper.onStepperBack()}
            classes={{ root: styles["up-header-button"] }}
          >
            {"Back"}
          </Button>
          <Button
            disabled={disableNext}
            onClick={() => stepper && stepper.onStepperNext()}
            classes={{ root: styles["up-header-button"] }}
          >
            {stepper.step === stepMax ? "Save" : "Next"}
          </Button>
        </div>
      )}
      {onRightBtnToggle && !hideRightButton && (
        <Button
          onClick={() => onRightBtnToggle && onRightBtnToggle()}
          classes={{ root: styles["up-header-button"] }}
        >
          {rightBtnText ? rightBtnText : "Done"}
        </Button>
      )}
    </AppBar>
  );
}

export default FormsHeader;
