import Header from "components/Shared/Header/Header";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { AppType } from "shared/Enums";

function PageNotFound() {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserDataContext);

  // Redirect to the home page if the user is using Volt and is on the index.html page
  // TODO - check if it's possible to remove this check
  useEffect(() => {
    if (
      process.env.REACT_APP_TYPE === AppType.app &&
      window.location.pathname === "/index.html"
    ) {
      navigate("/");
    }
    if (!currentUser) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Header title={""} toggleBack={() => navigate("/")} hideReload={true} />
      <div style={{ paddingTop: "7rem", textAlign: "center" }}>Page not found</div>
    </>
  );
}

export default PageNotFound;
