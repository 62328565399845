import axios from "axios";
import { Moment } from "moment";
import {
  AppointmentsType,
  DayScheduleStepsStatusesNew,
  DayScheduleStepsTypeNew,
} from "shared/Enums";
import { formatTimeStringToDate } from "shared/helpers/profile.helper";

import {
  AppointmentCreateFormI,
  AppointmentDataI,
  ResponseDto,
  VisitCardsNewI,
} from "@interfaces";

import {
  apiCreateAppointment,
  apiDeleteAppointment,
  apiEditAppointment,
  apiGetAppointmentData,
  apiGetAppointmentsByMonth,
  apiGetAppointmentsList,
} from "./Api/Appointments.api.service";
import { apiGetVisitsByMonth, apiGetVisitsList } from "./Api/Visits.api.service";

const moment = require("moment-timezone");

export const createAppointment = (body: AppointmentCreateFormI, timezone: string) => {
  return apiCreateAppointment({
    data: {
      type: "ups_appointment--ups_appointment",
      attributes: {
        description: body.title,
        date: body.date?.format("YYYY-MM-DD"),
        expected_duration: body.expectedDuration,
        time_range: body.isAllDay
          ? {}
          : {
              value: formatTimeStringToDate(body.time!.from, timezone),
              end_value: formatTimeStringToDate(body.time!.to, timezone),
            },
        is_virtual: body.type === AppointmentsType.call,
      },
      relationships: {
        location: {
          data: {
            type: "ups_location--ups_location",
            id: body.location,
          },
        },
      },
    },
  }).catch((err) => err);
};

export const editAppointment = (
  id: string,
  body: AppointmentCreateFormI,
  timezone: string
) => {
  return apiEditAppointment(id, {
    data: {
      type: "ups_appointment--ups_appointment",
      id: id,
      attributes: {
        description: body.title,
        date: body.date?.format("YYYY-MM-DD"),
        expected_duration: body.expectedDuration,
        time_range: body.isAllDay
          ? {}
          : {
              value: formatTimeStringToDate(body.time!.from, timezone),
              end_value: formatTimeStringToDate(body.time!.to, timezone),
            },
        is_virtual: body.type === AppointmentsType.call,
      },
      relationships: {
        location: {
          data: {
            type: "ups_location--ups_location",
            id: body.location,
          },
        },
      },
    },
  }).catch((err) => err);
};

export const DeleteAppointment = (id: string) => {
  return apiDeleteAppointment(id).catch((err) => err);
};

export const getAppointmentData = (id: string) => {
  return apiGetAppointmentData(id).then((response) => {
    const location = response.data.included?.find(
      (i: ResponseDto<any>) => i.type === "ups_location--ups_location"
    );
    return {
      id: response.data.data.id,
      title: response.data.data.attributes.description?.value || "",
      location: location
        ? {
            name: location.attributes.label,
            id: location.id,
          }
        : undefined,
      type: response.data.data.is_virtual
        ? AppointmentsType.call
        : AppointmentsType.visit,
      isAllDay: !response.data.data.attributes.time_range,
      date: response.data.data.attributes.date,
      expectedDuration: response.data.data.attributes.expected_duration,
      time: response.data.data.attributes.time_range
        ? {
            from: response.data.data.attributes.time_range.value,
            to: response.data.data.attributes.time_range.end_value,
          }
        : undefined,
    } as AppointmentDataI;
  });
};
export const getAppointmentsVisitsList = (date: Moment, uid: string) => {
  return axios
    .all([
      apiGetAppointmentsList(date.clone().utc().format("YYYY-MM-DD"), uid),
      apiGetVisitsList(date.clone(), uid),
    ])
    .then((resp) => {
      const items = [...resp[0].data.data, ...resp[1].data.data].sort(
        (a: any, b: any) => {
          const dateA = moment(a.attributes.created);
          const dateB = moment(b.attributes.created);
          return dateA.diff(dateB);
        }
      );
      const included = [
        ...(resp[0].data?.included || []),
        ...(resp[1].data?.included || []),
      ];

      return items.map((item: any) => {
        const location = included?.find(
          (i: ResponseDto<any>) =>
            i.type === "ups_location--ups_location" &&
            i.id === item.relationships.location?.data?.id
        );
        return {
          cardType: DayScheduleStepsTypeNew.service,
          status: item.attributes.visit_status || DayScheduleStepsStatusesNew.completed,
          id: item.id,
          arrival: item.attributes.date,
          isVirtual: item.attributes.is_virtual,
          geolocation: location?.attributes.geolocation,
          location: {
            id: location?.id,
            address: location?.attributes.address,
            title: location?.attributes.label,
            phoneNumbers: location?.attributes.telephones || [],
          },
          appointment:
            item.type === "ups_appointment--ups_appointment"
              ? {
                  id: item.id,
                  type: item.type,
                  meta: {
                    drupal_internal__target_id: item.attributes.drupal_internal__id,
                  },
                }
              : null,
          visitId: item.type === "ups_visit--ups_visit" ? item.id : null,
          time:
            item.type === "ups_appointment--ups_appointment"
              ? {
                  from: item.attributes.time_range?.value,
                  to: item.attributes.time_range?.end_value,
                }
              : undefined,
        } as VisitCardsNewI;
      });
    })
    .catch((err) => err);
};

export const getAppointmentsAndVisitsByMonth = (date: Moment, uid: string) => {
  return axios
    .all([
      apiGetAppointmentsByMonth(date.clone(), uid),
      apiGetVisitsByMonth(date.clone(), "completed", uid),
    ])
    .then((resp) => {
      const items = [...resp[0].data.data, ...resp[1].data.data].sort((a, b) => {
        return moment(a.attributes.created).diff(moment(b.attributes.created));
      });

      return items.map((item: any) => {
        return {
          date: moment.utc(item.attributes.date).format("YYYY-MM-DD"),
        };
      });
    })
    .catch((err) => err);
};
export const getVisitsByMonth = (date: Moment, status: string, uid: string) => {
  return apiGetVisitsByMonth(date.clone(), status, uid)
    .then((resp) => {
      const items = resp.data.data.sort(
        (a: { attributes: { created: any } }, b: { attributes: { created: any } }) => {
          return moment(a.attributes.created).diff(moment(b.attributes.created));
        }
      );
      return items.map((item: any) => {
        return {
          date: moment.utc(item.attributes.date).format("YYYY-MM-DD"),
          status: item.attributes.visit_status,
          id: item.id,
        };
      });
    })
    .catch((err) => err);
};
