import axios, { AxiosError } from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { checkPermissionsForEdit } from "shared/helpers/common.helper";
import { deleteNote } from "shared/Services";

import styles from "./NoteView.module.scss";
import { toast } from "react-toastify";
import { useNoteData } from "./hooks/useNoteData";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";

function NoteView() {
  const { currentUser } = useContext(UserDataContext);
  const navigate = useNavigate();
  const params = useParams();

  const { noteInfo, loading } = useNoteData(params.noteId);

  const ToggleDelete = () => {
    params.noteId &&
      deleteNote(params.noteId).then((data: AxiosError | any) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          toast.success("Successfully deleted!");
          navigate(`/notes`);
        }
      });
  };
  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Note"
        deleteToggle={() => ToggleDelete()}
        viewDeleteButton={checkPermissionsForEdit(
          currentUser!,
          PermissionsList.deleteAnyNote,
          PermissionsList.deleteOwnNote,
          noteInfo?.author.id || ""
        )}
        rightBtnText={"Edit"}
        hideRightButton={
          !checkPermissionsForEdit(
            currentUser!,
            PermissionsList.editAnyNote,
            PermissionsList.editOwnNote,
            noteInfo?.author.id || ""
          )
        }
        onRightBtnToggle={() => {
          navigate("edit");
        }}
        onCancelToggle={() => navigate(-1)}
      />
      <LoadingSpinner isLoading={loading}>
        <>
          {noteInfo && (
            <div className={styles["up-content"]}>
              <label className={styles["up-content-label"]}>Location</label>
              <div className={styles["up-content-value"]}>
                <span onClick={() => navigate(`/locations/${noteInfo.location.id}`)}>
                  {noteInfo.location.title}
                </span>
              </div>
              {noteInfo.task.id && (
                <>
                  <label className={styles["up-content-label"]}>Task</label>
                  <div className={styles["up-content-value"]}>
                    <span onClick={() => navigate(`/tasks/${noteInfo.task.id}`)}>
                      {noteInfo.task.title}
                    </span>
                  </div>
                </>
              )}
              {noteInfo.visit.id && (
                <>
                  <label className={styles["up-content-label"]}>Visit</label>
                  <div className={styles["up-content-value"]}>
                    <span onClick={() => navigate(`/visits/${noteInfo.visit.id}`)}>
                      {noteInfo.visit.title}
                    </span>
                  </div>
                </>
              )}
              <label className={styles["up-content-label"]}>By</label>
              <div className={styles["up-content-value"]}>{noteInfo.author.name}</div>
              <div className={styles["up-description"]}>{noteInfo.content}</div>
            </div>
          )}
        </>
      </LoadingSpinner>
    </div>
  );
}

export default NoteView;
