import { NoteViewI } from "@interfaces";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getNoteData } from "shared/Services";

export const useNoteData = (noteId?: string) => {
  const [noteInfo, setNoteInfo] = useState<NoteViewI>();
  const [error, setError] = useState<AxiosError<any>>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (noteId) {
          const data = await getNoteData(noteId);
          setNoteInfo(data);
        }
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(
        error.response?.data?.errors?.length
          ? error.response?.data?.errors[0]?.detail
          : error.message
      );
      throw error;
    }
  }, [error]);

  return { noteInfo, loading };
};
