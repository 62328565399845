import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CountriesStatesContext, RouterContext, UserDataContext } from "shared/Contexts";
import {
  DayScheduleActionsNew,
  DayScheduleStepsStatusesNew,
  DayScheduleTypeNew,
  PermissionsList,
} from "shared/Enums";
import { check12AMto00 } from "shared/helpers/time.helper";
import {
  formatAddressToString,
  isUserHavePermissions,
} from "shared/helpers/common.helper";
import { DayScheduleDayNewI, VisitCardsNewI } from "@interfaces";
import { Button, Card, CardActions, CardContent, CardHeader } from "@mui/material";

import CarIcon from "../../../assets/icons/car.svg";
import PhoneIcon from "../../../assets/icons/phone.svg";
import styles from "./VisitCard.module.scss";
import { toast } from "react-toastify";
import { UserDataContextI } from "shared/Contexts/UserDataContext";
import { openMapApp } from "shared/helpers/openMapApp";

function VisitCard({
  item,
  showButtons = true,
  active,
  onActionToggle,
  day,
  editableAppointment = false,
}: {
  item: VisitCardsNewI;
  showButtons?: boolean;
  active?: string;
  day?: DayScheduleDayNewI;
  editableAppointment?: boolean;
  onActionToggle: (action: DayScheduleActionsNew, id: string, locationId: string) => void;
}) {
  const navigate = useNavigate();
  const { countriesStates } = useContext(CountriesStatesContext);
  const { currentUser } = useContext(UserDataContext);
  const [, setRouterValue] = useContext(RouterContext);

  const isMissed = item.status === DayScheduleStepsStatusesNew.missed;
  const isOverdue = item.isLocationOverdue;

  const headerTitle = (item: VisitCardsNewI) => {
    if (item.status === DayScheduleStepsStatusesNew.inProgress) {
      return "In progress";
    } else if (isOverdue) {
      return "Overdue";
    } else if (item.appointment) {
      return isMissed ? "Missed Appointment" : "Appointment";
    }
  };

  return (
    <Card
      classes={{
        root: `${styles["up-card"]} 
        ${active === item.id ? styles["active"] : ""}
        ${item.appointment ? styles["appointment"] : ""}
        ${isMissed ? styles["missed"] : ""}
        ${isOverdue ? styles["overdue"] : ""}
        ${
          item.status === DayScheduleStepsStatusesNew.inProgress
            ? styles["in-progress"]
            : ""
        }`,
      }}
    >
      <CardHeader
        classes={{
          root: styles["up-card-header"],
          title: styles["up-card-header-title"],
          action: styles["up-card-header-action"],
        }}
        action={
          <>
            {item.location?.priority ? (
              <>
                {"Priority"}
                <span className={styles[`priority-${item.location?.priority}`]}>
                  {item.location?.priority}
                </span>
              </>
            ) : null}
          </>
        }
        title={headerTitle(item)}
      />

      <CardContent classes={{ root: styles["up-card-content"] }}>
        <div
          className={styles["place-name"]}
          onClick={() => navigate(`/locations/${item.location.id}`)}
        >
          {item.location.title}
        </div>
        {item.location && (
          <div className={styles.address}>
            {formatAddressToString(item.location.address, countriesStates)}
          </div>
        )}

        {item.time ? (
          <div className={styles["travel-time"]}>
            {check12AMto00(item.time.from, currentUser!.timezone.value)} -
            {check12AMto00(item.time.to, currentUser!.timezone.value)}
          </div>
        ) : typeof item.arrival !== "string" ? (
          <div className={styles["travel-time"]}>
            {check12AMto00(item.arrival.from, currentUser!.timezone.value)} -
            {check12AMto00(item.arrival.to, currentUser!.timezone.value)}
          </div>
        ) : !item.isVirtual ? (
          <div className={styles["travel-time"]}>
            <img alt="car icon" src={CarIcon} />
            ETA {check12AMto00(item.arrival, currentUser!.timezone.value)}
          </div>
        ) : (
          <div className={styles["call-time"]}>
            <img alt="phone icon" src={PhoneIcon} />
            {check12AMto00(item.arrival, currentUser!.timezone.value)}
          </div>
        )}

        {item.location?.phoneNumbers.length ? (
          <div className={styles["plone-number"]}>
            {item.location.phoneNumbers.map((p, index) => (
              <a key={index} rel="noreferrer" target="_blank" href={`tel:${p}`}>
                {p}
              </a>
            ))}
          </div>
        ) : null}
      </CardContent>
      {showButtons && (
        <CardActions classes={{ root: styles["up-card-actions"] }} disableSpacing>
          {day?.status === DayScheduleTypeNew.started &&
            (() => {
              switch (item.status) {
                case DayScheduleStepsStatusesNew.pending:
                case DayScheduleStepsStatusesNew.inProgress:
                  return (
                    <>
                      {!item.visitId &&
                        isUserHavePermissions(currentUser as UserDataContextI, [
                          PermissionsList.createVisit,
                          PermissionsList.editOwnVisit,
                        ]) && (
                          <Button
                            onClick={() =>
                              onActionToggle(
                                DayScheduleActionsNew.skipVisit,
                                item.id,
                                item.location.id
                              )
                            }
                            variant="outlined"
                            classes={{ root: styles["up-card-button"] }}
                          >
                            Skip
                          </Button>
                        )}
                      {item.visitId &&
                      isUserHavePermissions(currentUser as UserDataContextI, [
                        PermissionsList.editOwnVisit,
                      ]) &&
                      (item.status === DayScheduleStepsStatusesNew.pending ||
                        item.status === DayScheduleStepsStatusesNew.inProgress) ? (
                        <Button
                          variant="outlined"
                          onClick={() => navigate(`/visits/${item.visitId}`)}
                          classes={{ root: styles["up-card-button"] }}
                        >
                          Edit Visit
                        </Button>
                      ) : (
                        isUserHavePermissions(currentUser as UserDataContextI, [
                          PermissionsList.createVisit,
                        ]) && (
                          <Button
                            variant="outlined"
                            classes={{ root: styles["up-card-button"] }}
                            onClick={() =>
                              onActionToggle(
                                DayScheduleActionsNew.createVisit,
                                item.id,
                                item.location.id
                              )
                            }
                          >
                            Create Visit
                          </Button>
                        )
                      )}
                    </>
                  );
                case DayScheduleStepsStatusesNew.skipped:
                  return (
                    isUserHavePermissions(currentUser as UserDataContextI, [
                      PermissionsList.deleteOwnVisit,
                    ]) && (
                      <Button
                        onClick={() =>
                          onActionToggle(
                            DayScheduleActionsNew.unskipVisit,
                            item.id,
                            item.location.id
                          )
                        }
                        variant="outlined"
                        classes={{
                          root: `${styles["up-card-button"]} ${styles["unskip"]}`,
                        }}
                      >
                        Unskip
                      </Button>
                    )
                  );
              }
            })()}

          {day?.status === DayScheduleTypeNew.ended && item.id && (
            <>
              <Button
                variant="outlined"
                onClick={() => navigate(`/visits/${item.id}`)}
                classes={{ root: styles["up-card-button"] }}
              >
                View Visit
              </Button>
              {item.status === DayScheduleStepsStatusesNew.inProgress &&
                isUserHavePermissions(currentUser as UserDataContextI, [
                  PermissionsList.deleteOwnVisit,
                ]) && (
                  <Button
                    onClick={() =>
                      onActionToggle(
                        DayScheduleActionsNew.skipVisit,
                        item.id,
                        item.location.id
                      )
                    }
                    variant="outlined"
                    classes={{
                      root: `${styles["up-card-button"]} ${styles["skip"]}`,
                    }}
                  >
                    Skip
                  </Button>
                )}
            </>
          )}
          {editableAppointment &&
            isUserHavePermissions(currentUser as UserDataContextI, [
              PermissionsList.editOwnAppointment,
            ]) &&
            item.appointment && (
              <Button
                variant="outlined"
                onClick={() => navigate(`/visits/${item.id}/edit`)}
                classes={{ root: styles["up-card-button"] }}
              >
                Edit Appointment
              </Button>
            )}

          {!isMissed && (
            <Button
              variant="outlined"
              onClick={() =>
                openMapApp(
                  {
                    lat: item.geolocation?.lat,
                    lng: item.geolocation?.lng,
                  },
                  (e) => toast.error(e)
                )
              }
              classes={{
                root: `${styles["up-card-button"]} ${styles.direction}`,
              }}
            >
              Direction
            </Button>
          )}
          <Button
            variant="outlined"
            classes={{ root: `${styles["up-card-button"]} ${styles.map}` }}
            onClick={() =>
              setRouterValue((prev) => ({
                ...prev,
                gMapOpened: true,
                gMapGeolocation: item.geolocation,
                gMapLocationName: item.location.title,
              }))
            }
          >
            Map
          </Button>
          {!isMissed &&
            item.visitId &&
            isUserHavePermissions(currentUser as UserDataContextI, [
              PermissionsList.viewOwnVisit,
            ]) &&
            item.status !== DayScheduleStepsStatusesNew.pending && (
              <Button
                variant="outlined"
                classes={{ root: styles["up-card-button"] }}
                onClick={() => navigate(`/visits/${item.visitId}`)}
              >
                View
              </Button>
            )}
        </CardActions>
      )}
    </Card>
  );
}

export default VisitCard;
