import { LocationsListI } from "@interfaces";
import { debounce } from "@mui/material";
import { AxiosError } from "axios";
import { useContext, useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { UserDataContext, ManagerContext, CountriesStatesContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import {
  isUserHavePermissions,
  GetStateOrCountryCode,
} from "shared/helpers/common.helper";
import { getLocationsList } from "shared/Services";

export const useLocationsData = (companyId?: string) => {
  const { currentUser } = useContext(UserDataContext);
  const { countriesStates } = useContext(CountriesStatesContext);
  const [{ agent }] = useContext(ManagerContext);

  const [content, setContent] = useState<LocationsListI[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState<AxiosError<any>>();

  useEffect(() => {
    (async () => {
      if (searchText === "" || pageNumber !== 0) {
        try {
          const data = await getLocationsList(pageNumber * 20, searchText, {
            companyId,
            filterByAgent: agent
              ? agent.uuid
              : !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyLocation]) &&
                  isUserHavePermissions(currentUser!, [
                    PermissionsList.viewMyServiceLocation,
                  ])
                ? currentUser!.uid
                : undefined,
            filterByUser:
              !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyLocation]) &&
              !isUserHavePermissions(currentUser!, [
                PermissionsList.viewMyServiceLocation,
              ]) &&
              isUserHavePermissions(currentUser!, [PermissionsList.viewOwnLocation])
                ? currentUser!.uid
                : undefined,
          });
          responseMap(data);
        } catch (error: any) {
          setError(error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(true);
        debouncedCallEndpointRef.current(searchText, countriesStates, content);
      }
    })();
  }, [pageNumber, searchText]);

  const debouncedCallEndpointRef = useRef(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    debounce(async (searchText, pageNumber, content) => {
      try {
        const data = await getLocationsList(0, searchText, {
          companyId,
          filterByAgent: agent
            ? agent.uuid
            : !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyLocation]) &&
                isUserHavePermissions(currentUser!, [
                  PermissionsList.viewMyServiceLocation,
                ])
              ? currentUser!.uid
              : undefined,
          filterByUser:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyLocation]) &&
            !isUserHavePermissions(currentUser!, [
              PermissionsList.viewMyServiceLocation,
            ]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewOwnLocation])
              ? currentUser!.uid
              : undefined,
          countriesStates: GetStateOrCountryCode(searchText, countriesStates),
        });
        responseMap(data);
      } catch (error: any) {
        setError(error);
        setIsLoading(false);
      }
    }, 500)
  );

  const responseMap = (data: AxiosError | any) => {
    if (pageNumber === 0) {
      setContent([...data.content]);
    } else {
      if (
        content.length &&
        data.content.length &&
        content[content.length - 1].letter === data.content[0].letter
      ) {
        content[content.length - 1].locations.push(...data.content[0].locations);
        data.content.shift();
      }
      setContent([...content, ...data.content]);
    }
    setHasMore(data.hasMore);

    setIsLoading(false);
  };

  useEffect(() => {
    if (error) {
      toast.error(
        error.response?.data?.errors?.length
          ? error.response?.data?.errors[0]?.detail
          : error.message
      );
      throw error;
    }
  }, [error]);

  useEffect(() => {
    setPageNumber(0);
  }, [searchText]);

  return { content, setSearchText, hasMore, isLoading, setPageNumber };
};
