import { Button, ClickAwayListener, Popover, TextField } from "@mui/material";
import { PickersDay, StaticDatePicker } from "@mui/x-date-pickers-pro";
import TodayIcon from "@mui/icons-material/Today";
import { useContext, useEffect, useState } from "react";
import { Moment } from "moment";
import styles from "./DatePicker.module.scss";
import moment from "moment";
import { ManagerContext, UserDataContext } from "shared/Contexts";
import axios from "axios";
import { toast } from "react-toastify";
import { getVisitsByMonth } from "shared/Services/Appointments.service";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";

export const CalendarDatePicker = ({
  date,
  handleChange,
  dataLoading,
}: {
  date: Moment;
  handleChange: (date: Moment) => void;
  dataLoading: boolean;
}) => {
  const { currentUser } = useContext(UserDataContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [activeDays, setActiveDays] = useState<{ date: string; status: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [{ agent }] = useContext(ManagerContext);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  useEffect(() => {
    getActiveDaysByMonth(date);
  }, []);

  const isToday = (day: Moment) => {
    const today = moment().tz(currentUser!.timezone.value).startOf("day"); // Get the start of today
    return day.isSame(today, "day");
  };

  const renderPickerDay = (date: any, _: any, pickersDayProps: any) => {
    const selected = activeDays.find((i) => i.date === date.format("YYYY-MM-DD"));
    const inProgress = activeDays.find(
      (i) => i.date === date.format("YYYY-MM-DD") && i.status === "in_progress"
    );
    if (loading || dataLoading) {
      return (
        <div className={styles["active-day-loading"]} key={pickersDayProps.key}>
          <LoadingSpinner isLoading={true} />
        </div>
      );
    }
    return (
      <PickersDay
        {...pickersDayProps}
        key={pickersDayProps.key}
        disabled={!selected && !isToday(date)}
        className={`${selected && !isToday(date) ? styles["active-day"] : ""} ${inProgress && !isToday(date) ? styles["active-day-red"] : ""}`}
      />
    );
  };

  const getActiveDaysByMonth = (d: Moment) => {
    setLoading(true);
    getVisitsByMonth(d, "", agent ? agent.uuid : currentUser!.uid).then((resp) => {
      if (axios.isAxiosError(resp)) {
        toast.error(resp.response?.data?.errors[0]?.detail);
        setLoading(false);
      } else {
        const updatedActiveDays: { date: string; status: string }[] = [];
        resp.forEach((i: any) => {
          const existingDay = updatedActiveDays.find((day) => day.date === i.date);
          if (existingDay) {
            if (i.status === "in_progress") {
              existingDay.status = "in_progress";
            }
          } else if (!updatedActiveDays.find((day) => day.date === i.date)) {
            updatedActiveDays.push({ date: i.date, status: i.status });
          }
        });
        setActiveDays(updatedActiveDays.filter((i) => i.date !== "cancelled"));
        setLoading(false);
      }
    });
  };
  return (
    <ClickAwayListener onClickAway={handlePopoverClose}>
      <>
        <Button
          classes={{ root: styles["up-icon"] }}
          variant="outlined"
          color={"inherit"}
          onClick={handlePopoverOpen}
          aria-label="back"
        >
          <TodayIcon fontSize={"large"} />
        </Button>

        <Popover
          open={open}
          anchorEl={anchorEl}
          className={styles["up-popover"]}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
        >
          <div className={styles["up-modal"]}>
            <StaticDatePicker
              className={"mat-calendar"}
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={date}
              onChange={(date) => {
                handleChange(date!);
                handlePopoverClose();
              }}
              renderDay={renderPickerDay}
              onMonthChange={(m: Moment) => {
                getActiveDaysByMonth(m);
              }}
              renderInput={(params) => <TextField {...params} />}
              maxDate={moment().tz(currentUser!.timezone.value)}
            />
          </div>
        </Popover>
      </>
    </ClickAwayListener>
  );
};
