import axios from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import ProductsField from "components/Shared/Forms/ProductsField/ProductsField";
import LocationsList from "components/Shared/Locations/LocationsList/LocationsList";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getLocationName } from "shared/Services";
import * as Yup from "yup";

import { LocationFieldI, TaskCreateFormI, TaskDetailsI } from "@interfaces";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";

import styles from "./TasksForm.module.scss";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { TaskPriority } from "../../../../shared/Enums";
import { MobileDatePicker } from "@mui/x-date-pickers-pro";
interface TasksFormPropsI {
  submitted: number;
  formFinished: (value: TaskCreateFormI) => void;
  formValue?: TaskDetailsI;
  locationId?: string;
}

function TasksForm({ submitted, formFinished, formValue, locationId }: TasksFormPropsI) {
  const [searchParams] = useSearchParams();

  const [locationsOpened, setLocationsOpened] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<{
    id: string;
    title: string;
  }>(
    formValue?.location
      ? { id: formValue.location.id, title: formValue.location.title }
      : searchParams.get("locId")
        ? ({
            id: searchParams.get("locId"),
            title: searchParams.get("locTitle"),
          } as LocationFieldI)
        : ({} as LocationFieldI)
  );

  useEffect(() => {
    submitted && formik.handleSubmit();
  }, [submitted]);

  useEffect(() => {
    locationId &&
      getLocationName(locationId).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          setSelectedLocation(data);
        }
      });
  }, [locationId]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is required"),
    products: Yup.array(),
    note: Yup.string(),
    location: Yup.string().required("This field is required"),
    deadline: Yup.string().required("This field is required"),
    priority: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: formValue?.title || "",
      description: formValue?.description || "",
      products: formValue?.products?.map((i) => i.id) || [],
      note: "",
      location: formValue?.location?.id || searchParams.get("locId") || "",
      deadline: formValue?.deadline || "",
      priority: formValue?.priority || "regular",
    } as TaskCreateFormI,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formFinished(values);
    },
  });

  const handleDeadlineChange = (newValue: string | null) => {
    formik.setFieldValue("deadline", newValue);
  };

  return (
    <div className={styles["up-container"]}>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          classes={{ root: styles["up-form-title"] }}
          label="Task Title"
          variant="outlined"
          hiddenLabel={true}
          fullWidth={true}
          multiline
          InputProps={{
            classes: { root: styles["up-form-title-input"] },
          }}
          id="title"
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />

        <TextField
          size="medium"
          classes={{ root: styles["up-form-location"] }}
          label="Location"
          variant="outlined"
          hiddenLabel={true}
          fullWidth={true}
          multiline
          onClick={() => setLocationsOpened(true)}
          InputProps={{
            classes: { root: styles["up-form-location-input"] },
          }}
          id="location"
          name="location"
          value={selectedLocation.title}
          error={formik.touched.location && Boolean(formik.errors.location)}
          helperText={formik.touched.location && formik.errors.location}
        />
        <Modal open={locationsOpened} onClose={() => {}}>
          <div className={styles["up-modal"]}>
            <FormsHeader
              title="Pick Location"
              position="relative"
              onCancelToggle={() => setLocationsOpened(false)}
              onRightBtnToggle={() => setLocationsOpened(false)}
            />

            <div className={styles["up-modal-content"]}>
              <LocationsList
                hasSearch={true}
                active={selectedLocation?.id || undefined}
                locationSelected={(location) => {
                  formik.setFieldValue("location", location.id);
                  setSelectedLocation(location);
                  setLocationsOpened(false);
                }}
              />
            </div>
          </div>
        </Modal>

        <TextField
          classes={{ root: styles["up-form-description"] }}
          label="Description"
          variant="outlined"
          hiddenLabel={true}
          fullWidth={true}
          multiline
          minRows={5}
          InputProps={{
            classes: { root: styles["up-form-description-input"] },
          }}
          id="description"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />

        <MobileDatePicker
          className={styles["up-form-deadline"]}
          InputProps={{
            classes: { root: styles["up-form-deadline-input"] },
          }}
          label="Deadline (end of day)"
          inputFormat="ll"
          value={formik.values.deadline}
          onChange={handleDeadlineChange}
          renderInput={(params) => (
            <TextField
              {...params}
              error={formik.touched.deadline && Boolean(formik.errors.deadline)}
              helperText={formik.touched.deadline && formik.errors.deadline}
            />
          )}
        />

        <FormControl className={styles["up-form-priority"]}>
          <InputLabel>
            Priority (High priority tasks affect how the system generated agent routes)
          </InputLabel>
          <Select
            classes={{ select: styles["up-form-priority-input"] }}
            variant="outlined"
            fullWidth={true}
            id="priority"
            name="priority"
            label={
              "Priority (High priority tasks affect how the system generated agent routes)"
            }
            value={formik.values.priority}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            error={formik.touched?.priority && Boolean(formik.errors?.priority)}
          >
            {Object.entries(TaskPriority).map(([key, value]) => (
              <MenuItem value={key} key={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {formik.touched?.priority && formik.errors?.priority && (
            <FormHelperText>{formik.errors?.priority}</FormHelperText>
          )}
        </FormControl>

        <div className={styles["up-form-products"]}>
          <ProductsField
            selectedProducts={formValue?.products}
            toggleValue={(e) => formik.setFieldValue("products", e)}
            errors={formik.errors.products}
            touched={formik.touched.products}
          ></ProductsField>
        </div>

        {!formValue && (
          <TextField
            classes={{ root: styles["up-form-note"] }}
            label="New note"
            variant="outlined"
            hiddenLabel={true}
            fullWidth={true}
            multiline
            minRows={5}
            InputProps={{
              classes: { root: styles["up-form-note-input"] },
            }}
            id="note"
            name="note"
            value={formik.values.note}
            onChange={formik.handleChange}
            error={formik.touched.note && Boolean(formik.errors.note)}
            helperText={formik.touched.note && formik.errors.note}
          />
        )}
      </form>
    </div>
  );
}

export default TasksForm;
