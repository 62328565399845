import { LocationViewI } from "@interfaces";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getLocationData } from "shared/Services";

export const useLocationData = (locationId?: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [locationInfo, setLocationInfo] = useState<LocationViewI>();
  const [error, setError] = useState<AxiosError<any>>();

  useEffect(() => {
    (async () => {
      if (locationId) {
        try {
          const data = await getLocationData(locationId);
          setLocationInfo(data);
        } catch (error: any) {
          setError(error);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(
        error.response?.data?.errors?.length
          ? error.response?.data?.errors[0]?.detail
          : error.message
      );
      throw error;
    }
  }, [error]);

  return { isLoading, locationInfo };
};
