import axios from "axios";
import { FormikErrors, FormikTouched, useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { AgentI } from "@interfaces";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

import styles from "./AgentsListField.module.scss";
import { toast } from "react-toastify";
import { getAgentsList } from "shared/Services/Agents.service";
import { getUserById } from "shared/Services";

function AgentsListField({
  selectedAgentId,
  errors,
  touched,
  toggleValue,
}: {
  selectedAgentId?: string;
  errors?: FormikErrors<string[] | string>;
  touched?: FormikTouched<boolean>;
  toggleValue: (e: string) => void;
}) {
  const [agentsList, setAgentsList] = useState<AgentI[]>([]);

  useEffect(() => {
    getAgentsList()
      .then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          setAgentsList(data);
          if (!data.some((i) => i.uuid === selectedAgentId) && selectedAgentId) {
            getUserById(selectedAgentId).then((data) => {
              if (axios.isAxiosError(data)) {
                toast.error(
                  data.response?.data?.errors?.length
                    ? data.response?.data?.errors[0]?.detail
                    : ""
                );
                return;
              }
              setAgentsList((prev) => [data, ...prev]);
            });
          }
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  const validationSchema = Yup.object().shape({
    AgentsList: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      AgentsList: selectedAgentId,
    },
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const handleChange = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value);
    toggleValue(e.target.value);
  };

  return agentsList.some((i) => i.uuid === selectedAgentId) || !selectedAgentId ? (
    <FormControl className={styles["up-form"]}>
      <InputLabel>Agent</InputLabel>
      <Select
        classes={{ select: styles["up-form-input"] }}
        variant="outlined"
        fullWidth={true}
        id="AgentsList"
        name="AgentsList"
        value={formik.values.AgentsList || ""}
        displayEmpty
        error={touched && Boolean(errors)}
        label={"Agent"}
        onChange={handleChange}
      >
        <MenuItem value={""} key={""} style={{ display: "none" }}></MenuItem>
        {agentsList?.map((i) => (
          <MenuItem value={i.uuid} key={i.uuid}>
            {i.fullName}
          </MenuItem>
        ))}
      </Select>
      {touched && errors && <FormHelperText>{errors}</FormHelperText>}
    </FormControl>
  ) : null;
}

export default AgentsListField;
