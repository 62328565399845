import { AxiosResponse } from "axios";

import { jsonAuthApi } from "./Settings.api.service";

const moment = require("moment-timezone");

export const apiGetAllActiveTasksList = (
  limit: number,
  page: number,
  locationId: string | undefined,
  params: {
    id?: string;
    serviceId?: string;
  }
): Promise<AxiosResponse<any, any>> => {
  const idFilter = params?.id
    ? {
        "filter[uid.id][value]": params?.id,
      }
    : {};
  const serviceIdFilter = params?.serviceId
    ? {
        "filter[location.agent.id][operator]": "=",
        "filter[location.agent.id][value]": params?.serviceId,
      }
    : {};
  const locationFilter = locationId
    ? {
        "filter[location.id][operator]": "=",
        "filter[location.id][value]": locationId,
      }
    : {};

  return jsonAuthApi.get(`/ups_task/ups_task`, {
    params: {
      "page[limit]": limit,
      "page[offset]": page,
      "filter[status][value]": "1",
      "sort[sort-author][path]": "created",
      "sort[sort-author][direction]": "DESC",
      ...idFilter,
      ...serviceIdFilter,
      "filter[search-group][group][conjunction]": "OR",
      "filter[compaire][condition][path]": "completion_date",
      "filter[compaire][condition][value]": moment().utc().format("YYYY-MM-DDTHH:mm:ssZ"),
      "filter[compaire][condition][operator]": ">",
      "filter[compaire][condition][memberOf]": "search-group",

      "filter[isNull][condition][path]": "completion_date",
      "filter[isNull][condition][operator]": "IS NULL",
      "filter[isNull][condition][memberOf]": "search-group",
      ...locationFilter,
    },
  });
};

export const apiGetAllCompletedTasksList = (
  limit: number,
  page: number,
  locationId: string | undefined,

  params: {
    id?: string;
    serviceId?: string;
  }
): Promise<AxiosResponse<any, any>> => {
  const locationFilter = locationId
    ? {
        "filter[location.id][operator]": "=",
        "filter[location.id][value]": locationId,
      }
    : {};
  const idFilter = params?.id
    ? {
        "filter[uid.id][value]": params?.id,
      }
    : {};
  const serviceIdFilter = params?.serviceId
    ? {
        "filter[location.agent.id][operator]": "=",
        "filter[location.agent.id][value]": params?.serviceId,
      }
    : {};

  return jsonAuthApi.get(`/ups_task/ups_task`, {
    params: {
      "page[limit]": limit,
      "page[offset]": page,
      "sort[sort-author][path]": "completion_date",
      "sort[sort-author][direction]": "DESC",
      "filter[status][value]": "1",
      ...idFilter,
      ...serviceIdFilter,
      "filter[search-group][group][conjunction]": "OR",
      "filter[compaire][condition][path]": "completion_date",
      "filter[compaire][condition][value]": moment().utc().format("YYYY-MM-DDTHH:mm:ssZ"),
      "filter[compaire][condition][operator]": "<",
      "filter[compaire][condition][memberOf]": "search-group",
      ...locationFilter,
      include: "location",
    },
  });
};

export const apiGetTasksListByLocation = (
  locationId: string,
  id?: string
): Promise<AxiosResponse<any, any>> => {
  const idFilter = id
    ? {
        "filter[uid.id][value]": id,
      }
    : {};

  return jsonAuthApi.get(`/ups_task/ups_task`, {
    params: {
      "page[limit]": 10,
      "page[offset]": 0,
      "filter[status][value]": "1",
      "filter[location.id][operator]": "=",
      "filter[location.id][value]": locationId,
      ...idFilter,
    },
  });
};
export const apiGetTaskData = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_task/ups_task/${id}`, {
    params: {
      include: "location,products",
    },
  });
};
export const apiGetTaskName = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_task/ups_task/${id}`);
};

export const apiEditTaskData = (
  id: string,
  body: any
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.patch(`/ups_task/ups_task/${id}`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiDeleteTask = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.delete(`/ups_task/ups_task/${id}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiCreateTask = (body: any): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.post(`/ups_task/ups_task/`, body, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
