import { TextField, Modal } from "@mui/material";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { NotePublishType } from "shared/Enums";

import styles from "./VisitsSelectField.module.scss";
import { NameIdListI } from "@interfaces";
import axios from "axios";
import { toast } from "react-toastify";
import { getVisitsNameIdList } from "shared/Services";
import { UserDataContext } from "shared/Contexts";

export const VisitsSelectField = ({
  publishSelected,
  formik,
  setPublishSelected,
}: {
  publishSelected: {
    selectedType: NotePublishType;
    item: null | NameIdListI;
  };
  formik: any;
  setPublishSelected: (data: {
    selectedType: NotePublishType;
    item: null | NameIdListI;
  }) => void;
}) => {
  const { currentUser } = useContext(UserDataContext);
  const [visitsList, setVisitsList] = useState<NameIdListI[]>([]);
  const [visitsOpened, setVisitsOpened] = useState(false);

  const [visitsHasMore, setVisitsHasMore] = useState(true);
  const [visitPageNumber, setVisitsPageNumber] = useState(0);
  const pageSize = 20;

  useEffect(() => {
    getVisitsNameIdList(
      visitPageNumber * pageSize,
      pageSize,
      currentUser!.timezone.value,
      currentUser!.uid
    ).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(
          data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        setVisitsList([...visitsList, ...data.items]);
        setVisitsHasMore(data.hasMore);
      }
    });
  }, [visitPageNumber]);

  return (
    <>
      <TextField
        size="medium"
        classes={{ root: styles["up-form-visit"] }}
        label="Visit"
        variant="outlined"
        hiddenLabel={true}
        fullWidth={true}
        multiline
        onClick={() => setVisitsOpened(true)}
        InputProps={{
          classes: { root: styles["up-form-visit-input"] },
        }}
        id="visit"
        name="visit"
        value={
          publishSelected.selectedType === NotePublishType.visit
            ? publishSelected.item?.title
            : ""
        }
        error={formik.touched.visit && Boolean(formik.errors.visit)}
        helperText={formik.touched.visit && formik.errors.visit}
      />
      <Modal open={visitsOpened} onClose={() => {}}>
        <div className={styles["up-modal"]}>
          <FormsHeader
            title="Pick Visit"
            position="relative"
            onCancelToggle={() => setVisitsOpened(false)}
            onRightBtnToggle={() => setVisitsOpened(false)}
          />

          <InfiniteScroll
            dataLength={visitsList.length}
            next={() => setVisitsPageNumber(visitPageNumber + 1)}
            hasMore={visitsHasMore}
            height={"calc(100vh - 3.125rem)"}
            loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
          >
            {visitsList.length ? (
              visitsList.map((item) => (
                <div
                  key={item.id}
                  className={`${styles["up-publish-visits"]} ${
                    publishSelected.selectedType === NotePublishType.visit &&
                    publishSelected.item?.id === item.id
                      ? styles["active"]
                      : ""
                  }`}
                  onClick={() => {
                    formik.setFieldValue("visit", item.id);
                    formik.setFieldValue("location", item.locationId);
                    setPublishSelected({
                      selectedType: NotePublishType.visit,
                      item: item,
                    });
                    setVisitsOpened(false);
                  }}
                >
                  {item.title}
                </div>
              ))
            ) : (
              <div className="up-no-items">No active visit records found</div>
            )}
          </InfiniteScroll>
        </div>
      </Modal>
    </>
  );
};
