import axios, { AxiosError } from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpRouter from "components/Router";
import LoadingSplash from "components/Shared/LoadingSplash/LoadingSplash";
import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { UserDataContextI } from "shared/Contexts/UserDataContext";
import {
  getLoginStatus,
  getUserSettings,
  getSessionToken,
  getUserInfo,
} from "shared/Services";
import { RoutingSettingsI } from "@interfaces";
import Layout from "../../Layout/Layout";
import Auth from "../Auth/Auth";
import { oneTimeLogin } from "shared/Services/Auth.service";
import Header from "components/Shared/Header/Header";
import { Roles } from "shared/Enums";
import { Moment } from "moment";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserDataContextI | null>(null);

  const login = (userData: UserDataContextI) => {
    setIsLoading(true);
    check(userData);
  };
  const logout = () => {
    setIsLoading(true);
    check();
  };

  const check = (userData?: UserDataContextI) => {
    getLoginStatus().then((i) => {
      if (axios.isAxiosError(i)) {
        setIsLoading(false);
        setError(true);
        return;
      }
      getSessionToken().then(() => {
        let userInfo = userData as UserDataContextI | null;
        if (!!i.data) {
          if (!userInfo) {
            const userDataFromCookie = localStorage.getItem("current_user")!;
            userInfo = JSON.parse(userDataFromCookie) as UserDataContextI;
          }
          getUserInfo().then((info) => {
            if (axios.isAxiosError(info)) {
              toast.error(
                info.response?.data?.errors
                  ? info.response?.data?.errors[0]?.detail
                  : "See App.tsx 51"
              );
              return;
            }
            const permissions: any[] = [];
            info.data.roles.map((i: { [key: string]: string[] }) => {
              permissions.push(...Object.values(Object.values(i)[0]));
            });
            const roles: Roles[] = [];
            info.data.roles.map((i: any) => roles.push(...(Object.keys(i) as Roles[])));
            userInfo = {
              ...userInfo!,
              uid: info.data.uuid.value,
              permissions,
              agentProfiles:
                info.data.agent_profiles.length || info.data.agent_profiles.target_id,
              roles,
              timezone: info.data.timezone,
              mute_notifications_until: info.data.mute_notifications_until?.value,
            };

            getUserSettings(info.data.uuid.value).then(
              (
                settings:
                  | AxiosError
                  | {
                      settings: RoutingSettingsI;
                      workingDays: number[];
                      vacations: Moment[];
                      fullName: string;
                    }
              ) => {
                if (axios.isAxiosError(settings)) {
                  toast.error((settings.response?.data as any)?.errors[0]?.detail);
                } else {
                  userInfo!.routerSettings = settings.settings;
                  userInfo!.workingDays = settings.workingDays;
                  userInfo!.vacations = settings.vacations;
                  userInfo!.fullName = settings.fullName;
                  setCurrentUser(userInfo);
                  setIsLoading(false);
                }
              }
            );
          });
        }

        if (!i.data) {
          setCurrentUser(null);
          localStorage.removeItem("csrf_token");
          localStorage.removeItem("current_user");
          localStorage.removeItem("session_token");
          setIsLoading(false);
        }
      });
    });
  };

  useEffect(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(window.location.search));
    if (
      window.location.pathname === "/" &&
      searchParams.uid &&
      searchParams.timestamp &&
      searchParams.hash
    ) {
      oneTimeLogin({
        ...(searchParams as {
          uid: string;
          timestamp: string;
          hash: string;
        }),
      }).then((resp) => {
        if (axios.isAxiosError(resp)) {
          toast.error(resp?.response?.data.message || "Request failed.");
          check();
        } else {
          if (resp.data.error) {
            toast.error(resp.data.error);
          }

          resp.data.csrf_token &&
            localStorage.setItem("csrf_token", resp.data.csrf_token);
          if (resp.data.current_user) {
            localStorage.setItem("current_user", JSON.stringify(resp.data.current_user));
            login(resp.data?.current_user);
          } else {
            check();
          }
        }
      });
    } else {
      check();
    }
  }, []);

  return (
    <UserDataContext.Provider value={{ currentUser, setCurrentUser, login, logout }}>
      <LoadingSplash isLoading={isLoading}>
        <Router>
          {error ? (
            <>
              <Header
                title={""}
                hideBack={true}
                hideReload={true}
                toggleBack={() => {}}
              />
              <div style={{ paddingTop: "7rem", textAlign: "center" }}>
                Sorry, something went wrong
              </div>
            </>
          ) : (
            <>
              {!!currentUser ? (
                <Layout>
                  <UpRouter />
                </Layout>
              ) : (
                <Auth />
              )}

              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </>
          )}
        </Router>
      </LoadingSplash>
    </UserDataContext.Provider>
  );
}

export default App;
