import { ProfileI } from "@interfaces";
import { AxiosError } from "axios";
import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { UserDataContext } from "shared/Contexts";
import { formatVacations } from "shared/helpers/profile.helper";
import { getProfileData } from "shared/Services";

export const useProfileData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [profileInfo, setProfileInfo] = useState<null | ProfileI>(null);
  const { currentUser, setCurrentUser } = useContext(UserDataContext);
  const [error, setError] = useState<AxiosError<any>>();

  useEffect(() => {
    (async () => {
      try {
        const data = await getProfileData(currentUser!.uid, currentUser!.timezone);
        setProfileInfo(data);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(
        error.response?.data?.errors?.length
          ? error.response?.data?.errors[0]?.detail
          : error.message
      );
      throw error;
    }
  }, [error]);

  const updateProfile = (data: ProfileI) => {
    setProfileInfo({ ...data });
    setCurrentUser({
      ...currentUser!,
      workingDays: data.agentProfile?.workingHours.map((i) => i.day),
      vacations: formatVacations(
        data.agentProfile?.specialDays.map((i) => ({
          start_date: i.date.from,
          end_date: i.date.to,
          title: i.title,
          working_hours_end_time: i.workingHours?.to,
          working_hours_start_time: i.workingHours?.from,
        })) || []
      ),
    });
  };

  return { isLoading, profileInfo, updateProfile };
};
