import axios from "axios";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";
import { CountriesStatesContext, UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import {
  formatAddressToString,
  isUserHavePermissions,
} from "shared/helpers/common.helper";
import { checkDate12AMto00 } from "shared/helpers/time.helper";
import { getLocationName } from "shared/Services";

import { LocationNameI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { Fab, InputAdornment, TextField } from "@mui/material";

import styles from "./NotesView.module.scss";
import { toast } from "react-toastify";
import { useNotesData } from "./hooks/useNotesData";

function NotesView() {
  const { currentUser } = useContext(UserDataContext);
  const { countriesStates } = useContext(CountriesStatesContext);
  const params = useParams();

  const [locationInfo, setLocationInfo] = useState<LocationNameI | null>(null);

  const navigate = useNavigate();
  const { content, setSearchText, hasMore, isLoading, reload, setPageNumber } =
    useNotesData(params.locationId);

  useEffect(() => {
    params.locationId &&
      getLocationName(params.locationId).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          setLocationInfo(data);
        }
      });
  }, []);

  return (
    <div className={styles["up-container"]}>
      <Header
        title={"Location Notes"}
        toggleBack={() => navigate(-1)}
        toggleReload={reload}
      />
      {locationInfo && (
        <>
          <div className={styles["up-location-name"]}>{locationInfo?.title}</div>
          <div className={styles["up-location-address"]}>
            {formatAddressToString(locationInfo.address, countriesStates)}
          </div>
        </>
      )}
      <TextField
        hiddenLabel={true}
        fullWidth={true}
        onChange={(e) => setSearchText(e.target.value)}
        InputProps={{
          classes: {
            root: styles["up-search-root"],
            input: styles["up-search-input"],
          },
          endAdornment: (
            <InputAdornment position={"end"} classes={{ root: styles["up-search-icon"] }}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant={"filled"}
        placeholder={"Search"}
      />

      <LoadingSpinner isLoading={isLoading} height={300}>
        <div className={styles["up-content"]}>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={content.length}
            next={() => setPageNumber((prev) => prev + 1)}
            hasMore={hasMore}
            loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
          >
            {content.length ? (
              content.map((i) => (
                <div
                  className={styles["up-content-card"]}
                  key={i.id}
                  onClick={() => navigate(`/notes/${i.id}`)}
                >
                  <div className={styles["up-content-card-header"]}>
                    {i.task && (
                      <span className={styles["up-content-card-header-task"]}>
                        {i.task}
                      </span>
                    )}
                    <span className={styles["up-content-card-header-date"]}>
                      {checkDate12AMto00(i.date, currentUser!.timezone.value)}
                    </span>
                  </div>
                  <p className={`${styles["content"]} up-truncate-6`}>{i.content}</p>
                  <div className={styles["up-content-card-footer"]}>
                    <span className={styles["up-content-card-footer-author"]}>
                      {i.author}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="up-no-items">No note records found</div>
            )}
          </InfiniteScroll>
        </div>
      </LoadingSpinner>

      {isUserHavePermissions(currentUser!, [PermissionsList.createNote]) && (
        <Fab
          aria-label={"add note"}
          color={"inherit"}
          classes={{ root: styles["up-fab-root"] }}
          onClick={() =>
            params.locationId
              ? navigate(`/notes/create/locations/${params.locationId}`)
              : navigate("/notes/create")
          }
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
}

export default NotesView;
