import axios, { AxiosError } from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { editNoteData } from "shared/Services";

import { NoteFormI } from "@interfaces";

import styles from "./NoteEdit.module.scss";
import { toast } from "react-toastify";
import { NotesForm } from "components/Shared/Notes/NotesForm/NotesForm";
import { useNoteData } from "./hooks/useNoteData";

function NoteEdit() {
  const navigate = useNavigate();
  const params = useParams();

  const [submitted, setSubmitted] = useState(0);

  const { noteInfo, loading, locationId, taskId, visitId } = useNoteData(params.noteId);
  const FormSubmit = (values: NoteFormI) => {
    editNoteData(params.noteId!, values).then((data: AxiosError | any) => {
      if (axios.isAxiosError(data)) {
        toast.error(
          data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        if (data.data.validation) {
          toast.warning(data.data.validation.message);
        }
        toast.success("Successfully completed!");
        navigate(`/notes/${params.noteId}`);
      }
    });
  };

  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Edit note"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        position={"absolute"}
        background="transparent"
        onCancelToggle={() => navigate(-1)}
      />
      <LoadingSpinner isLoading={loading} height={300}>
        <>
          {noteInfo && (
            <NotesForm
              submitted={submitted}
              formFinished={FormSubmit}
              formValue={noteInfo}
              locationId={locationId}
              taskId={taskId}
              visitId={visitId}
            />
          )}
        </>
      </LoadingSpinner>
    </div>
  );
}

export default NoteEdit;
