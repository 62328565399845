import { LocationViewI } from "@interfaces";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserDataContextI } from "shared/Contexts/UserDataContext";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { getLocationViewData } from "shared/Services";

export function useLocationInfo(locationId: string, currentUser: UserDataContextI) {
  const [locationInfo, setLocationInfo] = useState<LocationViewI>();

  const [error, setError] = useState<AxiosError<any>>();
  const [loading, setLoading] = useState(true);

  const getContent = async () => {
    setLoading(true);
    try {
      if (locationId) {
        const data = await getLocationViewData(locationId, currentUser?.timezone.offset, {
          userIdForTask:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
            !isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceTask]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewOwnTask])
              ? currentUser!.uid
              : undefined,
          userIdForNotes:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
            !isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceNote]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewOwnNote])
              ? currentUser!.uid
              : undefined,
        });
        setLocationInfo(data);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(
        error.response?.data?.errors?.length
          ? error.response?.data?.errors[0]?.detail
          : error.message
      );
      throw error;
    }
  }, [error]);

  return {
    locationInfo,
    loading,
    getContent,
  };
}
