import {
  formatTimeStringToDate,
  formatVacations,
  MessengersKeys,
} from "shared/helpers/profile.helper";
import {
  formatMomentToAMPM,
  formatNumberToString,
  formatTimeStringToNumber,
} from "shared/helpers/time.helper";

import {
  ProfileFormI,
  ProfileI,
  ResponseDto,
  RoutingSettingsI,
  SpecialDaysDto,
  TimeReservationDto,
  WorkingHoursDto,
  WorkingHoursItemI,
} from "@interfaces";

import {
  apiAddAvatar,
  apiCreateProfileAgent,
  apiEditProfileAgent,
  apiEditProfileData,
  apiGetAgentProfile,
  apiGetUserProfile,
  apiChangePassword,
} from "./Api/Profile.api.service";
import { Moment } from "moment";

export const getProfileData = (id: string, timezone: any): Promise<ProfileI> => {
  return apiGetUserProfile(id).then((response) => {
    const userData = response.data.data;
    const userAgent: ResponseDto<any> = response.data.included?.find(
      (i: ResponseDto<any>) => i.type === "profile--agent"
    );
    const userAvatar: ResponseDto<any> = response.data.included?.find(
      (i: ResponseDto<any>) => i.type === "file--file"
    );

    const dayoffs = userAgent
      ? [...userAgent.attributes.field_working_hours.map((i: { day: string }) => i.day)]
      : [];

    return {
      id: userData.id,
      name: `${userData.attributes.display_name}`,
      avatar: userAvatar
        ? {
            url: userAvatar.attributes.uri.url,
            id: userAvatar.id,
          }
        : null,
      phoneNumber: userData.attributes.field_telephone
        ? [
            userData.attributes.field_telephone,
            ...(userData.attributes.field_additional_telephones || []),
          ]
        : [...(userData.attributes.field_additional_telephones || [])],
      email: [userData.attributes.display_name],
      agentProfile: userAgent
        ? {
            id: userAgent.id,
            address: {
              address_line: userAgent.attributes.field_address.address_line,
              city: userAgent.attributes.field_address.city,
              country_code: userAgent.attributes.field_address.country_code,
              postal_code: userAgent.attributes.field_address.postal_code,
              state: userAgent.attributes.field_address.state,
            },
            workingHours: [0, 1, 2, 3, 4, 5, 6].map((day: number) => {
              const timeItem = userAgent.attributes.field_working_hours?.find(
                (i: WorkingHoursDto) => i.day === day
              );
              const newObj: WorkingHoursItemI = {
                checked: !!timeItem || false,
                from: timeItem ? formatNumberToString(timeItem.starthours) : "00:00 AM",
                to: timeItem ? formatNumberToString(timeItem.endhours) : "00:00 AM",
                day: day,
              };
              return newObj;
            }),
            breaks: {
              checked: !!userAgent.attributes.field_break_hours,
              from: userAgent.attributes.field_break_hours?.value
                ? formatMomentToAMPM(
                    userAgent.attributes.field_break_hours.value,
                    timezone.value
                  )
                : "00:00 AM",
              to: userAgent.attributes.field_break_hours?.end_value
                ? formatMomentToAMPM(
                    userAgent.attributes.field_break_hours.end_value,
                    timezone.value
                  )
                : "00:00 PM",
              day: 8,
            },

            DayOffs: [0, 1, 2, 3, 4, 5, 6].filter((i) => !dayoffs.includes(i)),
            allLinks: userAgent.attributes.field_link.map((i: { uri: string }) => i.uri),
            links: (
              userAgent.attributes.field_link.map((i: { uri: string }) => i.uri) || []
            ).filter((str: string) => {
              for (const key of MessengersKeys) {
                if (str.includes(key)) {
                  return false;
                }
              }
              return true;
            }),

            messengers: (
              userAgent.attributes.field_link.map((i: { uri: string }) => i.uri) || []
            ).filter((str: string) => {
              for (const key of MessengersKeys) {
                if (str.includes(key)) {
                  return true;
                }
              }
              return false;
            }),
            specialDays: userAgent.attributes.field_special_working_hours?.map(
              (i: any, index: number) => {
                return {
                  date: {
                    from: i.start_date,
                    to: i.end_date,
                  },
                  title: i.title,
                  break: {
                    from: i.break_hours_start_time,
                    to: i.break_hours_end_time,
                  },
                  workingHours: {
                    from: i.working_hours_start_time,
                    to: i.working_hours_end_time,
                  },
                  id: index.toString(),
                };
              }
            ),
            timeReservation:
              userAgent.attributes.field_time_reservations?.map(
                (i: any, index: number) => {
                  return {
                    ...i,
                    id: index.toString(),
                  };
                }
              ) || [],
            geolocation: userAgent.attributes.field_location_point,
          }
        : undefined,
    } as ProfileI;
  });
};

export const editProfileData = (id: string, timezone: any, body: ProfileFormI) => {
  if (body.avatar?.arrayBuffer) {
    return apiAddAvatar(id, body.avatar.arrayBuffer)
      .then(() => {
        editProfileDataWithAvatar(id, timezone, body);
      })
      .catch((err) => err);
  } else if (!body.avatar) {
    return editProfileDataWithAvatar(id, timezone, body, true);
  }
  return editProfileDataWithAvatar(id, timezone, body);
};

const editProfileDataWithAvatar = (
  id: string,
  timezone: any,
  body: ProfileFormI,
  deleteAvatar?: boolean
) => {
  const breakHours = body.workingHours?.find((i) => i.day === 8 && i.checked);
  if (!body.agentProfileId && body.isUserAgent) {
    return apiCreateProfileAgent({
      data: {
        type: "profile--agent",
        id: body.agentProfileId,
        attributes: {
          field_address: {
            address_line: body.address.address_line,
            country_code: body.address.country_code,
            postal_code: body.address.postal_code,
            state: body.address.state,
            city: body.address.city,
          },
          field_location_point: body.geolocation,
          field_working_hours: body.workingHours
            .filter((i) => i.checked && i.day !== 8)
            .map((i) => {
              return {
                comment: "",
                day: i.day,
                starthours: formatTimeStringToNumber(i.from),
                endhours: formatTimeStringToNumber(i.to),
              };
            }),
          field_break_hours: breakHours
            ? {
                value: formatTimeStringToDate(breakHours.from, timezone.value),
                end_value: formatTimeStringToDate(breakHours.to, timezone.value),
              }
            : null,
          field_link: body.links.map((i) => {
            return {
              options: [],
              title: "",
              uri: i,
            };
          }),
          field_test_mode: body.isTest,
          field_location_point_test: body.testGeolocation,
          field_current_time_test: body.testTime,
        },
      },
    })
      .then((i) => {
        return editUserProfileData(
          id,
          body,
          timezone,
          breakHours,
          deleteAvatar || false,
          i.data.data.id
        );
      })
      .catch((err) => err);
  } else {
    return editUserProfileData(id, body, timezone, breakHours, deleteAvatar || false);
  }
};

const editUserProfileData = (
  id: string,
  body: ProfileFormI,
  timezone: any,
  breakHours: any,
  deleteAvatar: boolean,
  attachAgentProfileId?: string
) => {
  const fieldAvatar = deleteAvatar
    ? {
        field_avatar: {
          data: null,
        },
      }
    : {};
  const fieldAgentProfile = attachAgentProfileId
    ? {
        agent_profiles: {
          data: {
            type: "profile--agent",
            id: attachAgentProfileId,
          },
        },
      }
    : {};

  const relationships =
    deleteAvatar || attachAgentProfileId
      ? {
          relationships: {
            ...fieldAvatar,
            ...fieldAgentProfile,
          },
        }
      : {};

  return apiEditProfileData(id, {
    data: {
      type: "user--user",
      id: id,
      attributes: {
        field_full_name: body.name,
        field_telephone: body.phones[0],
        field_additional_telephones: body.phones.filter((_, index) => index !== 0),
      },
      ...relationships,
    },
  })
    .then(() => {
      if (!attachAgentProfileId && body.agentProfileId) {
        return apiEditProfileAgent(body.agentProfileId, {
          data: {
            type: "profile--agent",
            id: body.agentProfileId,
            attributes: {
              field_address: {
                address_line: body.address?.address_line,
                country_code: body.address?.country_code,
                postal_code: body.address?.postal_code,
                state: body.address?.state,
                city: body.address?.city,
              },
              field_location_point: body.geolocation,
              field_working_hours: body.workingHours
                .filter((i) => i.checked && i.day !== 8)
                .map((i) => {
                  return {
                    comment: "",
                    day: i.day,
                    starthours: formatTimeStringToNumber(i.from),
                    endhours: formatTimeStringToNumber(i.to),
                  };
                }),
              field_break_hours: breakHours
                ? {
                    value: formatTimeStringToDate(breakHours.from, timezone.value),
                    end_value: formatTimeStringToDate(breakHours.to, timezone.value),
                  }
                : null,
              field_link: body.links.map((i) => {
                return {
                  options: [],
                  title: "",
                  uri: i,
                };
              }),
              field_test_mode: body.isTest,
              field_location_point_test: body.testGeolocation,
              field_current_time_test: body.testTime,
            },
          },
        }).catch((err) => err);
      }
    })
    .catch((err) => err);
};

export const getSpecialWorkingHours = (
  id: string
): Promise<{ id: string; days: SpecialDaysDto[] }> => {
  return apiGetAgentProfile(id)
    .then((response) => {
      const userAgent: ResponseDto<any> = response.data.included?.find(
        (i: ResponseDto<any>) => i.type === "profile--agent"
      );
      return {
        id: userAgent.id,
        days: (userAgent.attributes.field_special_working_hours ||
          []) as SpecialDaysDto[],
      };
    })
    .catch((err) => err);
};
export const getTimeReservations = (
  id: string
): Promise<{ id: string; days: TimeReservationDto[] }> => {
  return apiGetAgentProfile(id)
    .then((response) => {
      const userAgent: ResponseDto<any> = response.data.included?.find(
        (i: ResponseDto<any>) => i.type === "profile--agent"
      );
      return {
        id: userAgent.id,
        days: (userAgent.attributes.field_time_reservations ||
          []) as TimeReservationDto[],
      };
    })
    .catch((err) => err);
};
export const editSpecialWorkingHours = (
  id: string,
  body: SpecialDaysDto[]
): Promise<any> => {
  return apiEditProfileAgent(id, {
    data: {
      type: "profile--agent",
      id: id,
      attributes: {
        field_special_working_hours: body,
      },
    },
  })
    .then((i) => i.data.data)
    .catch((err) => err);
};

export const editTimeReservation = (
  id: string,
  body: TimeReservationDto[]
): Promise<any> => {
  return apiEditProfileAgent(id, {
    data: {
      type: "profile--agent",
      id: id,
      attributes: {
        field_time_reservations: body,
      },
    },
  }).catch((err) => err);
};

export const getUserSettings = (id: string): Promise<any> => {
  return apiGetAgentProfile(id)
    .then((response) => {
      const userAgent: ResponseDto<any> = response.data.included?.find(
        (i: ResponseDto<any>) => i.type === "profile--agent"
      );
      return {
        fullName: response.data.data?.attributes.field_full_name,
        settings: {
          id: userAgent?.id,
          avoidFerries: userAgent?.attributes.field_map_avoid_ferries,
          avoidHighways: userAgent?.attributes.field_map_avoid_highways,
          avoidTolls: userAgent?.attributes.field_map_avoid_tolls,
          trafficModel: userAgent?.attributes.field_map_traffic_model,
          unitSystem: userAgent?.attributes.field_map_unit_system,
          testMode: {
            isTest: userAgent?.attributes.field_test_mode,
            points: userAgent?.attributes.field_location_point_test,
            time: userAgent?.attributes.field_current_time_test,
          },
        },
        workingDays: userAgent?.attributes.field_working_hours.map(
          (i: WorkingHoursDto) => i.day
        ),
        vacations: formatVacations(
          userAgent?.attributes.field_special_working_hours || []
        ),
      } as { settings: RoutingSettingsI; workingDays: number[]; vacations: Moment[] };
    })
    .catch((err) => err);
};

export const saveRoutingSettings = (id: string, body: RoutingSettingsI): Promise<any> => {
  return apiEditProfileAgent(id, {
    data: {
      type: "profile--agent",
      id: id,
      attributes: {
        field_map_avoid_ferries: body.avoidFerries,
        field_map_avoid_highways: body.avoidHighways,
        field_map_avoid_tolls: body.avoidTolls,
        field_map_traffic_model: body.trafficModel,
        field_map_unit_system: body.unitSystem,
      },
    },
  }).catch((err) => err);
};

export const changePassword = ({
  currentPass,
  newPass,
}: {
  currentPass: string;
  newPass: string;
}): Promise<any> => {
  return apiChangePassword({
    current_pass: currentPass,
    new_pass: newPass,
  }).catch((err) => err);
};
