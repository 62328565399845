import { NotePublishType } from "shared/Enums";

import { AllNotesViewI, NoteCardI, NoteFormI, NoteViewI, ResponseDto } from "@interfaces";

import { apiGetLocationsForNotes } from "./Api/Locations.api.service";
import {
  apiCreateNote,
  apiDeleteNote,
  apiEditNoteData,
  apiGetNoteData,
  apiGetNotesCount,
  apiGetNotesList,
} from "./Api/Notes.api.service";

export const getAllNotesList = (
  pageNumber: number,
  params?: {
    filterByAgents?: string;
    filterByUser?: string;
  }
) => {
  return apiGetLocationsForNotes(pageNumber, params).then((locations) => {
    const ids = locations.data.data.map((i: any) => i.id);
    if (ids.length) {
      return apiGetNotesCount(ids).then((notesInfo) => {
        return {
          hasMore: !(locations.data.data?.length < 20),
          content: locations.data.data.map((location: any) => {
            return {
              locationName: location.attributes.label,
              locationId: location.id,
              lastNote: notesInfo.data[location.id].lastNote,
              count: notesInfo.data[location.id].notesCount,
            } as AllNotesViewI;
          }),
        };
      });
    } else {
      return {
        hasMore: false,
        content: [],
      };
    }
  });
};

export const getNotesList = (
  pageNumber: number,
  searchText: string,
  params?: { userId?: string; locationId?: string; serviceId?: string }
) => {
  return apiGetNotesList(pageNumber, searchText, params).then((notes) => {
    return {
      hasMore: !(notes.data.data?.length < 20),
      content: notes.data.data.map((note: any) => {
        const task = notes.data.included?.find(
          (i: ResponseDto<any>) => i.type === "ups_task--ups_task"
        );
        const author = notes.data.included?.find(
          (i: ResponseDto<any>) => i.type === "user--user"
        );
        const location = notes.data.included?.find(
          (i: ResponseDto<any>) => i.type === "ups_location--ups_location"
        );
        return {
          task: task?.attributes.label,
          location: location?.attributes.label,
          content: note.attributes.note.value,
          date: note.attributes.created,
          author: author?.attributes.display_name,
          id: note.id,
        } as NoteCardI;
      }),
    };
  });
};

export const createNote = (body: NoteFormI) => {
  return apiCreateNote({
    data: {
      type: "ups_note--ups_note",
      attributes: {
        note: body.note,
      },
      relationships:
        body.selectedType === NotePublishType.task
          ? {
              location: {
                data: {
                  type: "ups_location--ups_location",
                  id: body.item.locationId,
                },
              },
              task: {
                data: {
                  type: "ups_task--ups_task",
                  id: body.item.id,
                },
              },
              visit: {
                data: null,
              },
            }
          : body.selectedType === NotePublishType.visit
            ? {
                location: {
                  data: {
                    type: "ups_location--ups_location",
                    id: body.item.locationId,
                  },
                },
                task: {
                  data: null,
                },
                visit: {
                  data: {
                    type: "ups_visit--ups_visit",
                    id: body.item.id,
                  },
                },
              }
            : {
                location: {
                  data: {
                    type: "ups_location--ups_location",
                    id: body.item.id,
                  },
                },
                task: {
                  data: null,
                },
                visit: {
                  data: null,
                },
              },
    },
  })
    .then((response) => {
      return response.data.data.id;
    })
    .catch((err) => err);
};

export const getNoteData = (id: string) => {
  return apiGetNoteData(id).then((response) => {
    const location = response.data.included?.find(
      (i: ResponseDto<any>) => i.type === "ups_location--ups_location"
    );
    const task = response.data.included?.find(
      (i: ResponseDto<any>) => i.type === "ups_task--ups_task"
    );
    const author = response.data.included?.find(
      (i: ResponseDto<any>) => i.type === "user--user"
    );
    const visit = response.data.included?.find(
      (i: ResponseDto<any>) => i.type === "ups_visit--ups_visit"
    );
    return {
      location: {
        title: location?.attributes.label,
        id: location?.id,
      },
      task: {
        title: task?.attributes.label,
        id: task?.id,
        locationId: task?.relationships.location.data.id,
      },
      visit: {
        id: visit?.id,
        title: `Visit to ${location?.attributes.label}`,
        locationId: location?.id,
      },
      author: {
        name: author?.attributes.display_name,
        id: author?.id,
      },
      content: response.data.data.attributes.note.value,
    } as NoteViewI;
  });
};

export const editNoteData = (id: string, body: NoteFormI) => {
  return apiEditNoteData(id, {
    data: {
      type: "ups_note--ups_note",
      id: id,
      attributes: {
        note: body.note,
      },

      relationships:
        body.selectedType === NotePublishType.task
          ? {
              location: {
                data: {
                  type: "ups_location--ups_location",
                  id: body.item.locationId,
                },
              },
              task: {
                data: {
                  type: "ups_task--ups_task",
                  id: body.item.id,
                },
              },
              visit: {
                data: null,
              },
            }
          : body.selectedType === NotePublishType.visit
            ? {
                location: {
                  data: {
                    type: "ups_location--ups_location",
                    id: body.item.locationId,
                  },
                },
                task: {
                  data: null,
                },
                visit: {
                  data: {
                    type: "ups_visit--ups_visit",
                    id: body.item.id,
                  },
                },
              }
            : {
                location: {
                  data: {
                    type: "ups_location--ups_location",
                    id: body.item.id,
                  },
                },
                task: {
                  data: null,
                },
                visit: {
                  data: null,
                },
              },
    },
  }).catch((err) => err);
};

export const deleteNote = (id: string) => {
  return apiDeleteNote(id).catch((err) => err);
};
