import axios, { AxiosError } from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import ProfileForm from "components/Shared/Profile/ProfileForm/ProfileForm";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { checkDateValidation, isUserHavePermissions } from "shared/helpers/common.helper";
import { WeekDays } from "shared/helpers/profile.helper";
import { editProfileData } from "shared/Services";

import { ProfileFormI } from "@interfaces";
import { toast } from "react-toastify";
import { useProfileData } from "../hooks/useProfileData";

function EditMyProfile() {
  const { currentUser, setCurrentUser } = useContext(UserDataContext);
  const [submitted, setSubmitted] = useState(0);
  const navigate = useNavigate();

  const { isLoading, profileInfo } = useProfileData();

  const FormSubmit = (value: ProfileFormI) => {
    let error = {
      val: false,
      day: 0,
    };
    if (value.workingHours) {
      value.workingHours.map((i) => {
        if (i.checked && !checkDateValidation(i.from, i.to)) {
          error = {
            val: true,
            day: i.day,
          };
        }
      });
    }
    if (error.val) {
      toast.error(
        `${error.day === 8 ? "Break" : WeekDays[error.day] + "'s working hours"} end time can't be earlier than start time`
      );
    } else {
      editProfileData(profileInfo!.id, currentUser!.timezone, value).then(
        (data: AxiosError | any) => {
          if (axios.isAxiosError(data)) {
            toast.error(
              data.response?.data?.errors?.length
                ? data.response?.data?.errors[0]?.detail
                : ""
            );
          } else {
            toast.success("Successfully completed!");
            if (
              isUserHavePermissions(currentUser!, [PermissionsList.administerTesting])
            ) {
              setCurrentUser({
                ...currentUser!,
                routerSettings: {
                  ...(currentUser!.routerSettings || {}),
                  testMode: {
                    isTest: value.isTest || false,
                    points: value.testGeolocation,
                    time: value.testTime,
                  },
                },
              });
            }
            navigate(`/profile`);
          }
        }
      );
    }
  };

  return (
    <>
      <FormsHeader
        title="Edit Profile"
        rightBtnText="Save"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        position={"absolute"}
        background={"transparent"}
        disableNext={isLoading}
        onCancelToggle={() => navigate(-1)}
      />
      <LoadingSpinner isLoading={isLoading} height={300}>
        {profileInfo ? (
          <ProfileForm
            isAgent={currentUser!.roles.includes("agent")}
            profileInfo={profileInfo}
            submitted={submitted}
            formFinished={FormSubmit}
          ></ProfileForm>
        ) : (
          <></>
        )}
      </LoadingSpinner>
    </>
  );
}

export default EditMyProfile;
