import { LocationViewI, ResourceI, ResourcesListI } from "@interfaces";
import ViewResources from "components/Pages/Visits/Resources/Resources";
import NotesCards from "components/Shared/Notes/NotesCards/NotesCards";
import TasksCards from "components/Shared/Tasks/TasksCards/TasksCards";
import { useContext, useState } from "react";
import { UserDataContext } from "shared/Contexts";
import { DayScheduleStepsStatusesNew, PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import Tab from "@mui/material/Tab";

import styles from "./LocationInfoTubs.module.scss";
import { Tabs } from "@mui/material";

type TabContent = "notes" | "tasks" | "resources";

interface TabPanelProps {
  children?: React.ReactNode;
  tabKey: TabContent;
  value: TabContent;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, tabKey, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== tabKey}
      id={`simple-tabpanel-${tabKey}`}
      aria-labelledby={`simple-tab-${tabKey}`}
      {...other}
    >
      {value === tabKey && children}
    </div>
  );
}

export const LocationInfoTubs = ({
  locationInfo,
  visit,
  content,
  canAdd,
  scrollData,
  hasError,
}: {
  locationInfo: LocationViewI;
  visit?: {
    id: string;
    status: DayScheduleStepsStatusesNew;
  };
  content: ResourcesListI;
  visitId?: string;
  canAdd: boolean;
  scrollData?: {
    items: ResourceI[];
    setPageNumber: (action: number) => void;
    pageNumber: number;
    hasMore: boolean;
  };
  hasError?: boolean;
}) => {
  const { currentUser } = useContext(UserDataContext);

  const hasNotePermissions = isUserHavePermissions(currentUser!, [
    PermissionsList.viewAnyNote,
    PermissionsList.viewOwnNote,
    PermissionsList.viewMyServiceNote,
  ]);

  const hasTaskPermissions = isUserHavePermissions(currentUser!, [
    PermissionsList.viewAnyTask,
    PermissionsList.viewOwnTask,
    PermissionsList.viewMyServiceTask,
  ]);

  const [value, setValue] = useState<TabContent>(
    hasNotePermissions ? "notes" : hasTaskPermissions ? "tasks" : "resources"
  );

  const [updatedCount, setUpdatedCount] = useState<number | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: TabContent) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        classes={{ root: styles["up-tabs"] }}
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {hasNotePermissions && (
          <Tab
            classes={{ root: styles["up-tab"] }}
            value="notes"
            label={`Notes (${locationInfo.notes.count})`}
          />
        )}
        {hasTaskPermissions && (
          <Tab
            classes={{ root: styles["up-tab"] }}
            value="tasks"
            label={`Tasks (${locationInfo.tasks.count})`}
          />
        )}
        <Tab
          classes={{ root: styles["up-tab"] }}
          value="resources"
          label={`Resources (${updatedCount !== null ? updatedCount : content.count})`}
        />
      </Tabs>
      {hasNotePermissions && (
        <CustomTabPanel value={value} tabKey={"notes"}>
          <div className={styles["up-notes"]}>
            <NotesCards
              ids={{
                visitId:
                  visit?.status === DayScheduleStepsStatusesNew.inProgress
                    ? visit.id
                    : undefined,
                locationId: locationInfo.id,
              }}
              content={locationInfo.notes}
            ></NotesCards>
          </div>
        </CustomTabPanel>
      )}
      {hasTaskPermissions && (
        <CustomTabPanel value={value} tabKey={"tasks"}>
          <div className={styles["up-tasks"]}>
            <TasksCards location={locationInfo}></TasksCards>
          </div>
        </CustomTabPanel>
      )}
      <CustomTabPanel value={value} tabKey={"resources"}>
        <div className={styles["up-resources"]}>
          <ViewResources
            content={content}
            canAdd={canAdd}
            hasError={hasError}
            scrollData={scrollData}
            visitId={visit?.id}
            updateCount={(e) => setUpdatedCount(e)}
          ></ViewResources>
        </div>
      </CustomTabPanel>
    </div>
  );
};
