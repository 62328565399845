import { LocationNameI, TabsI, TaskCardI } from "@interfaces";
import axios, { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { UserDataContextI } from "shared/Contexts/UserDataContext";
import { PermissionsList, TabsStatuses } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { getTasksList, getTasksTabsInfo, getLocationName } from "shared/Services";
import { useSearchParams } from "react-router-dom";

export const useTasksInfo = (
  currentUser: UserDataContextI,
  locationId: string | undefined
) => {
  const [tasksContent, setTasksContent] = useState<TaskCardI[] | null>(null);
  const [tabsContent, setTabsContent] = useState<TabsI | null>(null);
  const [locationInfo, setLocationInfo] = useState<LocationNameI | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<AxiosError<any>>();
  const [pageNumber, setPageNumber] = useState(0);
  const [searchParams] = useSearchParams();
  const [tabStatus, setTabStatus] = useState<TabsStatuses>(
    (searchParams.get("tab") as TabsStatuses) || TabsStatuses.active
  );

  useEffect(() => {
    getTasksList(tabStatus, pageNumber * 20, locationId, {
      id:
        !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceTask]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewOwnTask])
          ? currentUser!.uid
          : undefined,
      serviceId:
        !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceTask])
          ? currentUser!.uid
          : undefined,
    }).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(
          data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        setIsLoading(false);
        pageNumber > 0 && tasksContent
          ? setTasksContent([...tasksContent, ...data.content])
          : setTasksContent(data.content);
        setHasMore(data.hasMore);
      }
    });
  }, [pageNumber, tabStatus, locationId]);

  const getContent = async () => {
    setHasMore(true);
    setIsLoading(true);
    setPageNumber(0);
    try {
      const data = await getTasksTabsInfo(locationId, {
        id:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceTask]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewOwnTask])
            ? currentUser!.uid
            : undefined,
        serviceId:
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
          isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceTask])
            ? currentUser!.uid
            : undefined,
      });
      setTabsContent(data);
      if (locationId) {
        const locationData = await getLocationName(locationId);
        setLocationInfo(locationData);
      } else {
        setLocationInfo(null);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(
        error.response?.data?.errors?.length
          ? error.response?.data?.errors[0]?.detail
          : error.message
      );
      throw error;
    }
  }, [error]);

  return {
    getContent,
    isLoading,
    tasksContent,
    tabsContent,
    locationInfo,
    hasMore,
    pageNumber,
    setPageNumber,
    tabStatus,
    setTabStatus,
  };
};
