import axios from "axios";
import { getAdminUrl } from "shared/helpers/common.helper";

export const jsonAuthApi = axios.create({
  baseURL: getAdminUrl() + "/jsonapi",
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
  withCredentials: true,
});

export const helperApi = axios.create({
  baseURL: getAdminUrl() + "/api/v1/helper",
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
  withCredentials: true,
});

export const customAuthApi = axios.create({
  baseURL: getAdminUrl() + "",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const jsonApiFile = axios.create({
  baseURL: getAdminUrl() + "/jsonapi",
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/octet-stream",
    "Content-Disposition": 'file; filename="avatar.png"',
  },
  withCredentials: true,
});
