import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { CheckRoutingPermission } from "shared/helpers/common.helper";

import PageNotFound from "../../PageNotFound/PageNotFound";
import AppointmentsCreate from "./Appointments/AppointmentsCreate/AppointmentsCreate";
import AppointmentsEdit from "./Appointments/AppointmentsEdit/AppointmentsEdit";
import ViewCalendar from "./Calendar/ViewCalendar/ViewCalendar";
import RouteStart from "./RouteStart/RouteStart";
import VisitView from "./VisitView/VisitView";
import { ErrorBoundary } from "error";
import { Footer } from "components/Shared/Footer/Footer";

function VisitsRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <>
      <Routes>
        <Route
          path="/calendar"
          element={
            <ErrorBoundary>
              <ViewCalendar />
            </ErrorBoundary>
          }
        />

        {CheckRoutingPermission(currentUser!) && (
          <>
            <Route
              path="/location/:type"
              element={
                <ErrorBoundary>
                  <RouteStart />
                </ErrorBoundary>
              }
            />
            <Route
              path="/location/:type"
              element={
                <ErrorBoundary>
                  <RouteStart />
                </ErrorBoundary>
              }
            />

            <Route
              path="/:visitId"
              element={
                <ErrorBoundary>
                  <VisitView />
                </ErrorBoundary>
              }
            />
            <Route
              path="/create"
              element={
                <ErrorBoundary>
                  <AppointmentsCreate />
                </ErrorBoundary>
              }
            />
            <Route
              path="/:id/edit"
              element={
                <ErrorBoundary>
                  <AppointmentsEdit />
                </ErrorBoundary>
              }
            />

            <Route path={`*`} element={<PageNotFound />} />
          </>
        )}
      </Routes>
      <Footer />
    </>
  );
}

export default VisitsRouter;
