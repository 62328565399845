import { Route, Routes } from "react-router-dom";
import ViewReports from "./ViewReports/ViewReports";
import { ErrorBoundary } from "error";
import { Footer } from "components/Shared/Footer/Footer";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { useContext } from "react";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";

export default function ReportsRouter() {
  const { currentUser } = useContext(UserDataContext);
  return (
    <>
      <Routes>
        {isUserHavePermissions(currentUser!, [PermissionsList.viewReports]) && (
          <Route
            path="/"
            element={
              <ErrorBoundary>
                <ViewReports />
              </ErrorBoundary>
            }
          />
        )}
      </Routes>
      <Footer />
    </>
  );
}
