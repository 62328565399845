import styles from "./AgentProfileNotExist.module.scss";

function AgentProfileNotExist() {
  return (
    <div className={styles["up-warning"]}>
      Your account is missing Agent profile. Ask your manager to address this to enable
      routing functionality.
    </div>
  );
}

export default AgentProfileNotExist;
