import { FormikErrors, FormikTouched, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { CountriesStatesContext } from "shared/Contexts";
import * as Yup from "yup";

import { AddressDto, SelectOptionI } from "@interfaces";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import styles from "./AddressField.module.scss";

function AddressField({
  addressValue,
  toggleValue,
  errors,
  touched,
}: {
  addressValue?: AddressDto;
  toggleValue: (e: AddressDto) => void;
  errors?: FormikErrors<AddressDto>;
  touched?: FormikTouched<AddressDto>;
}) {
  const [countries, setCountries] = useState<SelectOptionI[]>([]);
  const [states, setStates] = useState<SelectOptionI[]>([]);
  const { countriesStates } = useContext(CountriesStatesContext);

  const handleChange = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value);
    toggleValue({ ...formik.values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setCountries(
      Object.keys(countriesStates).map((i) => {
        return {
          name: countriesStates[i].name,
          code: i,
        };
      })
    );
    addressValue?.country_code && GetStates(addressValue.country_code);
  }, []);

  const GetStates = (country_code: string) => {
    setStates(
      countriesStates[country_code].states.map((i) => {
        return {
          name: i.state,
          code: i.state_code,
        };
      })
    );
  };
  const validationSchema = Yup.object().shape({
    address_line: Yup.string(),
    city: Yup.string(),
    country_code: Yup.string(),
    postal_code: Yup.string(),
    state: Yup.string(),
  });

  const formik = useFormik({
    initialValues:
      addressValue ||
      ({
        address_line: "",
        city: "",
        country_code: "",
        postal_code: "",
        state: "",
      } as AddressDto),
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  return (
    <>
      <TextField
        classes={{ root: styles["up-form"] }}
        label="Address"
        variant="outlined"
        hiddenLabel={true}
        fullWidth={true}
        multiline
        InputProps={{
          classes: { root: styles["up-form-input"] },
        }}
        id="address_line"
        name="address_line"
        value={formik.values.address_line}
        onChange={handleChange}
        error={touched?.address_line && Boolean(errors?.address_line)}
        helperText={touched?.address_line && errors?.address_line}
      />
      <TextField
        classes={{ root: styles["up-form"] }}
        label="City"
        variant="outlined"
        hiddenLabel={true}
        fullWidth={true}
        multiline
        InputProps={{
          classes: { root: styles["up-form-input"] },
        }}
        id="city"
        name="city"
        value={formik.values.city}
        onChange={handleChange}
        error={touched?.city && Boolean(errors?.city)}
        helperText={touched?.city && errors?.city}
      />
      {!addressValue || states.length ? (
        <>
          <FormControl className={styles["up-form"]}>
            <InputLabel>State</InputLabel>
            <Select
              classes={{ select: styles["up-form-input"] }}
              variant="outlined"
              fullWidth={true}
              id="state"
              name="state"
              value={formik.values.state}
              onChange={handleChange}
              error={touched?.state && Boolean(errors?.state)}
              label={"State"}
            >
              {states.map((i) => (
                <MenuItem value={i.code} key={i.code}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
            {touched?.state && errors?.state && (
              <FormHelperText>{errors?.state}</FormHelperText>
            )}
          </FormControl>
        </>
      ) : null}

      <TextField
        classes={{ root: styles["up-form"] }}
        label="Zip Code / Postal Code"
        variant="outlined"
        hiddenLabel={true}
        fullWidth={true}
        multiline
        InputProps={{
          classes: { root: styles["up-form-input"] },
        }}
        id="postal_code"
        name="postal_code"
        value={formik.values.postal_code}
        onChange={handleChange}
        error={touched?.postal_code && Boolean(errors?.postal_code)}
        helperText={touched?.postal_code && errors?.postal_code}
      />
      {countries.length ? (
        <>
          <FormControl className={styles["up-form"]}>
            <InputLabel>Country</InputLabel>
            <Select
              classes={{ select: styles["up-form-input"] }}
              variant="outlined"
              fullWidth={true}
              id="country_code"
              name="country_code"
              label={"Country"}
              value={formik.values.country_code}
              onChange={(e) => {
                handleChange(e);
                formik.setFieldValue("state", "");
                GetStates(e.target.value);
              }}
              error={touched?.country_code && Boolean(errors?.country_code)}
            >
              {countries.map((i) => (
                <MenuItem value={i.code} key={i.code}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
            {touched?.country_code && errors?.country_code && (
              <FormHelperText>{errors?.country_code}</FormHelperText>
            )}
          </FormControl>
        </>
      ) : null}
    </>
  );
}

export default AddressField;
