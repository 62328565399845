import { apiGetAgentsList } from "./Api/Agents.api.service";
import { formatResponseWarning } from "../helpers/common.helper";
import { AgentI } from "@interfaces";

export const getAgentsList = (): Promise<AgentI[]> => {
  return apiGetAgentsList().then((resp): Promise<AgentI[]> => {
    const response = formatResponseWarning(resp.data);
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response?.map((item: any): AgentI => {
      return {
        id: item.id,
        avatar: item.avatar,
        email: item.email,
        fullName: item.full_name,
        uuid: item.uuid,
        profile: {
          id: item.profile.id,
          avoidFerries: item.profile.field_map_avoid_ferries,
          avoidHighways: item.profile.field_map_avoid_highways,
          avoidTolls: item.profile.field_map_avoid_tolls,
          trafficModel: item.profile.field_map_traffic_model,
          unitSystem: item.profile.field_map_unit_system,
          testMode: {
            isTest: item.profile.field_test_mode,
            points: item.profile.field_location_point_test,
            time: item.profile.field_current_time_test,
          },
        },
      };
    });
  });
};
