import { AllNotesViewI, NoteCardI } from "@interfaces";
import { debounce } from "@mui/material";
import { AxiosError } from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { getAllNotesList, getNotesList } from "shared/Services";

export const useAllNotesData = () => {
  const { currentUser } = useContext(UserDataContext);
  const [isLoading, setIsLoading] = useState(true);

  const [content, setContent] = useState<AllNotesViewI[]>([]);
  const [searchContent, setSearchContent] = useState<NoteCardI[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [error, setError] = useState<AxiosError<any>>();

  useEffect(() => {
    getContent();
  }, [pageNumber, searchText]);

  const reload = () => {
    setIsLoading(true);
    setContent([]);
    setSearchContent([]);
    setPageNumber(0);
    setHasMore(true);
    getContent();
  };
  const getContent = async () => {
    setHasMore(false);
    if (pageNumber === 0) {
      setIsLoading(true);
    }
    if (searchText === "") {
      try {
        const data = await getAllNotesList(pageNumber * 20, {
          filterByAgents:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyLocation]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceLocation])
              ? currentUser!.uid
              : undefined,
          filterByUser:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyLocation]) &&
            !isUserHavePermissions(currentUser!, [
              PermissionsList.viewMyServiceLocation,
            ]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewOwnLocation])
              ? currentUser!.uid
              : undefined,
        });
        setContent([...content, ...data.content]);
        setSearchContent([]);
        setHasMore(data.hasMore);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      debouncedCallEndpointRef.current(searchText, pageNumber, searchContent);
    }
  };
  const debouncedCallEndpointRef = useRef(
    debounce(async (searchText, pageNumber, searchContent) => {
      try {
        const data = await getNotesList(pageNumber * 20, searchText, {
          userId:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
            !isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceNote]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewOwnNote])
              ? currentUser!.uid
              : undefined,
          serviceId:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceNote])
              ? currentUser!.uid
              : undefined,
        });
        if (pageNumber !== 0) {
          setSearchContent([...searchContent, ...data.content]);
        } else {
          setSearchContent([...data.content]);
        }
        setContent([]);
        setHasMore(data.hasMore);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }, 500)
  );

  useEffect(() => {
    setPageNumber(0);
  }, [searchText]);

  useEffect(() => {
    if (error) {
      toast.error(
        error.response?.data?.errors?.length
          ? error.response?.data?.errors[0]?.detail
          : error.message
      );
      throw error;
    }
  }, [error]);

  return {
    content,
    searchContent,
    searchText,
    setSearchText,
    hasMore,
    isLoading,
    reload,
    setPageNumber,
  };
};
