import { NoteCardI } from "@interfaces";
import { debounce } from "@mui/material";
import { AxiosError } from "axios";
import { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { getNotesList } from "shared/Services";

export const useNotesData = (locationId?: string) => {
  const { currentUser } = useContext(UserDataContext);
  const [content, setContent] = useState<NoteCardI[]>([]);
  const [searchText, setSearchText] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);

  const [error, setError] = useState<AxiosError<any>>();

  const getContent = async () => {
    if (searchText === "" || pageNumber !== 0) {
      try {
        const data = await getNotesList(pageNumber * 20, "", {
          userId:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
            !isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceNote]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewOwnNote])
              ? currentUser!.uid
              : undefined,
          serviceId:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceNote])
              ? currentUser!.uid
              : undefined,
          locationId: locationId,
        });
        if (pageNumber === 0) {
          setContent([...data.content]);
        } else {
          setContent((prev) => [...prev, ...data.content]);
        }
        setHasMore(data.hasMore);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      debouncedCallEndpointRef.current(searchText, pageNumber * 20, content);
    }
  };

  const reload = () => {
    setHasMore(true);
    setIsLoading(true);
    setPageNumber(0);
    setContent([]);
    getContent();
  };

  useEffect(() => {
    getContent();
  }, [pageNumber, searchText]);

  const debouncedCallEndpointRef = useRef(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    debounce(async (searchText, pageNumber, content) => {
      try {
        const data = await getNotesList(pageNumber * 20, searchText, {
          userId:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
            !isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceNote]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewOwnNote])
              ? currentUser!.uid
              : undefined,
          serviceId:
            !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceNote])
              ? currentUser!.uid
              : undefined,
          locationId: locationId,
        });
        setContent([...data.content]);
        setHasMore(data.hasMore);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }, 500)
  );

  useEffect(() => {
    if (error) {
      toast.error(
        error.response?.data?.errors?.length
          ? error.response?.data?.errors[0]?.detail
          : error.message
      );
      throw error;
    }
  }, [error]);

  return { content, setSearchText, hasMore, isLoading, reload, setPageNumber };
};
