export enum PriorityStatuses {
  urgent = "URGENT",
  pastDue = "PASTDUE",
  appointment = "APPOINTMENT",
}

export enum TaskPriority {
  regular = "Regular",
  high = "High",
}

export enum NotePublishType {
  task = "TASK",
  visit = "VISIT",
  location = "LOCATION",
}

export enum TabsStatuses {
  active = "active",
  completed = "completed",
  skipped = "skipped",
}

export enum TaskStatuses {
  active = "active",
  completed = "completed",
}

export enum AppointmentDateForm {
  from = "from",
  to = "to",
  date = "date",
}

export enum DateFormI {
  from = "from",
  to = "to",
}

export enum PermissionsList {
  createCompany = "create company",
  createContact = "create contact",
  createLocation = "create location",
  createAppointment = "create appointment",
  createNote = "create note",
  createTask = "create task",
  createVisit = "create visit",
  createTimeslot = "create timeslot",
  deleteAnyContact = "delete any contact",
  deleteAnyDay = "delete any day",
  deleteAnyLocation = "delete any location",
  deleteAnyNote = "delete any note",
  deleteAnyTask = "delete any task",
  deleteAnyTimeslot = "delete any timeslot",
  deleteAnyCompany = "delete any company",
  deleteOwnContact = "delete own contact",
  deleteOwnDay = "delete own day",
  deleteOwnLocation = "delete own location",
  deleteOwnNote = "delete own note",
  deleteOwnTask = "delete own task",
  deleteOwnVisit = "delete own visit",
  deleteOwnTimeslot = "delete own timeslot",
  deleteOwnCompany = "delete own company",
  editAnyCompany = "edit any company",
  editOwnAppointment = "edit own appointment",
  editAnyContact = "edit any contact",
  editAnyDay = "edit any day",
  editAnyLocation = "edit any location",
  editAnyNote = "edit any note",
  editAnyTask = "edit any task",
  editAnyTimeslot = "edit any timeslot",
  editOwnCompany = "edit own company",
  editOwnContact = "edit own contact",
  editOwnDay = "edit own day",
  editOwnLocation = "edit own location",
  editOwnNote = "edit own note",
  editOwnTask = "edit own task",
  editOwnVisit = "edit own visit",
  editOwnTimeslot = "edit own timeslot",
  viewAnyCompany = "view any company",
  viewAnyContact = "view any contact",
  viewAnyDay = "view any day",
  viewOwnDay = "view own day",
  viewAnyLocation = "view any location",
  viewAnyNote = "view any note",
  viewAnyProfile = "view any profile",
  viewAnyTask = "view any task",
  viewAnyVisit = "view any visit",
  viewAnyTimeslot = "view any timeslot",
  viewMyServiceLocation = "view my service location",
  viewMyServiceTask = "view my service task",
  viewMyServiceNote = "view my service note",
  viewOwnCompany = "view own company",
  viewOwnContact = "view own contact",
  viewOwnLocation = "view own location",
  viewOwnNote = "view own note",
  viewOwnProfile = "view own profile",
  viewOwnTask = "view own task",
  viewOwnVisit = "view own visit",
  administerTesting = "administer agent testing",

  viewLocationsReports = "view ups locations report",
  viewPerformanceReports = "view ups performance report",
  viewReports = "view ups reports",
  viewTasksReports = "view ups tasks report",
}

export enum DayScheduleTypeNew {
  pending = "pending",
  started = "started",
  ended = "ended",
}

export enum DayScheduleStepsStatusesNew {
  pending = "pending",
  inProgress = "in_progress",
  skipped = "skipped",
  missed = "missed",
  completed = "completed",
}

export enum DayScheduleStepsTypeNew {
  break = "break",
  start = "start",
  end = "end",
  service = "service",
}

export enum DayScheduleActionsNew {
  skipVisit = "skipVisit",
  unskipVisit = "unskipVisit",
  completeVisit = "completeVisit",
  createVisit = "createVisit",
}
export enum DayScheduleBreakActions {
  startDay = "startDay",
  endDay = "endDay",
}

export enum AppointmentsType {
  visit = "visit",
  call = "call",
}

export enum TrafficModelE {
  bestguess = "bestguess",
  optimistic = "optimistic",
  pessimistic = "pessimistic",
}

export enum UnitSystemE {
  metric = "kilometers",
  imperial = "miles",
}

export enum ResourceLocationStatusEnum {
  ok = "GOOD",
  bad = "BAD",
  unknown = "UNKNOWN",
}

export type Roles = "agent" | "manager" | "owner";

export enum AppType {
  app = "APP",
  web = "WEB",
}

export enum MuteNotificationsUntil {
  oneHour = "1 hour",
  fourHours = "4 hours",
  tomorrow = "tomorrow",
  unMute = "unMute",
}
