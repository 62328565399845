import { Route, Routes } from "react-router-dom";
import PageNotFound from "../../PageNotFound/PageNotFound";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import LoginPage from "./Login/Login";
import ResetPassword from "./ResetPassword/ResetPassword";
import { ErrorBoundary } from "error";

function AuthRouter() {
  return (
    <Routes>
      <Route
        path={"*"}
        element={
          <ErrorBoundary>
            <PageNotFound />
          </ErrorBoundary>
        }
      />
      <Route
        path={"reset-password"}
        element={
          <ErrorBoundary>
            <ResetPassword />
          </ErrorBoundary>
        }
      />
      <Route
        path={"forgot-password"}
        element={
          <ErrorBoundary>
            <ForgotPassword />
          </ErrorBoundary>
        }
      />
      <Route
        path={``}
        element={
          <ErrorBoundary>
            <LoginPage />
          </ErrorBoundary>
        }
      />
    </Routes>
  );
}

export default AuthRouter;
