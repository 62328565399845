import { TaskDetailsI } from "@interfaces";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { UserDataContextI } from "shared/Contexts/UserDataContext";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { getTaskViewData } from "shared/Services";

export const useTaskInfo = (
  taskId: string | undefined,
  currentUser: UserDataContextI
) => {
  const [taskInfo, setTaskInfo] = useState<TaskDetailsI>();
  const [error, setError] = useState<AxiosError<any>>();
  const [loading, setLoading] = useState(true);

  const getContent = async () => {
    setLoading(true);
    try {
      if (taskId) {
        const data = await getTaskViewData(
          taskId,
          currentUser!.timezone.value,
          !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyNote]) &&
            !isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceNote]) &&
            isUserHavePermissions(currentUser!, [PermissionsList.viewOwnNote])
            ? currentUser!.uid
            : undefined
        );
        setTaskInfo(data);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(
        error.response?.data?.errors?.length
          ? error.response?.data?.errors[0]?.detail
          : error.message
      );
      throw error;
    }
  }, [error]);

  return { taskInfo, loading, getContent, setTaskInfo };
};
