import { AppointmentDataI } from "@interfaces";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAppointmentData } from "shared/Services";

export const useAppointmentData = (appointmentId: string | undefined) => {
  const [appointmentInfo, setAppointmentInfo] = useState<AppointmentDataI>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<AxiosError<any>>();

  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    if (appointmentId) {
      try {
        const data = await getAppointmentData(appointmentId);

        setAppointmentInfo(data);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(
        error.response?.data?.errors?.length
          ? error.response?.data?.errors[0]?.detail
          : error.message
      );
      throw error;
    }
  }, [error]);

  return { appointmentInfo, isLoading, getContent };
};
