import { AgentI } from "@interfaces";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { RouterContextI } from "shared/Contexts/RouterContext";
import { UserDataContextI } from "shared/Contexts/UserDataContext";
import { DayScheduleTypeNew } from "shared/Enums";

/**
 * This hook is used to get the current location of the user.
 */
export const useMyLocation = (
  routerValue: RouterContextI,
  setRouterValue: Dispatch<SetStateAction<RouterContextI>>,
  agent: AgentI | null | undefined,
  currentUser: UserDataContextI | null
) => {
  // useRef is used to store the value of the function so that it can be used in the useEffect hook with setTimeout.
  const setLocationRef = useRef<any>();
  const setWatchRef = useRef<any>();
  const locationErrorRef = useRef<any>();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // useState is used to store the value of the location.

  // This function is used to handle the error if the location services are not enabled.
  locationErrorRef.current = (error: any) => {
    if (error.code == 1) {
      setRouterValue((prev) => ({
        ...prev,
        isLocationHasError: true,
      }));
    }
  };

  // To round the latitude and longitude values to an approximate precision that corresponds to around 20 meters,
  // you can truncate the values to a certain number of decimal places.
  // For latitude and longitude, each decimal place represents a different level of precision:
  // 1 decimal place: ~11.1 km
  // 2 decimal places: ~1.1 km
  // 3 decimal places: ~110 m
  // Rounding to 3 decimal places should give you an approximate precision of around 110 meters.
  const roundToFourDecimals = (value: number) => Math.round(value * 1000) / 1000;

  // This function is used to get the current location of the user.
  setLocationRef.current = (position: GeolocationPosition) => {
    // If the agent is in test mode, then the location of the agent is used.
    // If the user is in test mode, then the location of the user is used.
    // If the location of the user is available, then the location of the user is used.
    // Otherwise, the location of the user is undefined.
    const myLocation =
      agent && agent.profile.testMode.isTest && agent.profile.testMode.points
        ? {
            lat: +agent.profile.testMode.points.lat,
            lng: +agent.profile.testMode.points.lng,
          }
        : currentUser?.routerSettings &&
            currentUser.routerSettings.testMode.isTest &&
            currentUser.routerSettings.testMode.points
          ? {
              lat: currentUser.routerSettings.testMode.points.lat,
              lng: currentUser.routerSettings.testMode.points.lng,
            }
          : position.coords.latitude && position.coords.longitude
            ? {
                lat: +position.coords.latitude,
                lng: +position.coords.longitude,
              }
            : undefined;
    // If the location of the user is available and the location of the user is different from the location of the user in the router context,
    // then the location of the user is updated in the router context.
    const isChordsChanged =
      roundToFourDecimals(myLocation!.lat) !==
        roundToFourDecimals(routerValue.myLocation?.lat || 0) ||
      roundToFourDecimals(myLocation!.lng) !==
        roundToFourDecimals(routerValue.myLocation?.lng || 0);

    // If the location of the user is not available, then the location of the user is undefined.
    if (!routerValue.myLocation) {
      setRouterValue((prev) => ({
        ...prev,
        myLocation,
        locationUpdatedTimeStamp: new Date().getTime(),
      }));
      setRouterValue((prev) => ({
        ...prev,
        isLocationHasError: false,
        isLocationLoaded: true,
        locationUpdatedTimeStamp: new Date().getTime(),
      }));
    } else if (
      // If the location of the user is available and the location of the user is different from the location of the user in the router context,
      // then the location of the user is updated in the router context.
      isChordsChanged
    ) {
      setRouterValue((prev) => ({
        ...prev,
        myLocation,
        locationUpdatedTimeStamp: new Date().getTime(),
      }));
    }
  };

  // This function is used to get the current location of the user.
  const getCurrentPosition = () => {
    if (
      (routerValue.opened &&
        routerValue.routeData!.day.status === DayScheduleTypeNew.started) ||
      !routerValue.isLocationLoaded
    ) {
      navigator.geolocation.getCurrentPosition(
        setLocationRef.current,
        locationErrorRef.current,
        {
          enableHighAccuracy: true,
          timeout: 1000000,
          maximumAge: 1000000,
        }
      );
    }
  };

  // This useEffect hook is used to get the current location of the user.
  useEffect(() => {
    setWatchRef.current = () => {
      getCurrentPosition();
      // The interval is set to 120 seconds if the location of the user is available.
      // Otherwise, the interval is set to 1000 milliseconds to get the location of the user.
      const interval = routerValue.isLocationLoaded ? 120000 : 3000;
      timeoutRef.current = setTimeout(setWatchRef.current, interval);
    };
    setWatchRef.current();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [routerValue.isLocationLoaded]);
};
