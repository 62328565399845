import { LatLngI, VisitCardsNewI, VisitBreaksNewI } from "@interfaces";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { RouterContextI } from "shared/Contexts/RouterContext";
import {
  DayScheduleTypeNew,
  DayScheduleStepsTypeNew,
  TabsStatuses,
  DayScheduleStepsStatusesNew,
} from "shared/Enums";

/**
 * Hook to get the directions for the route
 */
export const useDirections = (
  routerValue: RouterContextI,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setResponse: Dispatch<SetStateAction<any>>
) => {
  const [waypoints, setWaypoints] = useState<any>([]);
  const [missedAppointments, setMissedAppointments] = useState<any>([]);
  const [skipped, setSkipped] = useState<any>([]);
  const [completed, setCompleted] = useState<any>([]);
  const [startLocation, setStartLocation] = useState<any>(null);
  const [endLocation, setEndLocation] = useState<any>(null);

  useEffect(() => {
    setIsLoading(true);
    setResponse(null);
    if (
      routerValue.loaded &&
      routerValue.routeData?.steps?.active.length &&
      routerValue.opened
    ) {
      let startLoc: LatLngI;
      if (
        routerValue.routeData.day.status === DayScheduleTypeNew.started &&
        routerValue.myLocation
      ) {
        startLoc = routerValue.myLocation;
      } else {
        const startStep = routerValue.routeData.steps.active.find(
          (i) => i.cardType === DayScheduleStepsTypeNew.start
        ) as VisitCardsNewI | VisitBreaksNewI;
        startLoc = startStep.geolocation;
      }
      setStartLocation({
        location: startLoc,
        cardType: DayScheduleStepsTypeNew.start,
        status: TabsStatuses.active,
      });

      const endStep = routerValue.routeData.steps.active.find(
        (i) => i.cardType === DayScheduleStepsTypeNew.end
      ) as VisitCardsNewI | VisitBreaksNewI;
      setEndLocation({
        location: endStep.geolocation,
        cardType: DayScheduleStepsTypeNew.end,
        status: TabsStatuses.active,
      });

      setWaypoints(
        (
          routerValue.routeData.steps.active.filter(
            (i) =>
              i.cardType === DayScheduleStepsTypeNew.service &&
              i.status !== DayScheduleStepsStatusesNew.missed
          ) as VisitCardsNewI[]
        ).map((step) => {
          return {
            locationName: step.location.title,
            id: step.id,
            cardType: step?.cardType,
            status: TabsStatuses.active,
            isLocationOverdue: step.isLocationOverdue,
            location: new google.maps.LatLng({
              lat: Number(step.geolocation?.lat) || 0,
              lng: Number(step.geolocation?.lng) || 0,
            }),
            stopover: true,
            appointment: step.appointment,
          };
        })
      );

      setMissedAppointments(
        (
          routerValue.routeData.steps.active.filter(
            (i) => i.status === DayScheduleStepsStatusesNew.missed
          ) as VisitCardsNewI[]
        ).map((step) => {
          return {
            locationName: step.location.title,
            id: step.id,
            cardType: step?.cardType,
            status: TabsStatuses.active,
            isLocationOverdue: step.isLocationOverdue,
            location: new google.maps.LatLng({
              lat: Number(step.geolocation?.lat) || 0,
              lng: Number(step.geolocation?.lng) || 0,
            }),
            stopover: true,
            appointment: step.appointment,
          };
        })
      );

      setSkipped(
        (routerValue.routeData.steps.skipped as VisitCardsNewI[]).map((step) => {
          return {
            locationName: step.location.title,
            id: step.id,
            cardType: step?.cardType,
            status: TabsStatuses.skipped,
            location: new google.maps.LatLng({
              lat: Number(step.geolocation?.lat) || 0,
              lng: Number(step.geolocation?.lng) || 0,
            }),
            stopover: true,
          };
        })
      );

      setCompleted(
        (routerValue.routeData.steps.completed as VisitCardsNewI[]).map((step) => {
          return {
            locationName: step.location.title,
            id: step.id,
            cardType: step?.cardType,
            status: TabsStatuses.completed,
            location: new google.maps.LatLng({
              lat: Number(step.geolocation?.lat) || 0,
              lng: Number(step.geolocation?.lng) || 0,
            }),
            stopover: true,
          };
        })
      );
    } else {
      setIsLoading(false);
    }
  }, [routerValue.routeData, routerValue.myLocation]);

  return {
    waypoints,
    missedAppointments,
    skipped,
    completed,
    startLocation,
    endLocation,
  };
};
