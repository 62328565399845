import AddressField from "components/Shared/Forms/AddressField/AddressField";
import UpAvatar from "components/Shared/Profile/Avatar/Avatar";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { addressValidation, phoneValidation } from "shared/Validation/Validation";
import * as Yup from "yup";

import { CompanyFormI, CompanyViewI, FileI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import { Button, FormHelperText, IconButton, TextField } from "@mui/material";

import DeleteIcon from "../../../../assets/icons/delete.svg";
import LocationIcon from "../../../../assets/icons/location.svg";
import styles from "./CompaniesForm.module.scss";

interface CompaniesFormPropsI {
  submitted: number;
  formFinished: (value: CompanyFormI) => void;
  formValue?: CompanyViewI;
}

function CompaniesForm({ submitted, formFinished, formValue }: CompaniesFormPropsI) {
  useEffect(() => {
    submitted && formik.handleSubmit();
  }, [submitted]);

  const [avatar, setAvatar] = useState<FileI | null>(null);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    phoneNumbers: Yup.array().of(phoneValidation).required("This field is required"),
    information: Yup.string().required("This field is required"),
    address: addressValidation,
  });

  const formik = useFormik({
    initialValues: {
      title: formValue?.name,
      phoneNumbers: formValue?.phoneNumbers || [""],
      information: formValue?.information,
      address: formValue?.address || {
        address_line: "",
        city: "",
        country_code: "",
        postal_code: "",
        state: "",
      },
    } as CompanyFormI,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formFinished({ ...values, avatar });
    },
  });

  useEffect(() => {
    setAvatar({
      binaryData: formValue?.avatar?.url || null,
      arrayBuffer: null,
    });
  }, []);

  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-card"]}>
        <UpAvatar
          name={formValue?.name}
          avatar={avatar?.binaryData || null}
          toggleImage={setAvatar}
          editable={true}
          defaultImage={LocationIcon}
        />
      </div>

      <div className={styles["up-form"]}>
        <FormikProvider value={formik}>
          <TextField
            classes={{ root: styles["up-form-title"] }}
            label="Company name"
            variant="outlined"
            hiddenLabel={true}
            fullWidth={true}
            multiline
            InputProps={{
              classes: { root: styles["up-form-title-input"] },
            }}
            id="title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />

          <div className={styles["up-form-address"]}>
            <AddressField
              addressValue={formValue?.address}
              toggleValue={(e) => formik.setFieldValue("address", e)}
              errors={formik.errors.address}
              touched={formik.touched.address}
            ></AddressField>
          </div>

          <FieldArray name="phoneNumbers">
            {(formHelpers: any) => (
              <div className={styles["up-form-phoneNumbers"]}>
                {formik.values.phoneNumbers?.map((_, index) => (
                  <TextField
                    key={index}
                    classes={{ root: styles["up-form-phoneNumbers-root"] }}
                    label={"Phone number"}
                    variant="outlined"
                    hiddenLabel={true}
                    fullWidth={true}
                    multiline
                    InputProps={{
                      classes: { root: styles["up-form-phoneNumbers-input"] },
                      endAdornment:
                        formik.values.phoneNumbers?.length > 1 ? (
                          <IconButton
                            onClick={() => formHelpers.remove(index)}
                            className={styles["up-form-phoneNumbers-delete"]}
                          >
                            <img src={DeleteIcon} alt="Delete" />
                          </IconButton>
                        ) : null,
                    }}
                    id={`phoneNumbers.${index}`}
                    name={`phoneNumbers.${index}`}
                    value={formik.values.phoneNumbers[index]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumbers &&
                      Boolean(
                        formik.errors.phoneNumbers
                          ? formik.errors.phoneNumbers[index]
                          : false
                      )
                    }
                  />
                ))}
                <Button
                  classes={{ root: styles["up-form-phoneNumbers-add"] }}
                  variant="outlined"
                  startIcon={<AddIcon color="inherit" />}
                  onClick={() => formHelpers.push("")}
                >
                  Add Telephone
                </Button>
                <FormHelperText>
                  {formik.touched.phoneNumbers &&
                    formik.errors.phoneNumbers &&
                    (typeof formik.errors.phoneNumbers === "string"
                      ? formik.errors.phoneNumbers
                      : formik.errors.phoneNumbers.filter((i) => i.length)[0])}
                </FormHelperText>
              </div>
            )}
          </FieldArray>

          <TextField
            classes={{ root: styles["up-form-information"] }}
            label="Information"
            variant="outlined"
            hiddenLabel={true}
            fullWidth={true}
            multiline
            minRows={6}
            InputProps={{
              classes: { root: styles["up-form-information-input"] },
            }}
            id="information"
            name="information"
            value={formik.values.information}
            onChange={formik.handleChange}
            error={formik.touched.information && Boolean(formik.errors.information)}
            helperText={formik.touched.information && formik.errors.information}
          />
        </FormikProvider>
      </div>
    </div>
  );
}

export default CompaniesForm;
