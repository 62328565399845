import { createContext, Dispatch, SetStateAction } from "react";
import { AgentI } from "@interfaces";

export interface ManagerContextI {
  agent: AgentI | null | undefined;
}

export const ManagerContext = createContext<
  [ManagerContextI, Dispatch<SetStateAction<ManagerContextI>>]
>([
  {
    agent: null,
  },
  () => {},
]);
