import { NoteFormI, NoteViewI } from "@interfaces";
import { RadioGroup, FormControlLabel, Radio, TextField, Modal } from "@mui/material";
import axios from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import LocationsList from "components/Shared/Locations/LocationsList/LocationsList";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { NotePublishType } from "shared/Enums";
import { getLocationName, getTaskName, getVisitName } from "shared/Services";
import * as Yup from "yup";

import styles from "./NotesForm.module.scss";
import { TasksSelectField } from "../TasksSelectField/TasksSelectField";
import { VisitsSelectField } from "../VisitsSelectField/VisitsSelectField";

type NoteFormPropsI = {
  submitted: number;
  formFinished: (value: NoteFormI) => void;
  formValue?: NoteViewI;
  locationId?: string;
  taskId?: string;
  visitId?: string;
};

export function NotesForm({
  submitted,
  formFinished,
  formValue,
  locationId,
  taskId,
  visitId,
}: NoteFormPropsI) {
  const [locationsOpened, setLocationsOpened] = useState(false);

  const [publishSelected, setPublishSelected] = useState<{
    selectedType: NotePublishType;
    item: null | any;
  }>({
    selectedType: NotePublishType.location,
    item: null,
  });

  useEffect(() => {
    submitted && formik.handleSubmit();
  }, [submitted]);

  function getValidationSchema() {
    if (publishSelected.selectedType === NotePublishType.task) {
      return Yup.object().shape({
        note: Yup.string().required("This field is required"),
        task: Yup.string().required("This field is required"),
        visit: Yup.string(),
        location: Yup.string(),
        publishType: Yup.string(),
      });
    } else if (publishSelected.selectedType === NotePublishType.visit) {
      return Yup.object().shape({
        note: Yup.string().required("This field is required"),
        task: Yup.string(),
        visit: Yup.string().required("This field is required"),
        location: Yup.string(),
        publishType: Yup.string(),
      });
    } else {
      return Yup.object().shape({
        note: Yup.string().required("This field is required"),
        task: Yup.string(),
        visit: Yup.string(),
        location: Yup.string().required("This field is required"),
        publishType: Yup.string(),
      });
    }
  }

  const formik = useFormik({
    initialValues: {
      note: formValue?.content || "",
      location: "",
      task: "",
      visit: "",
      publishType: NotePublishType.location,
    } as NoteFormI,
    validationSchema: getValidationSchema,
    onSubmit: (values) => {
      formFinished({ ...values, ...publishSelected });
    },
  });

  useEffect(() => {
    locationId &&
      getLocationName(locationId).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          setPublishSelected({
            selectedType: NotePublishType.location,
            item: data,
          });

          formik.setFieldValue("location", data.id);
          formik.setFieldValue("publishType", NotePublishType.location);
        }
      });
    taskId &&
      getTaskName(taskId).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          setPublishSelected({
            selectedType: NotePublishType.task,
            item: data,
          });
          formik.setFieldValue("task", data.id);
          formik.setFieldValue("publishType", NotePublishType.task);
          formik.setFieldValue("location", data.location);
        }
      });
    visitId &&
      getVisitName(visitId).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          setPublishSelected({
            selectedType: NotePublishType.visit,
            item: data,
          });
          formik.setFieldValue("publishType", NotePublishType.visit);
          formik.setFieldValue("visit", data.id);
        }
      });
  }, []);

  return (
    <>
      <RadioGroup
        row
        id="publishType"
        name="publishType"
        value={formik.values.publishType}
        onChange={(e) => {
          formik.setFieldValue("location", "");
          formik.setFieldValue("task", "");
          formik.setFieldValue("visit", "");
          setPublishSelected({
            selectedType: e.target.value as NotePublishType,
            item: null,
          });
          formik.handleChange(e);
        }}
        classes={{ root: styles["up-radio-group"] }}
      >
        <FormControlLabel
          classes={{ root: styles["up-radio-label"] }}
          value={NotePublishType.location}
          control={<Radio />}
          label="Location"
        />
        <FormControlLabel
          classes={{ root: styles["up-radio-label"] }}
          value={NotePublishType.visit}
          control={<Radio />}
          label="Visit"
        />
        <FormControlLabel
          classes={{ root: styles["up-radio-label"] }}
          value={NotePublishType.task}
          control={<Radio />}
          label="Task"
        />
      </RadioGroup>
      <div className={styles["up-publish"]}>
        {publishSelected.selectedType === NotePublishType.location && (
          <>
            <TextField
              size="medium"
              classes={{ root: styles["up-form-location"] }}
              label="Location"
              variant="outlined"
              hiddenLabel={true}
              fullWidth={true}
              multiline
              onClick={() => setLocationsOpened(true)}
              InputProps={{
                classes: { root: styles["up-form-location-input"] },
              }}
              id="location"
              name="location"
              value={
                publishSelected.selectedType === NotePublishType.location
                  ? publishSelected.item?.title
                  : ""
              }
              error={formik.touched.location && Boolean(formik.errors.location)}
              helperText={formik.touched.location && formik.errors.location}
            />
            <Modal open={locationsOpened} onClose={() => {}}>
              <div className={styles["up-modal"]}>
                <FormsHeader
                  title="Pick Location"
                  position="relative"
                  onCancelToggle={() => setLocationsOpened(false)}
                  onRightBtnToggle={() => setLocationsOpened(false)}
                />

                <div className={styles["up-modal-content"]}>
                  <LocationsList
                    hasSearch={true}
                    active={
                      publishSelected.selectedType === NotePublishType.location
                        ? publishSelected.item?.id
                        : undefined
                    }
                    locationSelected={(location) => {
                      formik.setFieldValue("location", location.id);
                      setPublishSelected({
                        selectedType: NotePublishType.location,
                        item: location,
                      });
                      setLocationsOpened(false);
                    }}
                  />
                </div>
              </div>
            </Modal>
          </>
        )}
        {publishSelected.selectedType === NotePublishType.task && (
          <TasksSelectField
            formik={formik}
            setPublishSelected={setPublishSelected}
            publishSelected={publishSelected}
          />
        )}
        {publishSelected.selectedType === NotePublishType.visit && (
          <VisitsSelectField
            formik={formik}
            setPublishSelected={setPublishSelected}
            publishSelected={publishSelected}
          />
        )}
      </div>
      <TextField
        classes={{ root: styles["up-form-note"] }}
        variant="outlined"
        hiddenLabel={true}
        fullWidth={true}
        multiline
        minRows={5}
        InputProps={{
          classes: { root: styles["up-form-note-input"] },
        }}
        id="note"
        name="note"
        label="Note"
        value={formik.values.note}
        onChange={formik.handleChange}
        error={formik.touched.note && Boolean(formik.errors.note)}
        helperText={formik.touched.note && formik.errors.note}
      />
    </>
  );
}
