import { useContext } from "react";
import { UserDataContext } from "../../../shared/Contexts";
import { Route, Routes } from "react-router-dom";
import AgentsListView from "./AgentsListView/AgentsListView";
import { ErrorBoundary } from "error";
import { Footer } from "components/Shared/Footer/Footer";

export default function AgentsRouter() {
  const { currentUser } = useContext(UserDataContext);

  return (
    <>
      <Routes>
        {currentUser?.roles.includes("manager") && (
          <>
            <Route
              path="/"
              element={
                <ErrorBoundary>
                  <AgentsListView />
                </ErrorBoundary>
              }
            />
          </>
        )}
      </Routes>
      <Footer />
    </>
  );
}
