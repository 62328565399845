import { MessengersKeys } from "shared/helpers/profile.helper";

import { ContactFormI, ContactViewI, ResponseDto } from "@interfaces";

import {
  apiCreateContact,
  apiDeleteContact,
  apiEditContactData,
  apiGetContactData,
  apiUploadContactLogo,
} from "./Api/Contacts.api.service";

export const getContactData = (id: string) => {
  return apiGetContactData(id).then((response) => {
    const contactAvatar: ResponseDto<any> = response.data.included?.find(
      (i: ResponseDto<any>) => i.type === "file--file"
    );
    const company = response.data.included?.find(
      (i: ResponseDto<any>) => i.type === "ups_company--ups_company"
    );
    const location = response.data.included?.find(
      (i: ResponseDto<any>) => i.type === "ups_location--ups_location"
    );
    return {
      id: response.data.data.id,
      name: response.data.data.attributes.label,
      address: response.data.data.attributes.same_as_company_address
        ? company.attributes.address
        : response.data.data.attributes.address,
      phoneNumbers: response.data.data.attributes.telephones,
      emails: response.data.data.attributes.emails,
      sameAsCompanyAddress: response.data.data.attributes.same_as_company_address,
      links: (
        response.data.data.attributes.contact_links.map((i: { uri: string }) => i.uri) ||
        []
      ).filter((str: string) => {
        for (const key of MessengersKeys) {
          if (str.includes(key)) {
            return false;
          }
        }
        return true;
      }),

      allLinks: response.data.data.attributes.contact_links.map(
        (i: { uri: string }) => i.uri
      ),

      messengers: (
        response.data.data.attributes.contact_links.map((i: { uri: string }) => i.uri) ||
        []
      ).filter((str: string) => {
        for (const key of MessengersKeys) {
          if (str.includes(key)) {
            return true;
          }
        }
        return false;
      }),

      avatar: contactAvatar
        ? {
            url: contactAvatar.attributes.uri.url,
            id: contactAvatar.id,
          }
        : null,
      company: {
        name: company?.attributes.label,
        id: company?.id,
      },
      location: location
        ? {
            name: location.attributes.label,
            id: location.id,
          }
        : null,
      authorId: response.data.data.relationships.uid.data.id,
    } as ContactViewI;
  });
};

export const editContactData = (id: string, body: ContactFormI) => {
  if (body.avatar?.arrayBuffer) {
    return apiUploadContactLogo(id, body.avatar.arrayBuffer)
      .then(() => {
        editContactDataWithAvatar(id, body);
      })
      .catch((err) => err);
  } else if (!body.avatar) {
    return editContactDataWithAvatar(id, body, true);
  }
  return editContactDataWithAvatar(id, body);
};
export const editContactDataWithAvatar = (
  id: string,
  body: ContactFormI,
  deleteAvatar?: boolean
) => {
  const image = deleteAvatar
    ? {
        image: {
          data: null,
        },
      }
    : {};

  const location = body.selectedLocation
    ? {
        location: {
          data: {
            type: "ups_location--ups_location",
            id: body.selectedLocation.id,
          },
        },
      }
    : {};

  return apiEditContactData(id, {
    data: {
      type: "ups_contact--ups_contact",
      id: id,
      attributes: {
        address: body.address,
        contact_links: body.links,
        emails: body.emails,
        label: body.name,
        same_as_company_address: body.isAsCompanyAddress,
        telephones: body.phoneNumbers,
      },
      relationships: {
        company: {
          data: {
            type: "ups_company--ups_company",
            id: body.selectedCompany.id,
          },
        },
        ...image,
        ...location,
      },
    },
  }).catch((err) => err);
};

export const createContact = (body: ContactFormI) => {
  const location = body.selectedLocation
    ? {
        location: {
          data: {
            type: "ups_location--ups_location",
            id: body.selectedLocation.id,
          },
        },
      }
    : {};
  return apiCreateContact({
    data: {
      type: "ups_company--ups_company",
      attributes: {
        address: body.address,
        contact_links: body.links,
        emails: body.emails,
        label: body.name,
        same_as_company_address: body.isAsCompanyAddress,
        telephones: body.phoneNumbers,
      },
      relationships: {
        company: {
          data: {
            type: "ups_company--ups_company",
            id: body.selectedCompany.id,
          },
        },
        ...location,
      },
    },
  })
    .then((response) => {
      if (body.avatar?.arrayBuffer) {
        return apiUploadContactLogo(response.data.data.id, body.avatar.arrayBuffer)
          .then(() => {
            return response.data.data.id;
          })
          .catch((err) => err);
      }

      return response.data.data.id;
    })
    .catch((err) => err);
};

export const deleteContact = (id: string) => {
  return apiDeleteContact(id).catch((err) => err);
};
