import { Button } from "@mui/material";
import { MuteNotificationsUntil } from "shared/Enums";

import styles from "./MuteNotifications.module.scss";
import { muteNotifications } from "shared/Services/Common.service";
import { useContext } from "react";
import { UserDataContext } from "shared/Contexts";
import { toast } from "react-toastify";

export const MuteNotifications = () => {
  const { setCurrentUser } = useContext(UserDataContext);

  const muteNotification = async (until: MuteNotificationsUntil) => {
    try {
      const data = await muteNotifications(until);
      if (data.data.result) {
        setCurrentUser((prev) => ({
          ...prev!,
          mute_notifications_until: data.data.value,
          notificationsMuted: true,
        }));
      }
    } catch (e) {
      toast.error("Error while muting notifications");
    }
  };

  return (
    <div className={styles["up-container"]}>
      <Button
        className={styles["up-container-button"]}
        onClick={() => {
          muteNotification(MuteNotificationsUntil.oneHour);
        }}
      >
        Mute for 1 hour
      </Button>

      <Button
        className={styles["up-container-button"]}
        onClick={() => {
          muteNotification(MuteNotificationsUntil.fourHours);
        }}
      >
        Mute for 4 hours
      </Button>
      <Button
        className={styles["up-container-button"]}
        onClick={() => {
          muteNotification(MuteNotificationsUntil.tomorrow);
        }}
      >
        Mute until tomorrow
      </Button>
    </div>
  );
};
