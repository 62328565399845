import axios, { AxiosError } from "axios";
import ContactsForm from "components/Shared/Contacts/ContactForm/ContactsForm";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createContact } from "shared/Services";

import { ContactFormI } from "@interfaces";

import styles from "./ContactCreate.module.scss";
import { toast } from "react-toastify";

function ContactCreate() {
  const [submitted, setSubmitted] = useState(0);
  const navigate = useNavigate();

  const FormSubmit = (value: ContactFormI) => {
    createContact(value).then((response: AxiosError | any) => {
      if (axios.isAxiosError(response)) {
        toast.error(response.message);
      } else {
        toast.success("Successfully completed!");
        navigate(`/contacts/${response}`);
      }
    });
  };

  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Create Contact"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        position={"absolute"}
        background="transparent"
        onCancelToggle={() => navigate(-1)}
      />
      <ContactsForm submitted={submitted} formFinished={FormSubmit}></ContactsForm>
    </div>
  );
}

export default ContactCreate;
