import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { isUserHavePermissions } from "shared/helpers/common.helper";

import { LocationViewI, TasksListI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, Link } from "@mui/material";

import styles from "./TasksCards.module.scss";
import TaskCard from "../TaskCard/TaskCard";

function TasksCards({ location }: { location: LocationViewI }) {
  const { currentUser } = useContext(UserDataContext);
  const navigate = useNavigate();
  const {
    tasks: content,
    id: locationId,
    name,
  }: {
    tasks: TasksListI;
    id: string;
    name: string;
  } = location;

  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-header"]}>
        <div style={{ marginRight: "auto" }} />
        {content.items.length > 0 && (
          <Link
            onClick={() =>
              !locationId ? navigate("/tasks") : navigate(`/tasks/location/${locationId}`)
            }
            underline="none"
            classes={{ root: styles["up-header-link"] }}
          >
            All Tasks
          </Link>
        )}

        {isUserHavePermissions(currentUser!, [PermissionsList.createTask]) && (
          <IconButton
            color="inherit"
            classes={{ root: styles["up-header-add"] }}
            onClick={() =>
              locationId
                ? navigate(`/tasks/create?locId=${locationId}&locTitle=${name}`)
                : navigate(`/tasks/create`)
            }
          >
            <AddIcon />
          </IconButton>
        )}
      </div>

      {content.items.length ? (
        <div className={styles["up-grid"]}>
          {content.items.map((i, index) => (
            <TaskCard card={i} key={index} />
          ))}
        </div>
      ) : (
        <p className={styles["up-grid-no-items"]}>No tasks yet.</p>
      )}
    </div>
  );
}

export default TasksCards;
