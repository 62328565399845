import { createContext, Dispatch, SetStateAction } from "react";

import { RoutingSettingsI } from "@interfaces";
import { Roles } from "shared/Enums";
import { Moment } from "moment";

export interface UserDataContextI {
  name: string;
  roles: Roles[];
  uid: string;
  permissions: string[];
  agentProfiles?: boolean;
  timezone: { value: string; offset: number };
  routerSettings?: RoutingSettingsI;
  mute_notifications_until: string;
  notificationsMuted?: boolean;
  workingDays?: number[];
  vacations?: Moment[];
  calendarDate?: Moment;
  fullName?: string;
}

export const UserDataContext = createContext<{
  currentUser: UserDataContextI | null;
  setCurrentUser: Dispatch<SetStateAction<UserDataContextI | null>>;
  login: (userData: UserDataContextI) => void;
  logout: () => void;
}>({
  currentUser: null,
  login: () => {},
  setCurrentUser: () => {},
  logout: () => {},
});
