import { TaskDetailsI } from "@interfaces";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getTaskData } from "shared/Services";

export const useTaskInfo = (taskId: string | undefined) => {
  const [taskInfo, setTaskInfo] = useState<TaskDetailsI>();
  const [error, setError] = useState<AxiosError<any>>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (taskId) {
          const data = await getTaskData(taskId);
          setTaskInfo(data);
        }
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(
        error.response?.data?.errors?.length
          ? error.response?.data?.errors[0]?.detail
          : error.message
      );
      throw error;
    }
  }, [error]);

  return { taskInfo, loading };
};
