import { AxiosResponse } from "axios";

import { helperApi, jsonAuthApi } from "./Settings.api.service";

export const apiGetCountriesAndStates = (): Promise<AxiosResponse<any, any>> => {
  return helperApi.get(`/address/countries_states`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetProductsList = (page: number): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_product/ups_product`, {
    params: {
      "page[limit]": 5,
      "page[offset]": page,
      "filter[status][value]": "1",
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetSettingsList = (): Promise<AxiosResponse<any, any>> => {
  return helperApi.get(`/settings`, {
    params: {},
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetMissedAppointments = (
  date: string
): Promise<AxiosResponse<any, any>> => {
  return helperApi.get(`appointment/missed`, {
    params: {
      date,
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiMuteNotifications = (value: string): Promise<AxiosResponse<any, any>> => {
  return helperApi.post(
    `user/mute_notifications_until`,
    {
      value,
    },
    {
      headers: {
        "X-CSRF-Token": localStorage.getItem("session_token"),
      },
    }
  );
};

export const apiGetMissedVisits = (
  date: string,
  uid: string
): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/ups_visit/ups_visit`, {
    params: {
      include: "uid",
      "filter[uid.id]": uid,
      "filter[status]": "1",
      "filter[visit_status]": "in_progress",
      "filter[search-group][group][conjunction]": "OR",
      "filter[compaire][condition][path]": "date",
      "filter[compaire][condition][value]": date,
      "filter[compaire][condition][operator]": "<",
      "filter[compaire][condition][memberOf]": "search-group",
      // "filter[isNull][condition][path]": "day",
      // "filter[isNull][condition][operator]": "IS NOT NULL",
    },
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetUserById = (id: string): Promise<AxiosResponse<any, any>> => {
  return jsonAuthApi.get(`/user/user/${id}`, {
    params: {},
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
