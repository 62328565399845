import styles from "./Circle.module.scss";
import { OverlayView } from "@react-google-maps/api";
import { CSSProperties } from "react";

export interface LabelI {
  content: string;
  color?: string;
  font?: {
    family?: string;
    weight?: number;
    size?: number;
  };
}

interface Point {
  x: number;
  y: number;
}

interface CircleMarkerProps {
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  width: number;
  height: number;
  circle: {
    radius?: number;
    center?: Point;
    fill?: string;
  };
  text: {
    position?: Point;
    font?: {
      family?: string;
      weight?: number;
      size?: number;
    };
    color?: string;
    content?: string | number;
  };
  primaryLabel: LabelI;
  secondaryLabel: LabelI;
  onClick: () => void;
}

interface LabelCSSProperties extends CSSProperties {
  "--map-marker--label-color--primary"?: string;
  "--map-marker--label-color--secondary"?: string;
}

const Circle = ({
  position,
  width,
  height,
  circle,
  text,
  primaryLabel,
  secondaryLabel,
  onClick,
}: CircleMarkerProps) => {
  const mergedProps = {
    ...Circle.defaultProps,
    circle: {
      ...Circle.defaultProps.circle,
      ...circle,
    },
    text: {
      ...Circle.defaultProps.text,
      ...text,
    },
    primaryLabel: {
      ...Circle.defaultProps.primaryLabel,
      ...primaryLabel,
    },
    secondaryLabel: {
      ...Circle.defaultProps.secondaryLabel,
      ...secondaryLabel,
    },
  };

  const primaryLabelStyles: LabelCSSProperties = {
    "--map-marker--label-color--primary": mergedProps.primaryLabel.color,
  };
  const secondaryLabelStyles: LabelCSSProperties = {
    "--map-marker--label-color--secondary": mergedProps.secondaryLabel.color,
  };

  const maxLength = 15;
  const primaryLabelContent =
    mergedProps.primaryLabel.content?.length > maxLength
      ? mergedProps.primaryLabel.content.slice(0, maxLength) + "..."
      : mergedProps.primaryLabel.content;

  return (
    <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${width} ${height}`}
          width={width}
          height={height}
          onClick={onClick}
          className={styles["up-map-marker--circle"]}
        >
          <circle
            cx={mergedProps.circle.center?.x}
            cy={mergedProps.circle.center?.y}
            r={mergedProps.circle.radius}
            fill={mergedProps.circle.fill}
          />

          {mergedProps.text.content &&
            (mergedProps.text.content === "completed" ? (
              <path
                fill={mergedProps.text.color}
                stroke={mergedProps.text.color}
                strokeWidth="2"
                d="M7.6885391 404.6142S122.85389 534.30185 145.88696 587.27791h99.0422C286.38869 460.59602 447.62018 158.16034 585.8186 52.208207c28.63322-36.81414-43.2978-52.01021985-101.3455-27.63969-87.48642 36.72999-252.48825 317.168053-283.30677 384.652293-43.76283 11.51654-89.82898-73.70582-89.82898-73.70582L7.6885391 404.6142z"
                transform="scale(0.022) translate(140, 150)"
              />
            ) : (
              <text
                x={mergedProps.text.position?.x}
                y={mergedProps.text.position?.y}
                fill={mergedProps.text.color}
                fontSize={mergedProps.text.font?.size}
                fontWeight={mergedProps.text.font?.weight}
                fontFamily={mergedProps.text.font?.family}
                textAnchor="middle"
              >
                {mergedProps.text.content}
              </text>
            ))}
        </svg>

        <div className={styles["up-map-marker--circle-label"]}>
          <div
            className={styles["up-map-marker--circle-label--primary"]}
            style={primaryLabelStyles}
          >
            {primaryLabelContent}
          </div>
          {secondaryLabel?.content && (
            <div
              className={styles["up-map-marker--circle-label--secondary"]}
              style={secondaryLabelStyles}
            >
              {secondaryLabel.content}
            </div>
          )}
        </div>
      </div>
    </OverlayView>
  );
};

Circle.defaultProps = {
  width: 20,
  height: 20,
  circle: {
    radius: 10,
    center: {
      x: 10,
      y: 10,
    },
    fill: "gray",
  },
  text: {
    position: {
      x: 10,
      y: 14.5,
    },
    font: {
      size: 14,
      weight: 800,
      family:
        "Lato, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
    },
    color: "white",
  },
  primaryLabel: {
    color: "black",
  },
  secondaryLabel: {
    color: "gray",
  },
} as CircleMarkerProps;

export { Circle };
