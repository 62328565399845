import axios, { AxiosError } from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import TasksForm from "components/Shared/Tasks/TasksForm/TasksForm";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { checkPermissionsForEdit } from "shared/helpers/common.helper";
import { deleteTask, editTaskData } from "shared/Services";

import { TaskCreateFormI } from "@interfaces";

import styles from "./TaskEdit.module.scss";
import { toast } from "react-toastify";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { useTaskInfo } from "./hooks/useTaskInfo";

function TaskEdit() {
  const { currentUser } = useContext(UserDataContext);

  const [submitted, setSubmitted] = useState(0);
  const params = useParams();
  const navigate = useNavigate();

  const { taskInfo, loading } = useTaskInfo(params.taskId);

  const FormSubmit = (value: TaskCreateFormI) => {
    editTaskData(params.taskId!, value).then((data: AxiosError | any) => {
      if (axios.isAxiosError(data)) {
        toast.error(
          data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        toast.success("Successfully completed!");

        if (data.data.validation) {
          toast.warning(data.data.validation.message);
        }
        navigate(`/tasks/${params.taskId}`);
      }
    });
  };

  const ToggleDelete = () => {
    params.taskId &&
      deleteTask(params.taskId).then((data: AxiosError | any) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          toast.success("Successfully deleted!");
          navigate(`/tasks`);
        }
      });
  };

  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Edit task"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        position={"absolute"}
        background="transparent"
        viewDeleteButton={checkPermissionsForEdit(
          currentUser!,
          PermissionsList.deleteAnyTask,
          PermissionsList.deleteOwnTask,
          taskInfo?.authorId || ""
        )}
        deleteToggle={() => ToggleDelete()}
        onCancelToggle={() => navigate(-1)}
      />
      <LoadingSpinner isLoading={loading} height={300}>
        <>
          {taskInfo && (
            <TasksForm
              formValue={taskInfo}
              submitted={submitted}
              formFinished={FormSubmit}
            ></TasksForm>
          )}
        </>
      </LoadingSpinner>
    </div>
  );
}

export default TaskEdit;
