import { TextField, Modal } from "@mui/material";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { NotePublishType, PermissionsList } from "shared/Enums";

import styles from "./TasksSelectField.module.scss";
import { NameIdListI } from "@interfaces";
import axios from "axios";
import { toast } from "react-toastify";
import { isUserHavePermissions } from "shared/helpers/common.helper";
import { getTasksNameIdList } from "shared/Services";
import { UserDataContext } from "shared/Contexts";

export const TasksSelectField = ({
  publishSelected,
  formik,
  setPublishSelected,
}: {
  publishSelected: {
    selectedType: NotePublishType;
    item: null | NameIdListI;
  };
  formik: any;
  setPublishSelected: (data: {
    selectedType: NotePublishType;
    item: null | NameIdListI;
  }) => void;
}) => {
  const { currentUser } = useContext(UserDataContext);
  const [tasksOpened, setTasksOpened] = useState(false);
  const [tasksList, setTasksList] = useState<NameIdListI[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  useEffect(() => {
    getTasksNameIdList(pageNumber * 20, currentUser!.timezone.value, {
      id:
        !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceTask]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewOwnTask])
          ? currentUser!.uid
          : undefined,
      serviceId:
        !isUserHavePermissions(currentUser!, [PermissionsList.viewAnyTask]) &&
        isUserHavePermissions(currentUser!, [PermissionsList.viewMyServiceTask])
          ? currentUser!.uid
          : undefined,
    }).then((data) => {
      if (axios.isAxiosError(data)) {
        toast.error(
          data.response?.data?.errors?.length
            ? data.response?.data?.errors[0]?.detail
            : ""
        );
      } else {
        const ids = tasksList.map((item) => item.id);
        setTasksList((prev) => [
          ...prev,
          ...data.items.filter((item: NameIdListI) => !ids.includes(item.id)),
        ]);
        setHasMore(data.hasMore);
      }
    });
  }, [pageNumber]);
  return (
    <>
      <TextField
        size="medium"
        classes={{ root: styles["up-form-task"] }}
        label="Task"
        variant="outlined"
        hiddenLabel={true}
        fullWidth={true}
        multiline
        onClick={() => setTasksOpened(true)}
        InputProps={{
          classes: { root: styles["up-form-task-input"] },
        }}
        id="task"
        name="task"
        value={
          publishSelected.selectedType === NotePublishType.task
            ? publishSelected.item?.title
            : ""
        }
        error={formik.touched.task && Boolean(formik.errors.task)}
        helperText={formik.touched.task && formik.errors.task}
      />
      <Modal open={tasksOpened} onClose={() => {}}>
        <div className={styles["up-modal"]}>
          <FormsHeader
            title="Pick Task"
            position="relative"
            onCancelToggle={() => setTasksOpened(false)}
            onRightBtnToggle={() => setTasksOpened(false)}
          />

          <InfiniteScroll
            dataLength={tasksList.length}
            next={() => setPageNumber(pageNumber + 1)}
            hasMore={hasMore}
            // height={"calc(100vh - 3rem)"}
            loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
          >
            {tasksList.length ? (
              tasksList.map((item, index) => (
                <div
                  key={index}
                  className={`${styles["up-publish-tasks"]} ${
                    publishSelected.selectedType === NotePublishType.task &&
                    publishSelected.item?.id === item.id
                      ? styles["active"]
                      : ""
                  }`}
                  onClick={() => {
                    formik.setFieldValue("task", item.id);
                    formik.setFieldValue("location", item.locationId);
                    setPublishSelected({
                      selectedType: NotePublishType.task,
                      item: item,
                    });
                    setTasksOpened(false);
                  }}
                >
                  {item.title}
                </div>
              ))
            ) : (
              <div className="up-no-items">No active task records found</div>
            )}
          </InfiniteScroll>
        </div>
      </Modal>
    </>
  );
};
