import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CountriesStatesContext } from "shared/Contexts";
import { formatAddressToString, getImagesUrl } from "shared/helpers/common.helper";

import { LocationsInfoI } from "@interfaces";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";

import styles from "./LocationsList.module.scss";
import { useLocationsData } from "./hooks/useLocationsData";
import { Footer } from "components/Shared/Footer/Footer";

function LocationsList({
  locationSelected,
  active,
  hasSearch = false,
  companyId,
  showFooter = false,
}: {
  locationSelected: (location: LocationsInfoI) => void;
  active?: string;
  hasSearch?: boolean;
  companyId?: string;
  showFooter?: boolean;
}) {
  const { countriesStates } = useContext(CountriesStatesContext);

  const { content, setSearchText, hasMore, isLoading, setPageNumber } =
    useLocationsData(companyId);

  return (
    <div className={styles["up-locations"]}>
      {hasSearch && (
        <TextField
          hiddenLabel={true}
          fullWidth={true}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            classes: {
              root: styles["up-search-root"],
              input: styles["up-search-input"],
            },
            endAdornment: (
              <InputAdornment
                position={"end"}
                classes={{ root: styles["up-search-icon"] }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant={"filled"}
          placeholder={"Search"}
        />
      )}
      <LoadingSpinner isLoading={isLoading}>
        {content.length ? (
          <div id="locations-scrollable" className={styles["up-locations-scrollable"]}>
            <InfiniteScroll
              scrollableTarget={"locations-scrollable"}
              dataLength={content.reduce(
                (sum, item) => sum + item.locations.length + 1,
                0
              )}
              style={{ overflow: "hidden" }}
              next={() => setPageNumber((prev) => prev + 1)}
              hasMore={hasMore}
              loader={<LoadingSpinner isLoading={true}></LoadingSpinner>}
            >
              {content.map((i, index) => (
                <div className={styles["up-locations-block"]} key={index}>
                  <h2 className={styles["up-locations-letter"]}>{i.letter}</h2>
                  {i.locations.map((l, index) => (
                    <div
                      onClick={() => locationSelected(l)}
                      className={`${styles["up-locations-item"]} ${
                        active === l.id ? styles["active"] : ""
                      }`}
                      key={index}
                    >
                      <div className={styles["up-locations-title"]}>{l.title}</div>
                      <div className={styles["up-locations-details"]}>
                        {formatAddressToString(l.address, countriesStates)}
                      </div>
                      <div className={styles["up-locations-details"]}>{l.company}</div>
                      <div className={styles["up-locations-details"]}>
                        {l.contacts?.join(", ")}
                      </div>
                      {l.logo && (
                        <img
                          src={getImagesUrl() + l.logo}
                          alt={l.title}
                          className={styles["up-locations-logo"]}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}

              {showFooter && !hasMore && <Footer />}
            </InfiniteScroll>
          </div>
        ) : (
          <>
            <div className="up-no-items">No location records found</div>
            {showFooter && <Footer />}
          </>
        )}
      </LoadingSpinner>
    </div>
  );
}

export default LocationsList;
