import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import {
  CheckRoutingPermission,
  isUserHavePermissions,
} from "shared/helpers/common.helper";

import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";

import AddIcon from "../../../assets/icons/navbar/add-new.svg";
import styles from "./Sidenav.module.scss";

interface AddSidenavIProps {
  opened: boolean;
  onToggle: (event: boolean) => void;
}

function AddSidenav({ opened, onToggle }: AddSidenavIProps) {
  const { currentUser } = useContext(UserDataContext);

  const navigate = useNavigate();

  const HandleRedirect = (route: string) => {
    navigate(route);
    onToggle(false);
  };

  return (
    <>
      <SwipeableDrawer
        open={opened}
        onClose={() => onToggle(false)}
        onOpen={() => onToggle(true)}
        anchor="bottom"
        swipeAreaWidth={44}
        disableSwipeToOpen={true}
        classes={{ paper: styles["up-drawer"] }}
      >
        <div className={styles["up-puller"]} />
        <List classes={{ root: styles["up-list"] }}>
          <h2 className={styles["up-list-title"]}>Add New</h2>

          {CheckRoutingPermission(currentUser!) && (
            <ListItem disablePadding>
              <Link
                underline="none"
                onClick={() => HandleRedirect(`visits/create`)}
                classes={{ root: styles["up-list-button"] }}
              >
                <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                  <img alt="icon" src={AddIcon} className={styles["up-list-img"]} />
                </ListItemIcon>
                <ListItemText
                  primary={"Appointment"}
                  classes={{ root: styles["up-list-text"] }}
                />
              </Link>
            </ListItem>
          )}
          {isUserHavePermissions(currentUser!, [PermissionsList.createContact]) && (
            <ListItem disablePadding>
              <Link
                underline="none"
                onClick={() => HandleRedirect(`contacts/create`)}
                classes={{ root: styles["up-list-button"] }}
              >
                <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                  <img alt="icon" src={AddIcon} className={styles["up-list-img"]} />
                </ListItemIcon>
                <ListItemText
                  primary={"Contact"}
                  classes={{ root: styles["up-list-text"] }}
                />
              </Link>
            </ListItem>
          )}

          {isUserHavePermissions(currentUser!, [PermissionsList.createNote]) && (
            <ListItem disablePadding>
              <Link
                underline="none"
                onClick={() => HandleRedirect(`notes/create`)}
                classes={{ root: styles["up-list-button"] }}
              >
                <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                  <img alt="icon" src={AddIcon} className={styles["up-list-img"]} />
                </ListItemIcon>
                <ListItemText
                  primary={"Note"}
                  classes={{ root: styles["up-list-text"] }}
                />
              </Link>
            </ListItem>
          )}

          {isUserHavePermissions(currentUser!, [PermissionsList.createTask]) && (
            <ListItem disablePadding>
              <Link
                underline="none"
                onClick={() => HandleRedirect(`tasks/create`)}
                classes={{ root: styles["up-list-button"] }}
              >
                <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                  <img alt="icon" src={AddIcon} className={styles["up-list-img"]} />
                </ListItemIcon>
                <ListItemText
                  primary={"Task"}
                  classes={{ root: styles["up-list-text"] }}
                />
              </Link>
            </ListItem>
          )}
          {isUserHavePermissions(currentUser!, [PermissionsList.createCompany]) && (
            <ListItem disablePadding>
              <Link
                underline="none"
                onClick={() => HandleRedirect(`companies/create`)}
                classes={{ root: styles["up-list-button"] }}
              >
                <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                  <img alt="icon" src={AddIcon} className={styles["up-list-img"]} />
                </ListItemIcon>
                <ListItemText
                  primary={"Company"}
                  classes={{ root: styles["up-list-text"] }}
                />
              </Link>
            </ListItem>
          )}
          {isUserHavePermissions(currentUser!, [PermissionsList.createLocation]) && (
            <ListItem disablePadding>
              <Link
                underline="none"
                onClick={() => HandleRedirect(`locations/create`)}
                classes={{ root: styles["up-list-button"] }}
              >
                <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                  <img alt="icon" src={AddIcon} className={styles["up-list-img"]} />
                </ListItemIcon>
                <ListItemText
                  primary={"Location"}
                  classes={{ root: styles["up-list-text"] }}
                />
              </Link>
            </ListItem>
          )}
          {CheckRoutingPermission(currentUser!) && (
            <ListItem disablePadding>
              <Link
                underline="none"
                onClick={() => HandleRedirect(`profile/time-reservation`)}
                classes={{ root: styles["up-list-button"] }}
              >
                <ListItemIcon classes={{ root: styles["up-list-icon"] }}>
                  <img alt="icon" src={AddIcon} className={styles["up-list-img"]} />
                </ListItemIcon>
                <ListItemText
                  primary={"Time Reservation"}
                  classes={{ root: styles["up-list-text"] }}
                />
              </Link>
            </ListItem>
          )}
        </List>
      </SwipeableDrawer>
    </>
  );
}

export default AddSidenav;
