import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import UpAvatar from "components/Shared/Profile/Avatar/Avatar";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CountriesStatesContext, UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import {
  checkPermissionsForEdit,
  formatAddressToString,
} from "shared/helpers/common.helper";
import { Button } from "@mui/material";

import TelegramIcon from "../../../../assets/icons/socials/telegram.svg";
import WhatsAppIcon from "../../../../assets/icons/socials/whatsapp.svg";
import styles from "./ContactView.module.scss";
import { useContactData } from "../hooks/useContactData";

const renderIcon = (param: string) => {
  if (param.includes("https://wa.me/")) {
    return <img alt="WhatsApp" src={WhatsAppIcon} />;
  }
  if (param.includes("https://t.me/")) {
    return <img alt="Telegram" src={TelegramIcon} />;
  }
  if (param.includes("https://m.me/")) {
    return <img alt="Telegram" src={TelegramIcon} />;
  }
};

function ContactView() {
  const { currentUser } = useContext(UserDataContext);
  const { countriesStates } = useContext(CountriesStatesContext);

  const navigate = useNavigate();
  const params = useParams();

  const { contactInfo, isLoading } = useContactData(params.contactId);

  return (
    <>
      <Header
        title={"Contact"}
        toggleBack={() => navigate(-1)}
        opacity={0}
        hideReload={true}
      />
      <LoadingSpinner isLoading={isLoading} height={300}>
        {contactInfo ? (
          <div className={styles["up-container"]}>
            <div className={styles["up-card"]}>
              <div className={styles["up-card-edit"]}>
                {checkPermissionsForEdit(
                  currentUser!,
                  PermissionsList.editAnyContact,
                  PermissionsList.editOwnContact,
                  contactInfo.authorId
                ) && (
                  <Button
                    classes={{ root: styles["up-card-edit-button"] }}
                    color={"inherit"}
                    onClick={() => navigate("edit")}
                  >
                    Edit
                  </Button>
                )}
              </div>
              <div className={styles["up-card-avatar"]}>
                <UpAvatar
                  name={contactInfo.name}
                  avatar={contactInfo?.avatar?.url || null}
                  defaultImage={null}
                />
              </div>
              <div className={styles["up-card-name"]}>{contactInfo.name}</div>
              {contactInfo.position && (
                <div className={styles["up-card-position"]}>{contactInfo.position}</div>
              )}
            </div>

            <div className={styles["up-content"]}>
              {contactInfo.phoneNumbers?.length ? (
                <>
                  <label className={styles["up-content-label"]}>Phone number</label>
                  <div className={styles["up-content-value"]}>
                    {contactInfo.phoneNumbers.map((p, index) => (
                      <a key={index} rel="noreferrer" target="_blank" href={`tel:${p}`}>
                        {p}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}

              {contactInfo.emails?.length ? (
                <>
                  <label className={styles["up-content-label"]}>Email</label>
                  <div className={styles["up-content-value"]}>
                    {contactInfo.emails.map((p, index) => (
                      <a
                        key={index}
                        rel="noreferrer"
                        target="_blank"
                        href={`mailto:${p}`}
                      >
                        {p}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}
              {contactInfo.address && (
                <>
                  <label className={styles["up-content-label"]}>Address</label>
                  <div className={styles["up-content-value"]}>
                    {formatAddressToString(contactInfo.address, countriesStates)}
                  </div>
                </>
              )}

              {contactInfo.location && (
                <>
                  <label className={styles["up-content-label"]}>Location</label>
                  <div className={styles["up-content-value"]}>
                    <span
                      onClick={() => navigate(`/locations/${contactInfo.location?.id}`)}
                    >
                      {contactInfo.location.name}
                    </span>
                  </div>
                </>
              )}

              <label className={styles["up-content-label"]}>Company</label>
              <div className={styles["up-content-value"]}>
                <span onClick={() => navigate(`/companies/${contactInfo.company.id}`)}>
                  {contactInfo.company.name}
                </span>
              </div>

              {contactInfo.links.length ? (
                <>
                  <label className={styles["up-content-label"]}>Links</label>
                  <div className={styles["up-content-value"]}>
                    {contactInfo.links.map((l, index) => (
                      <a key={index} rel="noreferrer" target="_blank" href={l}>
                        {l}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}

              {contactInfo.messengers?.length ? (
                <>
                  <label className={styles["up-content-label"]}>Contact via</label>
                  <div className={styles["up-content-icons"]}>
                    {contactInfo.messengers?.map((l, index) => (
                      <a key={index} rel="noreferrer" target="_blank" href={l}>
                        {renderIcon(l)}
                      </a>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : (
          <></>
        )}
      </LoadingSpinner>
    </>
  );
}

export default ContactView;
