import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import LocationsList from "components/Shared/Locations/LocationsList/LocationsList";
import UpTimePicker from "../../ScrollablePicker/ScrollablePicker";
import { useFormik } from "formik";
import moment, { Moment } from "moment-timezone";
import styles from "./AppointmentsForm.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { AppointmentDateForm, AppointmentsType } from "shared/Enums";
import {
  check12AMto00,
  formatStringToTimeFormat,
  TimeFormatToString,
} from "shared/helpers/time.helper";
import { TimeOptionGroups } from "shared/MockedData/TimePicker.mocked";
import * as Yup from "yup";

import {
  AppointmentCreateFormI,
  AppointmentDataI,
  TimeFormatI,
  LocationFieldI,
} from "@interfaces";
import {
  FormControlLabel,
  FormHelperText,
  Modal,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers-pro";

interface AppointmentsFormPropsI {
  submitted: number;
  formFinished: (value: AppointmentCreateFormI) => void;
  formValue?: AppointmentDataI;
  products?: { key: string; value: string }[];
}

function AppointmentsForm({
  submitted,
  formFinished,
  formValue,
}: AppointmentsFormPropsI) {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { currentUser } = useContext(UserDataContext);
  const [isAllDay, setIsAllDay] = useState(formValue?.isAllDay || false);
  const [dateValue, setDateValue] = useState<{
    date: Moment;
    from: TimeFormatI;
    to: TimeFormatI;
    selected: AppointmentDateForm;
  }>({
    date: formValue
      ? moment(formValue.date)
      : location.state?.date
        ? moment(location.state?.date)
        : moment().tz(currentUser!.timezone.value),
    from: formValue?.time
      ? formatStringToTimeFormat(
          check12AMto00(formValue?.time.from, currentUser!.timezone.value)
        )
      : formatStringToTimeFormat("10:00 AM"),
    to: formValue?.time
      ? formatStringToTimeFormat(
          check12AMto00(formValue?.time.to, currentUser!.timezone.value)
        )
      : formatStringToTimeFormat("11:00 AM"),
    selected: AppointmentDateForm.date,
  });

  useEffect(() => {
    submitted && formik.handleSubmit();
  }, [submitted]);

  const [locationsOpened, setLocationsOpened] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<LocationFieldI>(
    formValue?.location
      ? { id: formValue.location.id, title: formValue.location.name }
      : searchParams.get("locId")
        ? ({
            id: searchParams.get("locId"),
            title: searchParams.get("locTitle"),
          } as LocationFieldI)
        : ({} as LocationFieldI)
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    type: Yup.string().required("This field is required"),
    location: Yup.string().required("This field is required"),
    expectedDuration: Yup.number()
      .nullable() // Allows empty input
      .min(5, "Value must be at least 5")
      .max(180, "Value must be at most 180")
      .typeError("Please enter a valid number"), // Custom type error message
  });

  const formik = useFormik({
    initialValues: {
      title: formValue?.title || "",
      type: formValue?.type || "call",
      location: formValue?.location?.id || searchParams.get("locId") || "",
      expectedDuration: formValue?.expectedDuration || null,
    } as AppointmentCreateFormI,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formFinished({
        ...values,
        time: {
          from: TimeFormatToString(dateValue.from),
          to: TimeFormatToString(dateValue.to),
        },
        date: dateValue.date,
        isAllDay,
      });
    },
  });

  const handleNumericInputChange = (e: any) => {
    const inputValue = e.target.value;
    const numericValue = parseFloat(inputValue);

    if (!isNaN(numericValue) || inputValue === "") {
      formik.setFieldValue("expectedDuration", numericValue);
    }
  };

  return (
    <div className={styles["up-container"]}>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          size="medium"
          classes={{ root: styles["up-form-location"] }}
          label="Location"
          variant="outlined"
          hiddenLabel={true}
          fullWidth={true}
          multiline
          onClick={() => setLocationsOpened(true)}
          InputProps={{
            classes: { root: styles["up-form-location-input"] },
          }}
          id="location"
          name="location"
          value={selectedLocation.title}
          error={formik.touched.location && Boolean(formik.errors.location)}
          helperText={formik.touched.location && formik.errors.location}
        />
        <Modal open={locationsOpened} onClose={() => {}}>
          <div className={styles["up-modal-location"]}>
            <FormsHeader
              title="Pick Location"
              position="relative"
              onCancelToggle={() => setLocationsOpened(false)}
              onRightBtnToggle={() => setLocationsOpened(false)}
            />

            <div className={styles["up-modal-location-content"]}>
              <LocationsList
                hasSearch={true}
                active={selectedLocation?.id || undefined}
                locationSelected={(location) => {
                  formik.setFieldValue("location", location.id);
                  setSelectedLocation(location);
                  setLocationsOpened(false);
                }}
              />
            </div>
          </div>
        </Modal>

        <TextField
          classes={{ root: styles["up-form-title"] }}
          label="Title"
          variant="outlined"
          hiddenLabel={true}
          fullWidth={true}
          multiline
          InputProps={{
            classes: { root: styles["up-form-title-input"] },
          }}
          id="title"
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
        <div className={styles["up-form-radio"]}>
          <RadioGroup
            row
            name="type"
            value={formik.values.type}
            onChange={formik.handleChange}
            classes={{ root: styles["up-form-radio-group"] }}
          >
            <FormControlLabel
              classes={{ root: styles["up-form-radio-label"] }}
              value={AppointmentsType.call}
              control={<Radio />}
              label="Call"
            />
            <FormControlLabel
              classes={{ root: styles["up-form-radio-label"] }}
              value={AppointmentsType.visit}
              control={<Radio />}
              label="In person Visit"
            />
          </RadioGroup>
          <FormHelperText>{formik.touched.type && formik.errors.type}</FormHelperText>
        </div>

        <div className={styles["up-form-date"]}>
          <div className={styles["up-form-date-header"]}>
            <span className={styles["up-form-date-header-label"]}>Any time on</span>
            <Switch
              color="default"
              value={isAllDay}
              onChange={(e) => {
                setIsAllDay(e.target.checked);
                setDateValue({
                  ...dateValue,
                  selected: AppointmentDateForm.date,
                });
              }}
              classes={{
                root: styles["up-switch"],
                thumb: styles["up-switch-thumb"],
                track: styles["up-switch-track"],
                switchBase: styles["up-switch-switch-base"],
                checked: styles["up-switch-checked"],
              }}
            />
          </div>

          <div className={styles["up-form-date-expected-duration"]}>
            <label
              htmlFor="expectedDuration"
              className={styles["up-form-date-expected-duration--label"]}
            >
              Expected duration
            </label>
            <TextField
              classes={{ root: styles["up-form-date-expected-duration--textfield"] }}
              type="text"
              label="from 5 to 180 minutes"
              variant="standard"
              InputProps={{
                classes: {
                  root: styles["up-form-date-expected-duration--textfield--input-root"],
                  input: styles["up-form-date-expected-duration--textfield--input"],
                },
                disableUnderline: true,
              }}
              size="small"
              id="expectedDuration"
              name="expectedDuration"
              onChange={handleNumericInputChange}
              onBlur={formik.handleBlur}
              value={formik.values.expectedDuration || ""}
              error={
                formik.touched.expectedDuration && Boolean(formik.errors.expectedDuration)
              }
              helperText={
                formik.touched.expectedDuration && formik.errors.expectedDuration
              }
            />
          </div>

          <div className={styles["up-form-date-tabs"]}>
            <div className={styles["up-form-date-tabs-block"]}>
              <label className={styles["up-form-date-tabs-label"]}>Day</label>
              <div
                onClick={() => {
                  setDateValue({
                    ...dateValue,
                    selected: AppointmentDateForm.date,
                  });
                }}
                className={`${styles["up-form-date-tabs-field"]} ${
                  dateValue.selected === AppointmentDateForm.date ? styles["active"] : ""
                }`}
              >
                {dateValue.date.format("ll")}
              </div>
            </div>

            {!isAllDay && (
              <>
                <div className={styles["up-form-date-tabs-block"]}>
                  <label className={styles["up-form-date-tabs-label"]}>
                    Start earliest
                  </label>

                  <div
                    onClick={() => {
                      setDateValue({
                        ...dateValue,
                        selected: AppointmentDateForm.from,
                      });
                    }}
                    className={`${styles["up-form-date-tabs-field"]} ${
                      dateValue.selected === AppointmentDateForm.from
                        ? styles["active"]
                        : ""
                    }`}
                  >
                    {TimeFormatToString(dateValue.from)}
                  </div>
                </div>
                <span className={styles["up-form-date-tabs-divider"]}>-</span>
                <div className={styles["up-form-date-tabs-block"]}>
                  <label className={styles["up-form-date-tabs-label"]}>
                    Start latest
                  </label>

                  <div
                    onClick={() => {
                      setDateValue({
                        ...dateValue,
                        selected: AppointmentDateForm.to,
                      });
                    }}
                    className={`${styles["up-form-date-tabs-field"]} ${
                      dateValue.selected === AppointmentDateForm.to
                        ? styles["active"]
                        : ""
                    }`}
                  >
                    {TimeFormatToString(dateValue.to)}
                  </div>
                </div>
              </>
            )}
          </div>

          {dateValue.selected === AppointmentDateForm.date ? (
            <StaticDatePicker
              className={"mat-calendar"}
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={dateValue.date}
              minDate={moment().utc().tz(currentUser!.timezone.value)}
              onChange={(newValue) => {
                newValue &&
                  setDateValue({
                    ...dateValue,
                    date: newValue,
                  });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          ) : (
            <UpTimePicker
              optionGroups={TimeOptionGroups}
              valueGroups={
                dateValue[dateValue.selected] as unknown as {
                  [key: string]: string;
                }
              }
              onChange={(name: string, value: string) =>
                setDateValue({
                  ...dateValue,
                  [dateValue.selected]: {
                    ...dateValue[dateValue.selected],
                    [name]: value,
                  },
                })
              }
            />
          )}
        </div>
      </form>
    </div>
  );
}

export default AppointmentsForm;
