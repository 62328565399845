import axios, { AxiosError } from "axios";
import ContactsForm from "components/Shared/Contacts/ContactForm/ContactsForm";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { editContactData } from "shared/Services";

import { ContactFormI } from "@interfaces";

import styles from "./ContactEdit.module.scss";
import { PermissionsList } from "shared/Enums";
import { checkPermissionsForEdit } from "shared/helpers/common.helper";
import { deleteContact } from "shared/Services/Contacts.service";
import { toast } from "react-toastify";
import { useContactData } from "../hooks/useContactData";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";

function ContactEdit() {
  const { currentUser } = useContext(UserDataContext);
  const navigate = useNavigate();
  const params = useParams();

  const { contactInfo, isLoading } = useContactData(params.contactId);

  const [submitted, setSubmitted] = useState(0);

  const FormSubmit = (value: ContactFormI) => {
    editContactData(params.contactId!, value).then((response: AxiosError | any) => {
      if (axios.isAxiosError(response)) {
        toast.error(response.message);
      } else {
        toast.success("Successfully completed!");
        navigate(`/contacts/${params.contactId}`);
      }
    });
  };

  const ToggleDelete = () => {
    params.contactId &&
      deleteContact(params.contactId).then((data: AxiosError | any) => {
        if (axios.isAxiosError(data)) {
          toast.error(
            data.response?.data?.errors?.length
              ? data.response?.data?.errors[0]?.detail
              : ""
          );
        } else {
          toast.success("Successfully deleted!");
          navigate("/");
        }
      });
  };

  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Edit Contact"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        position={"absolute"}
        background="transparent"
        onCancelToggle={() => navigate(-1)}
        viewDeleteButton={checkPermissionsForEdit(
          currentUser!,
          PermissionsList.deleteAnyContact,
          PermissionsList.deleteOwnContact,
          contactInfo?.authorId || ""
        )}
        deleteToggle={() => ToggleDelete()}
      />
      <LoadingSpinner isLoading={isLoading} height={300}>
        {contactInfo && (
          <ContactsForm
            formValue={contactInfo}
            submitted={submitted}
            formFinished={FormSubmit}
          ></ContactsForm>
        )}
      </LoadingSpinner>
    </div>
  );
}

export default ContactEdit;
