import { useContext, useEffect } from "react";
import { RouterContext } from "shared/Contexts";

import { Button, SwipeableDrawer } from "@mui/material";
import { GoogleMap, MarkerF } from "@react-google-maps/api";

import BlueIcon from "../../../assets/icons/mapMarkers/marker-blue.svg";
import Header from "../Header/Header";
import styles from "./gMap.module.scss";
import { toast } from "react-toastify";
import { openMapApp } from "shared/helpers/openMapApp";

function GMapView() {
  const [routerValue, setRouterValue] = useContext(RouterContext);

  useEffect(() => {
    if (routerValue.gMapOpened) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "inherit";
  }, [routerValue.gMapOpened]);

  return routerValue.loaded ? (
    <>
      <div className={styles["up-modal"]}>
        <Header
          title={routerValue.gMapLocationName ?? ""}
          toggleBack={() =>
            setRouterValue((prev) => ({
              ...prev,
              gMapOpened: false,
              gMapLocationName: "",
            }))
          }
          hideReload={true}
        />
        {routerValue.gMapGeolocation && (
          <>
            <GoogleMap
              options={{
                mapId: "11a7b148a03577a0",
                fullscreenControl: false,
                mapTypeControl: false,
                zoomControl: false,
                streetViewControl: false,
              }}
              clickableIcons={false}
              mapContainerStyle={{
                width: "100vw",
                height: "100vh",
              }}
              center={{
                lat: Number(routerValue.gMapGeolocation.lat),
                lng: Number(routerValue.gMapGeolocation.lng),
              }}
              zoom={16}
            >
              <MarkerF
                position={{
                  lat: routerValue.gMapGeolocation.lat,
                  lng: routerValue.gMapGeolocation.lng,
                }}
                icon={{
                  url: BlueIcon,
                  size: new google.maps.Size(32, 46),
                  scaledSize: new google.maps.Size(32, 46),
                  labelOrigin: new google.maps.Point(80, 20),
                }}
              />
            </GoogleMap>
            {routerValue.gMapOpened && (
              <SwipeableDrawer
                open={false}
                onClose={() => {}}
                onOpen={() => {}}
                swipeAreaWidth={44}
                classes={{
                  paper: styles["up-drawer-paper"],
                  root: styles["up-drawer-root"],
                }}
                ModalProps={{
                  keepMounted: true,
                }}
                anchor="bottom"
                disableSwipeToOpen={true}
              >
                <div className={styles["up-drawer-block"]}>
                  <div className={styles["up-puller"]} />
                  <div className={styles["up-drawer-content"]}>
                    <div className={styles["up-buttons"]}>
                      <Button
                        classes={{ root: styles["up-buttons-button"] }}
                        color={"inherit"}
                        onClick={() =>
                          openMapApp(
                            {
                              lat: routerValue.gMapGeolocation!.lat,
                              lng: routerValue.gMapGeolocation!.lng,
                            },
                            (e) => toast.error(e)
                          )
                        }
                        variant="outlined"
                      >
                        Direction
                      </Button>
                    </div>
                  </div>
                </div>
              </SwipeableDrawer>
            )}
          </>
        )}
      </div>
    </>
  ) : (
    <div>Getting the location data</div>
  );
}

export default GMapView;
